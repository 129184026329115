import React, { useState, useMemo } from 'react'
import { ModalBody, ModalHeader, Modal, ModalFooter, Button } from 'reactstrap'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys.json'
import { isValidTaskBody, taskBodyFormatter } from '../../../utils/taskHandler'
import { updateUserTasks } from '../../../services/api'
import { LocalStorage } from '../../../services/localStorage'
import { getLocalStorageKey } from '../../../utils/'
import GroupTree from '../../Elements/Group/GroupTree'
import TaskUsersList from '../../Elements/Group/TaskUsersList'

const AssigneeModal = ({
  groups,
  users,
  tasks,
  subTasks,
  tasksData,
  showAssigneeTaskModal,
  selectedTask,
  setShowAssigneeTaskModal,
  setEditTask,
  setShowTasksModal,
  setTasksAndTasksData,
  hideFullScreenLoader,
  logout,
  handleAlert,
  selectedGroup,
  showFullScreenLoader,
  selected_rid,
  selectedUser,
  setSelectedUser,
}) => {
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [openWarningModal, setOpenWarningModal] = useState(false)

  const isUidsOfSelectedGroupsInSelectedUsers = useMemo(() => {
    if (!selectedGroups) return
    return selectedGroups.some(id => {
      return groups[id].uids.some(uid => selectedUsers.includes(uid))
    })
  }, [selectedGroups, selectedUsers, groups])

  const isSelectedUidsInGroups = useMemo(() => {
    if (!selectedUsers) return
    return selectedUsers.some(uid => {
      return selectedGroups.some(id => {
        return groups[id].uids.includes(uid)
      })
    })
  }, [selectedGroups, selectedUsers, groups])

  const closeModal = () => {
    setShowAssigneeTaskModal(false)
    setSelectedGroups([])
    setSelectedUsers([])
    setOpenWarningModal(false)
  }

  const handleResponseError = status => {
    if (status === '401') {
      logout()
    }
  }

  const handleSelectUsers = e => {
    const userId = e.target.value
    const isChecked = selectedUsers.includes(userId)
    if (isChecked) {
      setSelectedUsers(prevState => prevState.filter(uId => uId !== userId))
    } else {
      setSelectedUsers(prevState => [...prevState, userId])
    }
  }

  const handleSubmissionResponse = response => {
    let msg = 'ERROR: changes were not saved, something is wrong with the data'
    let alertType = Keys.ALERT_ERROR

    if (response !== undefined && response['success'] === true) {
      msg = 'Changes Were Saved :) !'
      alertType = Keys.ALERT_SUCCESS
    }

    handleAlert({
      text: msg,
      type: alertType,
    })
    handleResponseError(response.status.toString())
  }

  const submitTask = (
    task,
    taskData = null,
    attachedSubTasks = null,
    subTask2Delete = null
  ) => {
    const groupsId = selectedGroups ?? []
    const userUids = selectedUsers ?? []
    const idForRsID =
      groupsId && groupsId.length > 0
        ? groupsId[0]
        : userUids && userUids.length > 0
        ? userUids[0]
        : null
    const rid = selected_rid
    const taskWithAddedRsID = { ...task, ['rs_id']: `${rid}-${idForRsID}` }
    const taskDataWithAddedRsID = {
      ...taskData,
      ['rs_id']: `${rid}-${idForRsID}`,
    }
    const { body, taskType } = taskBodyFormatter(
      taskWithAddedRsID,
      groupsId,
      userUids,
      rid,
      subTask2Delete,
      attachedSubTasks,
      taskDataWithAddedRsID
    )

    if (isValidTaskBody(body, taskType)) {
      showFullScreenLoader()

      return updateUserTasks(
        handleSubmissionResponse,
        body,
        'PUT',
        hideFullScreenLoader
      )
    } else {
      handleAlert({
        text:
          'task is not valid, check if : you field all the necessary data, and products',
        type: Keys.ALERT_ERROR,
      })
    }
  }

  const handleSubmitTask = () => {
    if (
      (isUidsOfSelectedGroupsInSelectedUsers || isSelectedUidsInGroups) &&
      !openWarningModal
    ) {
      setOpenWarningModal(true)
      return
    }
    setShowTasksModal(false)
    const isConfirm = window.confirm(Keys.MESSAGE_CONFIRM_CREATE_TASK)
    if (isConfirm) {
      const { categories } = selectedTask
      const subtasks = {}
      Object.keys(categories).forEach(category => {
        categories[category].sub_tasks_order.forEach(
          subtaskId => (subtasks[subtaskId] = subTasks[subtaskId])
        )
      })
      let taskData = null
      let { cloned, isNewTask, isEdit, ...task } = selectedTask

      if (isNewTask || isEdit) {
        taskData = tasksData[selectedTask.data_oid]
      }
      if (isEdit) {
        delete task.created_by
      }
      if (cloned) {
        const currentDate = +(Date.now() / 1000).toFixed(0)
        task.created_at_ts = currentDate
        task.update_ts = currentDate
        delete task.created_by
        taskData = tasksData[selectedTask.data_oid]
      }
      submitTask(task, taskData, subtasks)
        .then(() => {
          const tasksWithoutCloned = tasks.map(oldTask => {
            return oldTask[Keys.TASK_ID] === task[Keys.TASK_ID] ? task : oldTask
          })
          setTasksAndTasksData(tasksWithoutCloned, tasksData)
          setEditTask(false)
          const { name } = selectedGroup
          LocalStorage.removeData(getLocalStorageKey(selected_rid, name))
          LocalStorage.removeData(
            `cloned-${getLocalStorageKey(selected_rid, name)}`
          )
        })
        .catch(err => {
          handleSubmissionResponse(err)
        })
        .finally(() => {
          closeModal()
        })
    }
  }

  return (
    <>
      {openWarningModal ? (
        <Modal isOpen={openWarningModal}>
          <ModalBody>
            <h5 style={{ textAlign: 'center' }}>
              You have duplications of users in your selection!
            </h5>
            <h5 style={{ textAlign: 'center' }}>
              Are you sure you want to assign this task to this audience ?
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={handleSubmitTask}>
              Yes
            </Button>
            <Button color="none" onClick={() => setOpenWarningModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal isOpen={showAssigneeTaskModal} toggle={closeModal}>
          <ModalHeader>Choose audience</ModalHeader>
          <ModalBody style={{ overflowY: 'auto', maxHeight: '700px' }}>
            <div style={{ marginBottom: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>Task groups</div>
                <div
                  style={{
                    marginLeft: '30px',
                    fontSize: '12px',
                    color: 'gray',
                  }}
                >
                  Chosen {selectedGroups.length} of {Object.keys(groups).length}
                  groups
                </div>
              </div>

              <GroupTree
                groups={groups}
                onSelectedGroupsChange={setSelectedGroups}
                withCheckboxes={true}
              />
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ fontWeight: 'bold' }}>Task users</div>
                <div
                  style={{
                    marginLeft: '30px',
                    fontSize: '12px',
                    color: 'gray',
                  }}
                >
                  Chosen {selectedUsers.length} of {users.length} users
                </div>
              </div>
              <TaskUsersList
                users={users}
                selectedUsers={selectedUsers}
                handleSelectUsers={handleSelectUsers}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleSubmitTask}
              disabled={!selectedGroups.length && !selectedUsers.length}
            >
              ok
            </Button>
            <Button onClick={closeModal}>cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}

const mapStateToProps = ({
  showAssigneeTaskModal,
  groups,
  users,
  selectedTask,
  subTasks,
  tasksData,
  tasks,
  selectedGroup,
  selected_rid,
}) => ({
  showAssigneeTaskModal,
  groups,
  users,
  selectedTask,
  subTasks,
  tasksData,
  tasks,
  selectedGroup,
  selected_rid,
})

export default connect(mapStateToProps, actions)(AssigneeModal)
