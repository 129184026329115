import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import AddSubTaskButton from '../Elements/AddSubTaskButton'
import Colors from '../../utils/Colors'
import Keys from '../../utils/Keys'
import './style.css'
import isNil from 'lodash/isNil'

const tableStyle = { color: Colors.Type8Table, height: '20px' }
const headerStyle = {
  backgroundColor: tableStyle.color,
  textAlign: 'center',
  fontWeight: 'bold',
}

const TableWrapper = styled.div`
  background: ${props => props.tableColor.color};
`

class ReportTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handelRowClick = (row, isSelect, rowIndex, e) => {
    const cur_row = this.props.selectedTask.data[rowIndex]
    const row_obj = { data: cur_row, index: rowIndex }
    this.props.setEditItem(true)
    this.props.setSelectedRow(row_obj)
    this.props.setShowItemModal(true)
  }

  getItems = () => {
    let items = []
    if (
      !isNil(this.props.selectedTask) &&
      !isNil(this.props.selectedTask[Keys.TITLES]) &&
      this.props.selectedTask.type === Keys.TASK_TYPE_REPORT
    ) {
      this.props.reportTasks.forEach(task => {
        if (task.tid === this.props.selectedTask.tid) {
          const rows = task[Keys.DATA]
          const titles = task[Keys.TITLES]
          rows.forEach(row => {
            let data = {}
            row.forEach((param, index) => {
              data[titles[index]] = param
            })
            items.push(data)
          })
        }
      })
    }
    return items
  }

  getColumns = () => {
    let columns = []
    if (
      !isNil(this.props.selectedTask) &&
      !isNil(this.props.selectedTask[Keys.TITLES])
    ) {
      this.props.selectedTask[Keys.TITLES].forEach(col => {
        columns.push({ dataField: col, text: col, headerStyle: headerStyle })
      })
    }
    return columns
  }

  getKeyField = columns => {
    if (!isNil(columns) && columns.length > 0) {
      const firstCol = columns[0]
      if (!isNil(firstCol) && firstCol.hasOwnProperty('text')) {
        return firstCol.text
      }
    }
    return ''
  }

  render() {
    const columns = this.getColumns()

    const selectRow = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handelRowClick(row, isSelect, rowIndex, e)
      },
    }

    return (
      <TableWrapper className="itemTableWrapper" tableColor={tableStyle}>
        {this.props.reportTasks.length === 0 ? (
          'Nothing to Show'
        ) : (
          <BootstrapTable
            keyField={this.getKeyField(this.getColumns())}
            data={this.getItems()}
            selectRow={selectRow}
            columns={this.getColumns()}
            bordered={false}
            rowStyle={{
              backgroundColor: tableStyle.color,
              height: tableStyle.height,
            }}
          />
        )}
        <AddSubTaskButton
          bg={Colors.ACTION}
          text={'Add report line'}
          st_type={-1}
        />
      </TableWrapper>
    )
  }
}

const mapStateToProps = ({
  reportTasks,
  tasksData,
  permissions,
  userRidsDetails,
  selected_rid,
  selected_sid,
  selectedTask,
  thisWeek,
}) => ({
  reportTasks,
  tasksData,
  permissions,
  userRidsDetails,
  selected_rid,
  selected_sid,
  selectedTask,
  thisWeek,
})

export default connect(
  mapStateToProps,
  actions
)(ReportTable)
