import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import Button from './Button'
import styled from 'styled-components'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import UploadIcon from '../../assets/svg-icons/cloud-upload-blue.svg'
import WarningIcon from '../../assets/svg-icons/warning.svg'
import Colors from '../../utils/Colors'
import './styles/modalStyle.css'
import { sortTasks } from '../../utils/taskHandler'
import { deleteUser, updateUserTasks } from '../../services/api'
import Keys from '../../utils/Keys'
import { isNil, get } from 'lodash'
import withSubmissionHandler from './Form/withSubmissionHandler'
const StyledModal = styled(Modal)``

class DeleteUserWarningClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  close = () => {
    this.props.setShowUserModal(false)
    this.props.setShowDeleteUserWarningModal(false)
  }

  handleResponse = response => {
    let msg = 'YES! this user is history 8)'
    let alertType = Keys.ALERT_SUCCESS
    if (response === undefined || response.success !== true) {
      msg = 'failed to add delete the user :( , please check with R&D team'
      alertType = Keys.ALERT_ERROR
    }
    this.props.handleAlert({
      text: msg,
      type: alertType,
    })
    this.props.hideFullScreenLoader()
  }

  handleDelete = () => {
    let users = this.props.users
    this.props.showFullScreenLoader()
    deleteUser(this.handleResponse, this.props.selectedUser['uid'])

    let reducedUsers = users.filter(user => {
      if (user.uid !== this.props.selectedUser['uid']) {
        return user
      }
    })
    this.props.setUsers(reducedUsers)
    this.props.setEditUser(false)
    this.close()
  }
  render() {
    return (
      <StyledModal
        isOpen={this.props.showDeleteUserWarningModal}
        toggle={this.close}
      >
        <ModalHeader toggle={this.close}>
          <img className="modalHeaderImage" src={WarningIcon} /> Delete User
        </ModalHeader>
        <ModalBody>
          <h4 className="modalHeaderText">
            Are you sure you want to delete : {this.props.selectedUser['uid']}
          </h4>
          <div className="modalButtonDash">
            <Button
              onClick={this.handleDelete}
              type={'button'}
              bg={Colors.ACTION}
            >
              {' '}
              Yes, delete please{' '}
            </Button>
            <Button onClick={this.close} bg={Colors.DANGER}>
              {' '}
              cancel{' '}
            </Button>
          </div>
        </ModalBody>
      </StyledModal>
    )
  }
}

class DeleteCategoryWarningClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  close = () => {
    this.props.setShowCategoryModal(false)
    this.props.setShowDeleteCategoryWarningModal(false)
  }

  handleDelete = () => {
    this.props.setEditCategory(false)
    let tasks = [...this.props.tasks]
    let taskId = this.props.selectedTask[Keys.TASK_ID]
    let categoryName = this.props.selectedCategory[Keys.CATEGORY_NAME]
    let editedTask = null
    tasks.forEach(task => {
      if (taskId === task[Keys.TASK_ID]) {
        task.categories[categoryName][Keys.ST_ORDER].forEach(stId2delete => {
          this.props.setSubTasksToDelete(taskId, stId2delete)
        })

        delete task[Keys.CATEGORIES][categoryName]

        task[Keys.CATEGORIES_ORDER] = task[Keys.CATEGORIES_ORDER].filter(
          item => {
            if (categoryName !== item) {
              return item
            }
          }
        )
        editedTask = task
      }
    })
    this.props.submitTask(editedTask)
    // this.props.addChangedTask(this.props.selectedTask[Keys.TASK_ID])
    this.props.setAppTasks(tasks)
    this.props.setExpendedCategory([])
    this.props.setSelectedCategory({})
    this.close()
  }
  render() {
    return (
      <StyledModal
        isOpen={this.props.showDeleteCategoryWarningModal === true}
        toggle={this.close}
      >
        <ModalHeader toggle={this.close}>
          {' '}
          <img className="modalHeaderImage" src={WarningIcon} /> Delete category
        </ModalHeader>
        <ModalBody>
          <h4 className="modalHeaderText">
            Are you sure you want to delete this category : {'  '}
            {this.props.selectedCategory.category_name}
          </h4>
          <div className="modalButtonDash">
            <Button onClick={this.handleDelete} bg={Colors.ACTION}>
              {' '}
              Yes, delete please{' '}
            </Button>
            <Button onClick={this.close} bg={Colors.DANGER}>
              {' '}
              cancel{' '}
            </Button>
          </div>
        </ModalBody>
      </StyledModal>
    )
  }
}

class SubmitTaskModalClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      waitingForRes: false,
    }
  }
  close = (success = false) => {
    if (success === true) {
      this.props.removeChangedTask(this.props.selectedTask[Keys.TASK_ID])
    }
    this.props.setEditTask(false)
    this.props.setShowTasksModal(false)
    this.props.setShowSubmitTaskWarningModal(false)
  }
  handleSubmit = () => {
    this.setState({ waitingForRes: true })
    const taskType = this.props.selectedTask[Keys.TYPE]
    let tasks = this.props.tasks
    if ([Keys.TASK_TYPE_REPORT].includes(taskType)) {
      tasks = this.props.reportTasks
    }
    const taskId = this.props.selectedTask[Keys.TASK_ID]

    let task = tasks.filter(t => {
      if (t[Keys.TASK_ID] === taskId) {
        return t
      }
    })
    if (task.length !== 1) {
      this.props.handleAlert({
        text: 'something is went very wrong here',
        type: Keys.ALERT_ERROR,
      })
    } else {
      task = task[0]
      try {
        this.props.submitTask(task)
        this.close(true)
      } catch (e) {
        this.props.handleAlert({
          text: e.text,
          type: Keys.ALERT_ERROR,
        })
      }
    }
  }
  render() {
    return (
      <StyledModal
        isOpen={this.props.showSubmitTaskWarningModal === true}
        toggle={this.close}
      >
        <ModalHeader toggle={this.close}>
          <img className="modalHeaderImage" src={UploadIcon} /> Submit Task
        </ModalHeader>
        <ModalBody>
          <h4 className="modalHeaderText">
            You are about to submit task changes in the server : {'  '}
            {this.props.selectedTask[Keys.TASK_ID]}
          </h4>
          <div className="modalButtonDash">
            <Button onClick={this.handleSubmit} bg={Colors.ACTION}>
              Yes, Submit please
            </Button>
            <Button onClick={this.close} bg={Colors.DANGER}>
              {' '}
              Cancel{' '}
            </Button>
          </div>
        </ModalBody>
      </StyledModal>
    )
  }
}

const mapStateToProps = ({
  users,
  tasks,
  reportTasks,
  subTasks,
  tasksData,
  selectedUser,
  selectedTask,
  selectedCategory,
  selected_sid,
  selected_rid,
  subTasksToDelete,
  showUserModal,
  showDeleteUserWarningModal,
  showDeleteTaskWarningModal,
  showDeleteCategoryWarningModal,
  showSubmitTaskWarningModal,
}) => ({
  users,
  tasks,
  reportTasks,
  subTasks,
  tasksData,
  selectedUser,
  selectedTask,
  selectedCategory,
  selected_sid,
  selected_rid,
  subTasksToDelete,
  showUserModal,
  showDeleteUserWarningModal,
  showDeleteTaskWarningModal,
  showDeleteCategoryWarningModal,
  showSubmitTaskWarningModal,
})

export const DeleteUserWarning = connect(
  mapStateToProps,
  actions
)(DeleteUserWarningClass)

export const SubmitTaskModal = withSubmissionHandler(
  connect(
    mapStateToProps,
    actions
  )(SubmitTaskModalClass)
)

export const DeleteCategoryWarning = withSubmissionHandler(
  connect(
    mapStateToProps,
    actions
  )(DeleteCategoryWarningClass)
)
