import React, { useState } from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import IconButtonWrapper from './Buttons/IconButton'
import { SubmitLoader } from './TableLoader'
import { webGet } from '../../services/api'
import Colors from '../../utils/Colors'
import Keys from '../../utils/Keys'
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded'

const Scrapper = props => {
  const [loading, setLoading] = useState(false)

  const responseHandler = response => {
    if (response !== undefined) {
      if (response[Keys.IMAGE] !== undefined || response.status === 200) {
        props._callback(response['image'], props.name)
      } else {
        props.handleAlert({
          text: `could not find this model on ${
            props.selected_rid
          } website, \n  ... sorry,  ¯\\_(ツ)_/¯`,
          type: Keys.ALERT_INFO,
        })
      }
    } else {
      props.handleAlert({
        text: 'some thing is wrong in try asking the R&D team :(',
        type: Keys.ALERT_ERROR,
      })
    }
  }
  const scrap = e => {
    setLoading(true)

    webGet(
      responseHandler,
      { rid: props.selected_rid, itemId: props.itemId },
      setLoading
    )
  }

  const dummyClick = e => {
    props.handleAlert({
      text: 'fill item id first, daaa..',
      type: Keys.ALERT_WARNING,
    })
  }

  return (
    <IconButtonWrapper
      type={'button'}
      onClick={props.itemId.trim() !== '' ? scrap : dummyClick}
      color={props.itemId.trim() !== '' ? 'primary' : 'default'}
      icon={loading ? <SubmitLoader /> : <GetAppRoundedIcon />}
    />
  )
}

const mapStateToProps = ({ selected_rid }) => ({
  selected_rid,
})

export default connect(
  mapStateToProps,
  actions
)(Scrapper)
