import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import '../styles/formStyle.css'
import Button from '../Button'
import Colors from '../../../utils/Colors'
import EditableListWrapper from '../Lists/EditableListWrapper'
import Keys from '../../../utils/Keys'
import { generateTagList } from '../../../utils/taskHandler'

class TaskType1Structure extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <EditableListWrapper
          items={this.props.titles}
          setItems={this.props.handleTitlesChange}
          placeholder={'Type Column name and press Enter ...'}
          label={'Columns'}
          name={'titles'}
        />
      </div>
    )
  }
}

const mapStateToProps = ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
}) => ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
})

export default connect(
  mapStateToProps,
  actions
)(TaskType1Structure)
