import React from 'react'
import '../styles/formStyle.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Select from './Select'
import CloseIcon from '../../../assets/svg-icons/error.svg'
import styled from 'styled-components'

const StyleSelect = styled(Select)`
  margin-top: 10px;
`

const StyledImage = styled.img`
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0px 5px 0px 0px;
  cursor: pointer;
  top: -13px;
  right: -10px;
`

const Toggle = styled.div`
  text-align: right;
`

const BoxLabel = styled.label`
  display: block;
  float: left;
  margin-top: -30px;
  margin-bottom: 0;
  background: #ffffff;
  height: 20px;
  padding: 2px 5px 2px 5px;
  color: #b9b9b9;
  font-size: 14px;
  overflow: hidden;
`

const COLUMN1 = 0
const COLUMN2 = 2
const COMPERATOR = 1

class RuleBoxSuffix extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columnNumber: this.props.columnNumber,
      target_column: this.props.target_column,
      enabled_rule: this.props.index > 0,
    }
  }

  printColumnOptions = () => {
    const columnNumber = this.props.columnNumber
    if (columnNumber !== undefined) {
      return [
        <option value="" id="" key={'-1'}>
          {`Selected Somthing`}
        </option>,
        ...Array(columnNumber)
          .fill()
          .map((val, i) => {
            return (
              <option value={i} key={i} id={i}>
                {`column No.${i + 1}`}
              </option>
            )
          }),
      ]
    }
  }
  deleteRule = () => {
    const { index } = this.props
    this.props.deleteTableRule(index)
  }

  render() {
    const { target_column, index } = this.props
    return (
      <form className="itemForm ruleBoxWrapper">
        <BoxLabel>{`+/- Rule`}</BoxLabel>
        <Toggle>
          {' '}
          <StyledImage src={CloseIcon} onClick={this.deleteRule} />
        </Toggle>
        <ul className="formList">
          <StyleSelect
            label="Target column"
            name={'target_column'}
            value={target_column === null ? '' : target_column}
            onChange={event => this.props.handleRuleChange(event, index)}
          >
            {this.printColumnOptions()}
          </StyleSelect>
        </ul>
      </form>
    )
  }
}

const mapStateToProps = ({ loading }) => ({ loading })

export default connect(
  mapStateToProps,
  actions
)(RuleBoxSuffix)
