import React from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../../store/actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '../Buttons/Button'
import '../styles/formStyle.css'
import Colors from '../../../utils/Colors'
import Keys from '../../../utils/Keys'
import {
  setNewOrder,
  addNewItemToOrder,
  generateID,
  generateTagList,
  tagsToList,
  convertUnderscoreNameToLabel,
  printMenuOptions,
  emptyStrToNull,
  inReachOptional,
} from '../../../utils/taskHandler'
import Input from '../FormElements/Input'
import SortableEditableListWrapper from '../Lists/SortableEditableListWrapper'
import SortableEditableListWithColorWrapper from '../Lists/SortableEditableListWithColorWrapper'
import Radio from '@material-ui/core/Radio'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Select from '../FormElements/Select'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import UploadFile from '../UploadFile'
import FileService from '../../../services/fileServices'
import CodeIcon from '@material-ui/icons/CodeRounded'

import { Tooltip } from '@material-ui/core'
import withSubmissionHandler from './withSubmissionHandler'
import ImageTextArea from '../FormElements/ImageTextArea'
import InputLabel from '@material-ui/core/InputLabel'
import styled from 'styled-components'
import DropIcon from '../../../assets/svg-icons/chevron-down.svg'
import TextArea from '../FormElements/TextArea'
import IconButtonWrapper from '../Buttons/IconButton'
import Switch from '../FormElements/Switch'
import { getComments, getCommentsCount } from '../../../services/api'
import CommentSection from '../Comments/CommentSection'

const Wrapper = styled.div`
  margin-bottom: 30px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 70%;
`

const DropHiddenForm = styled.button`
  width: 100%;
  opacity: 30%;
  transition: all 300ms ease-in-out
  &: hover {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
    transform: scale(1.01);
  }
`

class SubTaskForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      st_id:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.ST_ID])
          ? this.props.selectedRow[Keys.ST_ID]
          : generateID(),

      action_menu_id:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.ACTION_MENU_ID])
          ? this.props.selectedRow[Keys.ACTION_MENU_ID]
          : 1,

      pid:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.PID])
          ? this.props.selectedRow[Keys.PID]
          : '',

      type:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.TYPE])
          ? this.props.selectedRow[Keys.TYPE]
          : this.props.creatingSubTaskOfType,

      status:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.STATUS])
          ? this.props.selectedRow[Keys.STATUS]
          : null,

      desc:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.DESC])
          ? this.props.selectedRow[Keys.DESC]
          : '',

      product_title:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.PRODUCT_TITLE])
          ? this.props.selectedRow[Keys.PRODUCT_TITLE]
          : '',

      image:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.IMAGE])
          ? this.props.selectedRow[Keys.IMAGE]
          : '',

      image_orig:
        this.props.editSubTask === true
          ? this.props.selectedRow[Keys.IMAGE]
          : '',

      title:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.TITLE])
          ? this.props.selectedRow[Keys.TITLE]
          : '',

      external_link:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.EXTERNAL_LINK])
          ? this.props.selectedRow[Keys.EXTERNAL_LINK]
          : '',
      order:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.ORDER])
          ? this.props.selectedRow[Keys.ORDER]
          : this.props.selectedCategory[Keys.ST_ORDER].length,

      order_orig:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.ORDER])
          ? this.props.selectedRow[Keys.ORDER]
          : this.props.selectedCategory[Keys.ST_ORDER].length,

      info:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.INFO])
          ? generateTagList(this.props.selectedRow[Keys.INFO])
          : [],

      tags:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.TAGS])
          ? this.props.selectedRow[Keys.TAGS]
          : [],

      comments:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.COMMENTS])
          ? generateTagList(this.props.selectedRow[Keys.COMMENTS])
          : [],

      files:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.FILES])
          ? generateTagList(this.props.selectedRow[Keys.FILES])
          : [],

      extra:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.EXTRA])
          ? generateTagList(this.props.selectedRow[Keys.EXTRA])
          : [],
      cover_img:
        this.props.editSubTask === true &&
        !isNil(this.props.selectedRow[Keys.COVER_IMAGE])
          ? this.props.selectedRow[Keys.COVER_IMAGE]
          : [null],

      is_st_required:
        this.props.editSubTask &&
        !isNil(this.props.selectedRow[Keys.IS_ST_REQUIRED])
          ? this.props.selectedRow[Keys.IS_ST_REQUIRED]
          : false,

      openForm: false,
    }
  }

  handleMenuChange = event => {
    this.setState({ action_menu_id: Number(event.target.value) })
  }

  getMenuOptions = () => {
    let menuOptions = []
    const { statusMenus } = this.props
    const { action_menu_id } = this.state
    Object.keys(statusMenus).forEach((item, index) => {
      const sm_id = statusMenus[item].sm_id
      menuOptions.push(
        <div className="menuOptionsSelectElement" key={index}>
          <Radio
            type="radio"
            id={`${sm_id}`}
            name={`${sm_id}`}
            color="default"
            value={sm_id}
            checked={action_menu_id === sm_id}
            onChange={this.handleMenuChange}
          />
          <Tooltip title={printMenuOptions(statusMenus[item].menu)}>
            <label className="menuOptionsSelectLabel">
              {statusMenus[item].name}
            </label>
          </Tooltip>
        </div>
      )
    })

    return menuOptions
  }

  finishSubmit = (task = null, subTask = null, tasksData = null) => {
    if (!isNil(task)) {
      if (!this._isSubTaskType(Keys.SUB_TASK_PRODUCT)) {
        this.props.submitSubTask(task, null, subTask)
      }
      if (
        this._isSubTaskType(Keys.SUB_TASK_PRODUCT) &&
        get(subTask, Keys.PID, []).length > 0
      ) {
        const taskData = get(tasksData, task[Keys.DATA_OID], null)
        this.props.submitSubTask(task, taskData, subTask)
      }
    }
    this.props.setCreatingSubTaskOfType(null)
    this.props.setEditSubTask(false)
    this.props.setShowItemModal(false)
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleListChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  _image = img => {
    const _img = this.getCoverImage()
    if (!isNil(_img) && _img !== '') {
      return (
        <li className="formListItem">
          <InputLabel shrink id="label">
            {convertUnderscoreNameToLabel(img)}
          </InputLabel>
          <img
            src={_img}
            alt={img}
            style={{ width: '200px', display: 'flex', margin: 'auto' }}
          />
        </li>
      )
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const {
      st_id,
      title,
      tags,
      desc,
      action_menu_id,
      extra,
      files,
      comments,
      info,
      status,
      pid,
      image,
      product_title,
      cover_img,
      order_orig,
      order,
      type,
      external_link,
      is_st_required,
    } = this.state

    const {
      selectedCategory,
      tasks,
      subTasks,
      tasksData,
      selectedTask,
      editSubTask,
    } = this.props

    const selectedCategoryName = selectedCategory.category_name
    let newTasks = [...tasks]
    let newSubTasks = { ...subTasks }
    const taskId = selectedTask[Keys.TASK_ID]
    let editedTask = null

    // set order
    newTasks = newTasks.map((task, index, array) => {
      if (task[Keys.TASK_ID] === taskId && !isNil(task[Keys.CATEGORIES])) {
        task.isEdit = true
        // setOrder
        if (editSubTask === true) {
          task[Keys.CATEGORIES][selectedCategoryName][
            Keys.ST_ORDER
          ] = setNewOrder(
            task[Keys.CATEGORIES][selectedCategoryName][Keys.ST_ORDER],
            order_orig,
            order
          )
        } else {
          task[Keys.CATEGORIES][selectedCategoryName][
            Keys.ST_ORDER
          ] = addNewItemToOrder(
            task[Keys.CATEGORIES][selectedCategoryName][Keys.ST_ORDER],
            order,
            st_id
          )
        } // end setOrder
        editedTask = task
      }
      return task
    })

    const _extra = tagsToList(extra)
    const _files = tagsToList(files)
    const _comments = tagsToList(comments)
    const _info = tagsToList(info)

    let _status = status
    if (status !== null) {
      _status = Number(status)
    }
    if (_status === -100) {
      _status = null
    }
    let subTask = {}
    if (editSubTask === true) {
      subTask = subTasks[st_id]
    }

    subTask = {
      ...subTask,
      st_id: st_id,
      title: title.trim(),
      type: type,
      status: _status,
      is_st_required: is_st_required,
      category: selectedCategory.category_name,
    }

    if (
      !this._isSubTaskType(Keys.SUB_TASK_IMAGE) &&
      !this._isSubTaskType(Keys.SUB_TASK_LINK)
    ) {
      subTask[Keys.ACTION_MENU_ID] = action_menu_id
      subTask[Keys.STATUS] = _status
    }

    if (this._isSubTaskType(Keys.SUB_TASK_PRODUCT)) {
      subTask[Keys.PRODUCT_ID] = pid
      // set taskdata
      if (!this.props.editSubTask) {
        const _pid = pid.trim()
        subTask[Keys.PRODUCT_ID] = [_pid]
        const taskDataId = selectedTask[Keys.DATA_OID]
        if (!isNil(get(tasksData, `${taskDataId}.${[Keys.DATA]}`, null))) {
          tasksData[taskDataId][Keys.DATA][_pid] = {
            [Keys.TITLE]: product_title.trim(),
            [Keys.IMAGE]: emptyStrToNull(image),
          }
        } else {
          alert('task data object missing')
        }
      }
    }
    // set cover_ image
    if (
      this._isSubTaskType(Keys.SUB_TASK_PRODUCT) ||
      this._isSubTaskType(Keys.SUB_TASK_IMAGE)
    ) {
      subTask[Keys.COVER_IMAGE] = cover_img
    }
    if (this._isSubTaskType(Keys.SUB_TASK_LINK)) {
      subTask[Keys.EXTERNAL_LINK] = external_link
    }

    subTask = inReachOptional(subTask, {
      extra: _extra,
      files: _files,
      comments: _comments,
      info: _info,
      tags: tags,
      desc: desc,
    })

    newSubTasks[st_id] = subTask

    newTasks.forEach(task => {
      if (task[Keys.TASK_ID] === taskId) {
        let current_task = task
        current_task.categories[selectedCategoryName][
          Keys.ST_ORDER
        ] = this.sortSubTasks(
          current_task.categories[selectedCategoryName][Keys.ST_ORDER],
          newSubTasks
        )

        this.props.setSelectedCategory({
          ...selectedCategory,
          ...current_task.categories[selectedCategoryName],
        })
        this.props.setSelectedTask({
          ...selectedTask,
          ...current_task,
        })

        return current_task
      }
    })

    this.props.setTasksData(tasksData)
    this.props.setAppTasks(newTasks)
    this.props.setSubTasks(newSubTasks)
    this.props.setCreatingSubTaskOfType(null)
    this.props.setEditSubTask(false)
    this.props.setShowItemModal(false)
  }

  sortSubTasks = (subTasksOrder, subTasks) => {
    const temp = subTasksOrder
    subTasksOrder.sort(function(a, b) {
      const a_index = temp.indexOf(a)
      const b_index = temp.indexOf(b)

      const a_type = subTasks[a][Keys.TYPE]
      const b_type = subTasks[b][Keys.TYPE]

      const diff = a_type - b_type
      if (diff === 0) {
        return a_index - b_index
      } else {
        return diff
      }
    })
    return subTasksOrder
  }

  printStatusOptions = () => {
    const menuType = this.state.action_menu_id
    if (!isNil(menuType)) {
      let statusMap = this.props.statusMenus[menuType]
      let options = []
      if (statusMap !== undefined) {
        statusMap.menu.forEach((item, i) => {
          options.push(
            <option value={item.value} key={item.value} id={item.value}>
              {convertUnderscoreNameToLabel(item.title)}
            </option>
          )
        })
        options = [
          <option value={-1} key={-1} id={-1}>
            {'Status was Changed'}
          </option>,
          <option value={-100} key={-100} id={-100}>
            {'No Status '}
          </option>,
          ...options,
        ]
        return options
      }
    }
  }

  _isSubTaskType = subTaskType => {
    const { st_id } = this.state

    const { subTasks, creatingSubTaskOfType, editSubTask } = this.props
    const val =
      (editSubTask === false && creatingSubTaskOfType === subTaskType) ||
      (editSubTask &&
        !isNil(get(subTasks, st_id, null)) &&
        !isNil(get(subTasks[st_id], Keys.TYPE, null)) &&
        subTasks[st_id][Keys.TYPE] === subTaskType)
    return val
  }

  getCoverImage = () => {
    const { cover_img } = this.state
    if (!isNil(cover_img) && cover_img.length > 0 && !isNil(cover_img[0])) {
      return cover_img[0]
    }
    return ''
  }

  handleCoverImageChange = e => {
    const { cover_img } = this.state
    if (!isNil(cover_img)) {
      this.setState({ cover_img: [emptyStrToNull(e.target.value)] })
    }
  }

  handleDelete = event => {
    const stId2delete = this.state.st_id
    let currentTask = null
    let newTasks = this.props.tasks.map((task, index, array) => {
      const currentTaskId = this.props.selectedTask[Keys.TASK_ID]
      if (
        task[Keys.TASK_ID] === currentTaskId &&
        !isNil(task[Keys.CATEGORIES])
      ) {
        // set data order
        const categoryName = this.props.selectedCategory[Keys.CATEGORY_NAME]
        let index2delete = task.categories[categoryName][Keys.ST_ORDER].indexOf(
          stId2delete
        )

        task.categories[categoryName][Keys.ST_ORDER].splice(index2delete, 1)
        this.props.setSelectedCategory({
          ...this.props.selectedCategory,
          ...task.categories[categoryName],
        })
        this.props.setSelectedTask({ ...this.props.selectedTask, ...task })
        currentTask = task
      }
      return task
    })
    this.props.setAppTasks(newTasks)
    this.props.submitTask(currentTask, null, null, stId2delete)
    this.finishSubmit()
  }
  switchActive = e => {
    this.setState({ [e.target.value]: e.target.checked })
  }

  handleImageUpload = async (e, imageName) => {
    this.props.showFullScreenLoader()
    const file = e.target.files[0]
    let files = this.state[imageName]

    const res = await FileService.uploadFile(file, this.props.selected_rid)

    if (imageName === Keys.IMAGE) {
      this.setState({ [imageName]: res })
    } else {
      files.push({ tag: res })
      this.setState({ [imageName]: [...files] })
    }

    this.props.hideFullScreenLoader()
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  setImage = image => {
    this.setState({
      image: image,
    })
  }

  render() {
    return (
      <form
        className="itemForm"
        onSubmit={this.handleSubmit}
        onKeyPress={this.preventSubmit}
      >
        <ul className="formList">
          <Row>
            <Input
              value={this.state.st_id}
              onChange={this.handleChange}
              type="text"
              name={Keys.ST_ID}
              required={true}
              label={'SubTask ID'}
              readOnly={true}
            />

            <Input
              value={this.state.title}
              onChange={this.handleChange}
              type="text"
              name={Keys.TITLE}
              required={true}
              label={'SubTask Title'}
            />
          </Row>

          {this._isSubTaskType(Keys.SUB_TASK_PRODUCT) &&
          this.props.editSubTask === false &&
          this.props.showItemModal ? (
            <React.Fragment>
              <Input
                value={this.state.pid}
                onChange={this.handleChange}
                type="text"
                name={Keys.PID}
                required={true}
                label={'Product ID'}
              />
              <Input
                value={this.state.product_title}
                onChange={this.handleChange}
                type="text"
                name={Keys.PRODUCT_TITLE}
                required={true}
                label={'Product title'}
              />
              <ImageTextArea
                name={Keys.IMAGE}
                value={this.state.image}
                itemId={this.state.pid}
                setImage={this.setImage}
                onChange={this.handleChange}
              >
                <UploadFile
                  setShowValidationPassword={
                    this.props.setShowValidationPassword
                  }
                  onChange={e => this.handleImageUpload(e, Keys.IMAGE)}
                />
              </ImageTextArea>
            </React.Fragment>
          ) : null}
          <Row>
            <Input
              value={this.state.order}
              onChange={this.handleChange}
              type="number"
              name={Keys.ORDER}
              required={true}
              label={'Order'}
            />
            <Switch
              value={'is_st_required'}
              label={'is sub task required'}
              onChange={this.switchActive}
              checked={this.state.is_st_required}
            />
          </Row>
          {!this._isSubTaskType(Keys.SUB_TASK_IMAGE) &&
            !this._isSubTaskType(Keys.SUB_TASK_UPLOAD_FILE) && (
              <Select
                label="Current status"
                name={Keys.STATUS}
                value={this.state.status === null ? -100 : this.state.status}
                onChange={this.handleChange}
              >
                {this.printStatusOptions()}
              </Select>
            )}

          {!this._isSubTaskType(Keys.SUB_TASK_IMAGE) &&
          !this._isSubTaskType(Keys.SUB_TASK_LINK) &&
          !this._isSubTaskType(Keys.SUB_TASK_UPLOAD_FILE) ? (
            <React.Fragment>
              <Wrapper>
                <InputLabel shrink id="label">
                  Status Menus
                </InputLabel>
                <div className="formRadioSelectWrapper">
                  {this.getMenuOptions()}
                </div>
              </Wrapper>

              <TextArea
                onChange={this.handleChange}
                name={'desc'}
                label={'Description'}
                value={this.state.desc}
              >
                <IconButtonWrapper
                  color={'orange'}
                  icon={<CodeIcon fontSize={'small'} />}
                  onClick={e => {
                    e.preventDefault()
                    window.open('https://html5-editor.net/')
                  }}
                />
              </TextArea>

              <InputLabel shrink id="label">
                Comments
              </InputLabel>
              <CommentSection oid={this.state.st_id} />

              {this.state.openForm ? (
                <div>
                  <SortableEditableListWrapper
                    items={this.state.files}
                    setItems={this.handleListChange}
                    label={'Files'}
                    name={Keys.FILES}
                    placeholder={'Add any file url, and press Enter...'}
                  >
                    <UploadFile
                      setShowValidationPassword={
                        this.props.setShowValidationPassword
                      }
                      onChange={e => this.handleImageUpload(e, Keys.FILES)}
                    />
                  </SortableEditableListWrapper>

                  <SortableEditableListWrapper
                    items={this.state.extra}
                    setItems={this.handleListChange}
                    label={'Extra'}
                    name={Keys.EXTRA}
                    placeholder={'Type any Extra, and press Enter...'}
                  />

                  <SortableEditableListWrapper
                    items={this.state.info}
                    setItems={this.handleListChange}
                    label={'Info'}
                    name={Keys.INFO}
                    placeholder={'Type any Info, and press Enter...'}
                  />

                  <SortableEditableListWithColorWrapper
                    label={'Tags'}
                    items={this.state.tags}
                    setItems={this.handleListChange}
                    name={Keys.TAGS}
                    placeholder={
                      'add any tag, and press Enter, then choose color...'
                    }
                  />
                </div>
              ) : (
                <DropHiddenForm
                  bg={Colors.DISABLE}
                  onClick={() => {
                    this.setState({ openForm: !this.state.openForm })
                  }}
                >
                  <img
                    src={DropIcon}
                    style={{ height: '30px', width: '100px' }}
                  />
                </DropHiddenForm>
              )}
            </React.Fragment>
          ) : this._isSubTaskType(Keys.SUB_TASK_IMAGE) ? (
            <React.Fragment>
              <Input
                value={this.getCoverImage()}
                onChange={this.handleCoverImageChange}
                type="text"
                name={Keys.COVER_IMAGE}
                label={'Cover Image'}
              />
              {this._image(Keys.COVER_IMAGE)}
            </React.Fragment>
          ) : this._isSubTaskType(Keys.SUB_TASK_LINK) ? (
            <Input
              value={this.state.external_link}
              onChange={this.handleChange}
              type="text"
              name={Keys.EXTERNAL_LINK}
              label={'External Link'}
            />
          ) : null}
          <li className="formButtonDash">
            <Button
              type={'submit'}
              label={this.props.editSubTask ? 'Save Item Changes' : 'Add Item'}
            />
            {this.props.editSubTask ? (
              <Button
                onClick={this.handleDelete}
                label={'Delete'}
                color={'secondary'}
              />
            ) : null}
          </li>
        </ul>
      </form>
    )
  }
}

export default withSubmissionHandler(SubTaskForm)
