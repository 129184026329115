import React from 'react'
import '../styles/formStyle.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Select from './Select'
import ColorSelect from './ColorSelect'
import CloseIcon from '../../../assets/svg-icons/error.svg'
import styled from 'styled-components'
import isNil from 'lodash/isNil'

const StyleSelect = styled(Select)`
  margin-top: 10px;
`

const StyledImage = styled.img`
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0px 5px 0px 0px;
  cursor: pointer;
  top: -13px;
  right: -10px;
`

const Toggle = styled.div`
  text-align: right;
`

const BoxLabel = styled.label`
  display: block;
  float: left;
  margin-top: -30px;
  margin-bottom: 0;
  background: #ffffff;
  height: 20px;
  padding: 2px 5px 2px 5px;
  color: #b9b9b9;
  font-size: 14px;
  overflow: hidden;
`

const COLUMN1 = 0
const COLUMN2 = 2
const COMPERATOR = 1

class RuleBoxColor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columnNumber: this.props.columnNumber,
      rule_comparator: this.getRuleParts(COMPERATOR),
      column1: this.getRuleParts(COLUMN1),
      column2: this.getRuleParts(COLUMN2),
      target_column: this.props.target_column,
      False: this.props.False,
      True: this.props.True,
    }
  }

  getRuleParts = _index => {
    if (!isNil(this.props.rule) && this.props.rule.length > _index) {
      if (this.props.rule[_index] === null) {
        return ''
      }
      return this.props.rule[_index]
    }
  }

  printColumnOptions = () => {
    const columnNumber = this.props.columnNumber
    if (columnNumber !== undefined) {
      return [
        <option value="" id="" key={'-1'}>
          {`Selected Somthing`}
        </option>,
        Array(columnNumber)
          .fill()
          .map((val, i) => {
            return (
              <option value={i} key={i} id={i}>
                {`column No.${i + 1}`}
              </option>
            )
          }),
      ]
    }
  }

  printComperatorsOptions = () => {
    const comperators = ['<', '>', '=', '≠']
    return comperators.map((val, i) => {
      return (
        <option value={val} key={i} id={i}>
          {val}
        </option>
      )
    })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  deleteRule = () => {
    this.props.deleteTableRule(this.props.index)
  }

  render() {
    const { index } = this.props
    return (
      <form className="itemForm ruleBoxWrapper">
        <BoxLabel>{`Rule ${this.props.index + 1}`}</BoxLabel>
        <Toggle>
          {' '}
          <StyledImage src={CloseIcon} onClick={this.deleteRule} />
        </Toggle>
        <ul className="formList">
          <div className={'formRow'}>
            <StyleSelect
              label="column"
              name={'column1'}
              value={this.getRuleParts(COLUMN1)}
              onChange={event => this.props.handleRuleChange(event, index)}
            >
              {this.printColumnOptions()}
            </StyleSelect>
            <StyleSelect
              label="Compare"
              name={'rule_comparator'}
              value={this.getRuleParts(COMPERATOR)}
              onChange={event => this.props.handleRuleChange(event, index)}
            >
              {this.printComperatorsOptions()}{' '}
            </StyleSelect>
            <StyleSelect
              label="column"
              name={'column2'}
              value={this.getRuleParts(COLUMN2)}
              onChange={event => this.props.handleRuleChange(event, index)}
            >
              {this.printColumnOptions()}
            </StyleSelect>
          </div>
          <Select
            label="Target Column"
            name={'target_column'}
            value={
              this.props.target_column === null ? '' : this.props.target_column
            }
            onChange={event => this.props.handleRuleChange(event, index)}
          >
            {this.printColumnOptions()}
          </Select>
          <div className={'formRow'}>
            <ColorSelect
              color={this.props.True}
              index={index}
              name={'True'}
              label={'True color'}
              onColorChange={event =>
                this.props.handleColorTrueRuleChange(event, index)
              }
            />

            <ColorSelect
              name={'False'}
              index={index}
              color={this.props.False}
              label={'False color'}
              onColorChange={event =>
                this.props.handleColorFalseRuleChange(event, index)
              }
            />
          </div>
        </ul>
      </form>
    )
  }
}

const mapStateToProps = ({ loading }) => ({ loading })

export default connect(
  mapStateToProps,
  actions
)(RuleBoxColor)
