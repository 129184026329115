import React from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../../store/actions'
import DeleteTaskIcon from '../../../assets/svg-icons/cross-red.svg'
import CloneIcon from '../../../assets/svg-icons/clone.svg'
import PublishIcon from '../../../assets/svg-icons/paper-plane.svg'
import Keys from '../../../utils/Keys'
import TaskTableExpandComponent from '../../Elements/TaskTableExpandComponent'
import ExpendedIcon from '../../../assets/svg-icons/chevron-up.svg'
import UnExpendedIcon from '../../../assets/svg-icons/chevron-down.svg'
import { epochTime2date, timestamp2tableDate } from '../../../utils/dateHandler'
import Colors from '../../../utils/Colors'
import '../style.css'
import moment from 'moment'

const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
}

const withTasksTable = WrappedComponent => {
  class WithTasksTable extends React.Component {
    constructor(props) {
      super(props)
    }

    handleDeleteTask = (cell, row, rowIndex) => {
      this.props.setShowTasksModal(false)
      this.props.setShowDeleteTaskWarningModal(true)
    }

    handleCopyTask = (cell, row, rowIndex) => {
      this.props.setShowTasksModal(false)
      this.props.setShowCopyTaskModal(true)
    }

    handleCloneTask = (cell, row, rowIndex, e) => {
      e.stopPropagation()
      this.props.setSelectedTask(row)
      this.props.setShowTasksModal(false)
      this.props.setShowCloneTaskModal(true)
    }

    handleSubmitTask = (row, e) => {
      e.stopPropagation()
      this.props.setSelectedTask(row)
      this.props.setShowAssigneeTaskModal(true)
    }

    tableActions = (cell, row, enumObject, rowIndex) => {
      let bool = false
      let temp = this.props.changedTasks
      if (temp.indexOf(row.tid) >= 0) {
        bool = true
      }

      return (
        <div className={'buttonDash'}>
          {(row.cloned || (row.isEdit && !row.isNewTask)) && (
            <button
              className={'tableButton'}
              type="button"
              onClick={e => this.handleSubmitTask(row, e)}
            >
              <img
                className={'tableIcon'}
                title={'Publish'}
                src={PublishIcon}
              />
            </button>
          )}
          {!row.cloned && !row.isNewTask && !row.isEdit && (
            <button
              className={'tableButton'}
              type="button"
              onClick={e => this.handleCloneTask(cell, row, rowIndex, e)}
            >
              <img
                className={'tableIcon'}
                title={'Clone task'}
                src={CloneIcon}
              />
            </button>
          )}
          {row.isNewTask && (
            <button
              className={'tableButton'}
              type="button"
              onClick={e => this.handleSubmitTask(row, e)}
            >
              <img
                className={'tableIcon'}
                title={'Publish'}
                src={PublishIcon}
              />
            </button>
          )}

          <button
            className={'tableButton'}
            type="button"
            onClick={() => this.handleDeleteTask(cell, row, rowIndex)}
          >
            <img
              className={'tableIcon'}
              title={'Delete task'}
              src={DeleteTaskIcon}
            />
          </button>
          {/*<button*/}
          {/*  className={'tableButton'}*/}
          {/*  type="button"*/}
          {/*  onClick={() => this.handleCopyTask(cell, row, rowIndex)}*/}
          {/*>*/}
          {/*  <img className={'tableIcon'} title={'Copy task'} src={CopyIcon} />*/}
          {/*</button>*/}
        </div>
      )
    }

    _print_type_column = (typeMap, cell) => {
      let typeStr = <span>{'Others'}</span>
      if (cell !== undefined) {
        Object.keys(typeMap).forEach(key => {
          if (Number(cell) === typeMap[key]) {
            typeStr = <span>{key}</span>
          }
        })
      }
      return typeStr
    }

    _type = cell => {
      return this._print_type_column(this.props.taskTypeMap, cell)
    }

    _sub_type = cell => {
      return this._print_type_column(this.props.taskTypesView, cell)
    }

    _active = cell => {
      return (
        <li
          className={'tagItem'}
          style={
            cell
              ? {
                  backgroundColor: Colors.ACTION,
                  color: 'white',
                  padding: '3px',
                }
              : {
                  backgroundColor: Colors.DANGER,
                  color: 'white',
                  padding: '3px',
                }
          }
        >
          <span>{cell ? 'Active' : 'Inactive'}</span>
        </li>
      )
    }

    _timestamp = (cell, row) => {
      if (cell !== undefined && cell !== '') {
        return timestamp2tableDate(cell)
      }
    }

    _epochTime2date = (cell, row) => {
      if (cell !== undefined) {
        return epochTime2date(cell, 'YYYY-MM-DD HH:mm:ss')
      }
    }

    handelRowClick = row => {
      this.props.setExpendedCategory([])
      if (!row.isNewTask && !row.cloned) {
        row.is_edit_mode = true
        row.utc_offset = Number(moment().utcOffset() / 60)
      }
      this.props.setSelectedTask(row)
    }

    isExpandableTask = task_id => {
      const ExpandableTaskTypes = [
        Keys.TASK_TYPE_GENERAL,
        Keys.TASK_TYPE_REPORT,
      ]
      const { reportTasks, tasks } = this.props
      let isExpandable = false
      reportTasks.forEach(task => {
        if (task[Keys.TASK_ID] === task_id) {
          isExpandable = true
        }
      })

      tasks.forEach(task => {
        if (
          task[Keys.TASK_ID] === task_id &&
          ExpandableTaskTypes.includes(task[Keys.TYPE])
        ) {
          isExpandable = true
        }
      })

      return isExpandable
    }

    _getExpandColumn = (expanded, rowKey, expandable) => {
      if (this.isExpandableTask(rowKey)) {
        return (
          <div>
            {expanded && expandable ? (
              <img className={'tableIcon arrowIcon'} src={ExpendedIcon} />
            ) : (
              <img className={'tableIcon arrowIcon'} src={UnExpendedIcon} />
            )}
          </div>
        )
      } else {
        return <div />
      }
    }

    render() {
      const expandRow = {
        nonExpandable: this.props.nonExpandable,
        renderer: row => <TaskTableExpandComponent row={row} />,
        onExpand: (row, isExpand, rowIndex, e) => {
          this.handelRowClick(row)
        },

        expandColumnRenderer: ({ expanded, rowKey, expandable }) =>
          this._getExpandColumn(expanded, rowKey, expandable),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => <div />,
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        width: 70,
      }

      const columns = [
        {
          dataField: Keys.TASK_ID,
          text: 'Task Id',
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.VIEW,
          text: 'View',
          formatter: this._sub_type,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.TITLE,
          text: 'Task Title',
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.TYPE,
          text: 'Type',
          formatter: this._type,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.ORDER,
          text: 'Order',
          headerStyle: { ...headerStyle, width: 100 },
        },
        {
          dataField: Keys.FROM_TS,
          text: 'Start of Campaign',
          formatter: this._timestamp,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.DEADLINE,
          text: 'End of campaign',
          formatter: this._timestamp,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.TO_TS,
          text: 'Removal date',
          formatter: this._timestamp,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.ACTIVE,
          text: 'Activation',
          formatter: this._active,
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.CREATED_BY,
          text: 'Created By',
          headerStyle: headerStyle,
        },
        {
          dataField: Keys.CREATED_AT_TS,
          text: 'Created at',
          formatter: this._epochTime2date,
          headerStyle: headerStyle,
        },
        {
          dataField: 'button',
          text: 'Action',
          formatter: this.tableActions,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              this.props.setShowTasksModal(false)
            },
          },
          headerStyle: headerStyle,
        },
      ]

      const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        hideSelectAll: true,

        onSelect: (row, isSelect, rowIndex, e) => {
          this.handelRowClick(row)
          this.props.setEditTask(true)
          this.props.setShowTasksModal(true)
          const newTaskList = this.props.tasks.map(task =>
            task.tid === row.tid ? { ...task, isEdit: true } : task
          )
          this.props.setAppTasks(newTaskList)
        },
      }

      return (
        <WrappedComponent
          selectRow={selectRow}
          columns={columns}
          expandRow={expandRow}
          _active={this._active}
          _timestamp={this._timestamp}
          tableActions={this.tableActions}
          handelRowClick={this.handelRowClick}
          _getExpandColumn={this._getExpandColumn}
          {...this.props}
        />
      )
    }
  }

  const mapStateToProps = ({
    tasks,
    reportTasks,
    tasksData,
    selected_rid,
    selected_fromdate,
    selected_todate,
    showItemModal,
    thisWeek,
    taskTypeMap,
    taskTypesView,
    changedTasks,
    nonExpandable,
    setShowCloneTaskModal,
    subTasks,
    selectedTask,
    selectedGroup,
    setShowTasksModal,
    showTasksModal,
    setSelectedTask,
    setTasksAndTasksData,
  }) => ({
    tasks,
    reportTasks,
    tasksData,
    selected_rid,
    selected_fromdate,
    selected_todate,
    showItemModal,
    thisWeek,
    taskTypeMap,
    taskTypesView,
    changedTasks,
    nonExpandable,
    setShowCloneTaskModal,
    subTasks,
    selectedTask,
    selectedGroup,
    setShowTasksModal,
    showTasksModal,
    setSelectedTask,
    setTasksAndTasksData,
  })

  return connect(mapStateToProps, actions)(WithTasksTable)
}

export default withTasksTable
