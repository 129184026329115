import { GroupCheckbox } from '../../../components/GroupCheckbox'
import React, { useState, useEffect } from 'react'

export default function TaskUsersList({
  users,
  selectedUsers,
  handleSelectUsers,
}) {
  const [searchQuery, setSearchQuery] = useState('')
  const [founedUsers, setFoundUsers] = useState([])
  useEffect(() => {
    const copyedUsers = [...users]
    setFoundUsers(
      copyedUsers.filter(
        user =>
          user?.title?.toLowerCase()?.includes(searchQuery) ||
          '' ||
          user?.desc?.toLowerCase()?.includes(searchQuery) ||
          '' ||
          user?.sec_uid?.toLowerCase()?.includes(searchQuery) ||
          '' ||
          user?.uid?.toLowerCase()?.includes(searchQuery) ||
          ''
      )
    )
  }, [searchQuery])

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Search by user properties"
          value={searchQuery}
          style={{ width: '200px' }}
          onChange={e => setSearchQuery(e.target.value)}
        ></input>
      </div>
      {founedUsers.length
        ? founedUsers.map(user => (
            <GroupCheckbox
              isChecked={selectedUsers.includes(user.uid)}
              value={user.uid}
              onChange={handleSelectUsers}
              marginBottom="10px"
              title={`${user.first_name} ${user?.last_name ?? ''}`}
            >
              {user?.uid && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  uid: {user.uid}
                </div>
              )}
              {user?.desc && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  desc: {user.desc}
                </div>
              )}
              {user?.sec_uid && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  sec uid: {user.sec_uid}
                </div>
              )}
            </GroupCheckbox>
          ))
        : users.map(user => (
            <GroupCheckbox
              isChecked={selectedUsers.includes(user.uid)}
              value={user.uid}
              onChange={handleSelectUsers}
              title={`${user.first_name} ${user?.last_name ?? ''}`}
            >
              {user?.uid && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  uid: {user.uid}
                </div>
              )}
              {user?.desc && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  desc: {user.desc}
                </div>
              )}
              {user?.sec_uid && (
                <div
                  className="groupName"
                  style={{ fontSize: '12px', color: 'gray' }}
                >
                  sec uid: {user.sec_uid}
                </div>
              )}
            </GroupCheckbox>
          ))}
    </div>
  )
}
