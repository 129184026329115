import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import '../styles/formStyle.css'
import Keys from '../../../utils/Keys'
import { epochTime2date, timestamp2date } from '../../../utils/dateHandler'
import Input from '../FormElements/Input'
import Switch from '../FormElements/Switch'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const TaskType1Details = props => {
  return (
    <form className="itemForm">
      <ul className="formList">
        <Row>
          <Input
            value={props.ref_id}
            type="text"
            name={Keys.REFERENCE_ID}
            required={true}
            label={'Reference ID'}
            readOnly={true}
          />
          <Input
            value={props.tid}
            type="text"
            name={Keys.TASK_ID}
            required={true}
            label={'Task ID'}
            readOnly={true}
          />

          <Switch
            value={'active'}
            label={'Active'}
            onChange={props.switchActive}
            checked={props.active}
            required={true}
          />
        </Row>
        <Row>
          <Input
            value={props.page_title}
            onChange={props.handleDetailsChange}
            type="text"
            name={Keys.PAGE_TITLE}
            required={true}
            label={'Page Title'}
          />

          <Input
            value={props.order}
            onChange={props.handleDetailsChange}
            type="number"
            name={Keys.ORDER}
            required={true}
            label={'Order'}
          />
        </Row>

        <Row>
          <Input
            value={timestamp2date(props.from_ts)}
            onChange={props.handleDetailsChange}
            type="datetime-local"
            name={Keys.FROM_TS}
            required={true}
            label={'Start of campaign'}
            step={'1'}
          />
          <Input
            value={timestamp2date(props.to_ts)}
            onChange={props.handleDetailsChange}
            type="datetime-local"
            name={Keys.TO_TS}
            required={true}
            label={'End of campaign'}
            step={'1'}
          />
        </Row>
        <Row>
          {props.editTask === true && props.update_ts !== null ? (
            <Input
              value={epochTime2date(props.update_ts)}
              type="datetime-local"
              name={Keys.UPDATE_TS}
              label={'Last update'}
              step={'1'}
              readOnly={true}
            />
          ) : null}

          {props.editTask === true && props.created_at_ts !== null ? (
            <Input
              value={epochTime2date(props.created_at_ts)}
              type="datetime-local"
              name={Keys.CREATED_AT_TS}
              label={'Created at'}
              step={'1'}
              readOnly={true}
            />
          ) : null}
        </Row>
      </ul>
    </form>
  )
}

const mapStateToProps = ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
}) => ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
})

export default connect(
  mapStateToProps,
  actions
)(TaskType1Details)
