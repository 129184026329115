import React, { Component } from 'react'
import styled from 'styled-components'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import './style.css'
import Colors from '../../utils/Colors'
import Keys from '../../utils/Keys'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import ImageIcon from '../../assets/svg-icons/camera.svg'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import ExpandAllIcon from '../../assets/svg-icons/add.svg'
import SubTaskTableExpandComponent from '../Elements/SubTaskTableExpandComponent'
import ExpendedIcon from '../../assets/svg-icons/chevron-up.svg'
import UnExpendedIcon from '../../assets/svg-icons/chevron-down.svg'
import FileService from '../../services/fileServices'
import { convertUnderscoreNameToLabel } from '../../utils/taskHandler'
import TextAddButton from '../Elements/Buttons/TextAddButton'
import { getCommentsCount } from '../../services/api'
import CommentCounter from '../Elements/Comments/CommentCounter'

const TableWrapper = styled.div``
const Icon = styled.img`
  width: 30px;
  height: 30px;
`

const ButtonDash = styled.div`
  display: flex;
  flex-direction: row;
`

const Alink = styled.a`
  z-index: 100;
`

const Span = styled.span`
  display: block;
  z-index: 100;
`

const ExpandAllWrapper = styled.div`
  overflow: hidden;
  transform: rotate(0deg);
  transition: all 0.3s ease-out;
  transform: ${props => (props.expended ? `rotate(45deg)` : '')};
`

class SubTaskTableType0 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      category_name: this.props.selectedCategory.category_name,
    }
  }

  handelRowClick = row => {
    this.props.setSelectedRow(row)
    this.props.setSelectedSubTask(row)
  }

  _action_menu = cell => {
    const { statusMenus } = this.props
    let action_menu = ''
    if (!isNil(cell)) {
      Object.keys(statusMenus).forEach(key => {
        if (statusMenus[key].sm_id === cell) {
          action_menu = statusMenus[key].name
        }
      })
    }
    return action_menu
  }

  _type = cell => {
    if (!isNil(cell)) {
      return this.props.subTaskType[cell]
    }
  }

  _status = (cell, row) => {
    const { statusMenus } = this.props
    if (isNil(cell) || isNil(row[Keys.ACTION_MENU_ID])) {
      return 'no status was given'
    } else if (
      isNil(statusMenus[row[Keys.ACTION_MENU_ID]]) ||
      isNil(statusMenus[row[Keys.ACTION_MENU_ID]].menu[cell])
    ) {
      return 'this menu is not supported'
    } else {
      return convertUnderscoreNameToLabel(
        statusMenus[row[Keys.ACTION_MENU_ID]].menu[cell].title
      )
    }
  }

  _files = files => {
    let links = []
    if (files !== undefined && files !== null) {
      const curFiles = files.filter(file => {
        if (!FileService.isImageFileName(file)) {
          return true
        }
      })

      curFiles.forEach((file, index) => {
        links.push(
          <Alink href={file} key={index} target="_blank">
            <Span>File {index + 1}</Span>
          </Alink>
        )
      })
    }
    return links
  }

  _images = files => {
    let images = []
    if (files !== undefined && files !== null) {
      images = files.filter(file => {
        if (FileService.isImageFileName(file)) {
          return true
        }
      })
    }
    if (images.length > 0) {
      return (
        <button
          className="categoryImagesButton"
          type={'button'}
          onClick={() => {
            this.props.setCarouselModalImages(images)
            this.props.setEditSubTask(false)
            this.props.setShowCategoryModal(false)
            this.props.setShowImageCarouselModal(true)
          }}
        >
          <Icon src={ImageIcon} />
        </button>
      )
    }
  }

  getCount = async st_id => {
    const comment_num = await getCommentsCount(res => {
      if (res.status === 200) {
        return res[st_id]
      }
    }, st_id)
  }

  _comment_number = (cell, row) => {
    const st_id = row['st_id']
    return <CommentCounter oid={st_id} />
  }
  _SubTaskIsTypeProduct = st_id => {
    const { subTasks } = this.props
    const subTask = subTasks[st_id]
    return (
      !isNil(get(subTask, Keys.TYPE, null)) &&
      subTask[Keys.TYPE] === Keys.SUB_TASK_PRODUCT
    )
  }

  getItems = () => {
    let items = []
    const { selectedTask, subTasks, tasksData } = this.props
    const categoryName = this.state.category_name
    const category = selectedTask.categories[categoryName]
    if (category !== undefined) {
      category[Keys.ST_ORDER].forEach((subTaskName, index) => {
        const subTask = subTasks[subTaskName]
        if (!isNil(subTask)) {
          let item = { order: index, ...subTask }
          if (!isNil(subTask[Keys.FILES])) {
            item[Keys.FILES] = subTask[Keys.FILES]
            item[Keys.IMAGES] = subTask[Keys.FILES]
          }

          if (subTask[Keys.TYPE] === Keys.SUB_TASK_PRODUCT) {
            const pid = subTask[Keys.PRODUCT_ID]
            const taskDataOid = selectedTask[Keys.DATA_OID]
            const taskData = taskDataOid !== undefined && tasksData[taskDataOid]
            if (!isNil(taskData)) {
              item[Keys.PRODUCT_TITLE] = []
              item[Keys.IMAGE] = []
              pid.forEach(product_id => {
                const productData = taskData[Keys.DATA][product_id]
                item[Keys.PRODUCT_TITLE].push(productData[Keys.TITLE])
                item[Keys.IMAGE].push(productData[Keys.IMAGE])
              })
            }
          }
          items.push(item)
        } else {
          this.props.handleAlert({
            text: `SubTask: ${subTaskName} was not sent by the server`,
            type: 'warning',
          })
        }
      })
    }
    return items
  }

  _getExpandColumn = (expanded, rowKey, expandable) => {
    if (this._SubTaskIsTypeProduct(rowKey)) {
      return (
        <div>
          {expanded && expandable ? (
            <img
              className={'tableIcon arrowIcon'}
              style={{ marginLeft: '11px' }}
              src={ExpendedIcon}
            />
          ) : (
            <img
              className={'tableIcon arrowIcon'}
              style={{ marginLeft: '11px' }}
              src={UnExpendedIcon}
            />
          )}
        </div>
      )
    } else {
      return <div />
    }
  }

  handleAddSubTaskClick = st_id => {
    this.props.setShowItemModal(true)
    this.props.setCreatingSubTaskOfType(st_id)
  }

  render() {
    const tableColor = {}
    const headerStyle = {
      textAlign: 'center',
      fontWeight: 'bold',
    }

    const columns = [
      {
        dataField: Keys.ST_ID,
        text: 'SubTask ID',
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.TYPE,
        text: 'Type',
        sort: true,
        formatter: this._type,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.TITLE,
        text: 'SubTask Title',
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.ACTION_MENU_ID,
        text: 'Menu Type',
        formatter: this._action_menu,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.ORDER,
        text: 'Order',
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: 'comments',
        isDummyField: true,
        text: 'Comments',
        sort: true,
        formatter: this._comment_number,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.IMAGES,
        text: 'Images',
        formatter: this._images,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.FILES,
        text: 'Files',
        formatter: this._files,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.STATUS,
        text: 'Status',
        formatter: this._status,
        headerStyle: headerStyle,
      },
    ]

    const expandRow = {
      renderer: row => <SubTaskTableExpandComponent subTask={row} />,
      onExpand: (row, isExpand, rowIndex, e) => {
        this.handelRowClick(row)
        this.props.setSelectedSubTask(row)
      },
      expandColumnRenderer: ({ expanded, rowKey, expandable }) =>
        this._getExpandColumn(expanded, rowKey, expandable),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => (
        <div
          style={{
            height: '48px',
          }}
        >
          <ExpandAllWrapper
            expended={isAnyExpands}
            style={{
              backgroundColor: 'transparent',
              padding: '6px',
            }}
          >
            <img className={'expendAllIcon'} src={ExpandAllIcon} />
          </ExpandAllWrapper>
        </div>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
      width: 70,
    }
    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      hideSelectAll: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handelRowClick(row, isSelect, rowIndex, e)
        this.props.setShowItemModal(true)
        this.props.setEditSubTask(true)
      },
    }

    return (
      <TableWrapper className="itemTableWrapper" tableColor={tableColor}>
        {this.props.tasks.length === 0 ? (
          'Nothing to Show'
        ) : (
          <BootstrapTable
            keyField={Keys.ST_ID}
            expandRow={expandRow}
            data={this.getItems()}
            selectRow={selectRow}
            columns={columns}
            bordered={false}
            rowStyle={{
              backgroundColor: tableColor.color,
            }}
          />
        )}
        <ButtonDash>
          <TextAddButton
            label={'Add product'}
            color={'green'}
            onClick={() => {
              this.handleAddSubTaskClick(Keys.SUB_TASK_PRODUCT)
            }}
          />
          <TextAddButton
            label={'Add question'}
            color={'green'}
            onClick={() => {
              this.handleAddSubTaskClick(Keys.SUB_TASK_TEXT)
            }}
          />
          <TextAddButton
            label={'Add image'}
            color={'green'}
            onClick={() => {
              this.handleAddSubTaskClick(Keys.SUB_TASK_IMAGE)
            }}
          />
          <TextAddButton
            label={'Add file'}
            color={'green'}
            onClick={() => {
              this.handleAddSubTaskClick(Keys.SUB_TASK_UPLOAD_FILE)
            }}
          />
          <TextAddButton
            label={'Add Link'}
            color={'green'}
            onClick={() => {
              this.handleAddSubTaskClick(Keys.SUB_TASK_LINK)
            }}
          />
        </ButtonDash>
      </TableWrapper>
    )
  }
}

const mapStateToProps = ({
  tasks,
  tasksData,
  subTasks,
  selected_rid,
  selected_sid,
  showItemModal,
  taskTypeMap,
  taskTypesView,
  subTaskType,
  changedTasks,
  statusMenus,
}) => ({
  tasks: tasks,
  tasksData,
  subTasks,
  selected_rid,
  selected_sid,
  showItemModal,
  taskTypeMap,
  taskTypesView,
  subTaskType,
  changedTasks,
  statusMenus,
})

export default connect(mapStateToProps, actions)(SubTaskTableType0)
