import React, { useEffect } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import withTasksTable from './withTasksTable'
import { setTaskToLocalStorage } from '../../../utils/taskHandler'
import '../style.css'

const AppTasksTable = props => {
  const getData = () => {
    if (props.tasks === null) {
      return []
    } else {
      return props.tasks
    }
  }

  useEffect(() => {
    const newTask = getData().filter(task => task.isNewTask)[0]
    if (newTask) {
      const oid = newTask.data_oid
      const taskData = { [oid]: props.tasksData?.[oid] }
      const categories = Object.keys(newTask.categories)
      const subtasksList = categories
        .map(categoryName => newTask.categories[categoryName].sub_tasks_order)
        .flat(Infinity)
      const subtasks = {}
      subtasksList.forEach(
        subtaskId => (subtasks[subtaskId] = props.subTasks[subtaskId])
      )
      const { name } = props.selectedGroup
      const { selected_rid } = props
      setTaskToLocalStorage(selected_rid, name, {
        task: newTask,
        subtasks,
        taskData,
      })
    }
  }, [props.tasks, props.subTasks])

  const rowClasses = row => {
    let classes = ''
    if (row.view === 0) {
      classes += `DataRow `
    } else if (row.view === 1) {
      classes += `OperationalRow `
    } else if (row.view === 2) {
      classes += `TrainingRow `
    } else if (row.view === 3) {
      classes += 'HistoryRow '
    }
    if (row.cloned) {
      classes += ' clonedTask'
    }

    if (row.isNewTask) {
      classes += ' newTask'
    }

    if (row.isNewTask && row.isEdit) {
      classes += ' editTask'
    }

    return classes
  }

  return (
    <div className={'tableWrapper'}>
      {props.tasks === null || props.tasks.length === 0 ? (
        <div> No Tasks to Show </div>
      ) : (
        <BootstrapTable
          keyField="tid"
          data={getData()}
          expandRow={props.expandRow}
          selectRow={props.selectRow}
          columns={props.columns}
          bordered={false}
          rowClasses={rowClasses}
        />
      )}
    </div>
  )
}
export default withTasksTable(AppTasksTable)
