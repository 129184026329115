import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/formStyle.css'
import PropTypes from 'prop-types'
import SortableEditableListWithColor from './SortableEditableListWithColor'
import Colors from '../../../utils/Colors'
import InputLabel from "@material-ui/core/InputLabel";

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.enter]

class SortableEditableListWithColorWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
      new_item: '',
    }
  }

  onTagEdite = (e, index) => {
    let items = this.state.items
    items[index].tag = e.target.value
    this.setState({ items: items })
    this.props.setItems(items)
  }

  onColorChangeTag = (e, index, temp) => {
    let items = this.state.items
    items[index].bg = e.target.name
    this.setState({ items: items })
  }

  onCloseTag = (e, index) => {
    let items = this.state.items
    items.splice(index, 1)
    this.setState({ items: items })
    this.props.setItems(items)
  }

  onInputChangeTag = e => {
    this.setState({ new_item: e.target.value })
  }

  onKeyPressTag = e => {
    if (e.key === 'Enter') {
      let items = this.state.items
      const new_item = this.state.new_item.trim()
      let isUniq = true
      items.forEach(tag => {
        if (tag.tag === new_item) {
          isUniq = false
        }
      })
      if (new_item !== '' && isUniq === true) {
        items.push({ tag: new_item, bg: Colors.BLACK })
      }
      this.setState({ items: items, new_item: '' })
      this.props.setItems(items)
    }
  }

  onDragStartTag = (e, index) => {
    let items = this.state.items
    this.draggedItem = items[index]
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20)
  }

  onDragOverTag = index => {
    const draggedOverItem = this.state.items[index]
    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return
    }
    // filter out the currently dragged item
    let items = this.state.items.filter(item => item !== this.draggedItem)

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem)
    this.setState({ items: items })
  }

  onDragEnd_Tag = () => {
    this.draggedIdx = null
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  render() {
    return (
      <ul className="formList">
        <li className="formListItem">
          <InputLabel shrink id="label">
            {this.props.label}
          </InputLabel>
          <SortableEditableListWithColor
            items={this.state.items}
            onDragEnd={this.onDragEnd_Tag}
            onDragOver={this.onDragOverTag}
            onDragStart={this.onDragStartTag}
            onChange={this.onInputChangeTag}
            onKeyPress={this.onKeyPressTag}
            onColorChange={this.onColorChangeTag}
            onClose={this.onCloseTag}
            onTagEdite={this.onTagEdite}
            placeholder={this.props.placeholder}
            inputValue={this.state.new_item}
          />
        </li>
      </ul>
    )
  }
}

SortableEditableListWithColorWrapper.defaultProps = {
  bg: '#49486b',
  placeholder: 'Type a tag and press Enter ...',
}

SortableEditableListWithColorWrapper.propTypes = {
  items: PropTypes.any,
  setItems: PropTypes.func,
  label: PropTypes.string,
}

const mapStateToProps = ({}) => ({})

export default connect(
  mapStateToProps,
  actions
)(SortableEditableListWithColorWrapper)
