import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import Plus from '../../assets/svg-icons/plus-white.svg'
import styled from 'styled-components'
import Button from './Button'
import Colors from '../../utils/Colors'
import PropTypes from 'prop-types'

const AddItemStyled = styled(Button)`
  margin: 5px 5px 5px 30px;
  display: flex;
  padding: 0px 20px;
`

const ImageStyled = styled.img`
  width: 30px;
  height: 30px;
`

const AddSubTaskButton = props => {
  const handleClick = () => {
    props.setShowItemModal(true)
    props.setCreatingSubTaskOfType(props.st_type)
  }

  return (
    <AddItemStyled
      type="button"
      className="button"
      bg={props.bg}
      onClick={handleClick}
    >
      {props.text} <ImageStyled src={Plus} />
    </AddItemStyled>
  )
}

const mapStateToProps = ({
  selected_rid,
  visibleItemInput,
  enableAddTasks,
  thisWeek,
  showTasksModal,
  selectedTask,
}) => ({
  selected_rid,
  visibleItemInput,
  enableAddTasks,
  thisWeek,
  showTasksModal,
  selectedTask,
})

Button.defaultProps = {
  bg: Colors.ACTION,
  text: '',
  st_type: null,
}
Button.propTypes = {
  bg: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
}

export default connect(
  mapStateToProps,
  actions
)(AddSubTaskButton)
