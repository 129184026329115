import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import Colors from '../../utils/Colors'
import Keys from '../../utils/Keys'
import './style.css'
import isNil from 'lodash/isNil'
import TextAddButton from '../Elements/Buttons/TextAddButton'

const TableWrapper = styled.div``
class ProductTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fullTable: window.innerWidth >= 400,
      w: window.innerWidth,
    }
  }

  handelRowClick = (row, isSelect, rowIndex, e) => {
    const { products } = this.props
    row['index'] = rowIndex
    this.props.setSelectedSubTask(products)
    this.props.setSelectedRow(row)
    this.props.setEditProduct(true)
    this.props.setSelectedProduct(row)
    this.props.setShowProductModal(true)
  }

  addProduct = () => {
    const { products } = this.props
    const index = this.getItems().length
    let emptyProduct = Keys.EMPTY_PRODUCT
    emptyProduct['index'] = index
    this.props.setSelectedSubTask(products)
    this.props.setEditProduct(false)
    this.props.setSelectedProduct(emptyProduct)
    this.props.setShowProductModal(true)
  }

  _image = cell => {
    if (!isNil(cell)) {
      return <img className="itemImage" src={cell} />
    }
  }

  getItems = () => {
    let items = []
    const { products } = this.props
    const pids = products[Keys.PRODUCT_ID]
    pids.forEach((pid, index) => {
      items.push({
        [Keys.PRODUCT_ID]: pid,
        [Keys.TITLE]: products[Keys.PRODUCT_TITLE][index],
        [Keys.COVER_IMAGE]: products[Keys.COVER_IMAGE][index],
        [Keys.IMAGE]: products[Keys.IMAGE][index],
      })
    })

    return items
  }

  render() {
    const tableColor = {}

    const headerStyle = {
      textAlign: 'center',
      fontWeight: 'bold',
    }
    const columns = [
      {
        dataField: Keys.PRODUCT_ID,
        text: 'Product Id',
        sort: true,
        headerStyle: headerStyle,
      },

      {
        dataField: Keys.TITLE,
        text: 'Product Title',
        sort: true,
        headerStyle: headerStyle,
      },

      {
        dataField: Keys.IMAGE,
        text: 'Image',
        formatter: this._image,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.COVER_IMAGE,
        text: 'Cover Image',
        formatter: this._image,
        headerStyle: headerStyle,
      },
    ]

    const selectRow = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handelRowClick(row, isSelect, rowIndex, e)
      },
    }

    return (
      <TableWrapper className="itemTableWrapper" tableColor={tableColor}>
        <BootstrapTable
          keyField={Keys.PRODUCT_ID}
          data={this.getItems()}
          selectRow={selectRow}
          columns={columns}
          bordered={false}
          rowStyle={{
            backgroundColor: tableColor.color,
          }}
        />

        <div style={{ display: 'flex', alignItems: 'start' }}>
          <TextAddButton
            onClick={this.addProduct}
            color={'green'}
            label={'Add matching Products'}
          />
        </div>
      </TableWrapper>
    )
  }
}

const mapStateToProps = ({
  tasks,
  tasksData,
  subTasks,
  permissions,
  userRidsDetails,
  selected_rid,
  selected_sid,
  selectedTask,
  selectedSubTask,
  thisWeek,
}) => ({
  tasks,
  tasksData,
  subTasks,
  permissions,
  userRidsDetails,
  selected_rid,
  selected_sid,
  selectedTask,
  selectedSubTask,
  thisWeek,
})

export default connect(
  mapStateToProps,
  actions
)(ProductTable)
