import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { UncontrolledCarousel } from 'reactstrap'
import './styles/modalStyle.css'
import { isSolid } from '../../utils/taskHandler'

const ImageCarouselModal = props => {
  const close = () => {
    props.setShowImageCarouselModal(false)
    props.setShowCategoryModal(false)
    props.setEditSubTask(false)
    props.setEditCategory(false)
    props.setShowItemModal(false)
    props.setCarouselModalImages([])
  }

  const convertImages = images => {
    let items = []
    if (isSolid(images)) {
      images.forEach(img => {
        items.push({ src: img, altText: '', caption: '' })
      })
    }
    return items
  }

  return (
    <Modal isOpen={props.showImageCarouselModal} toggle={close}>
      <ModalHeader toggle={close} />
      <ModalBody>
        <UncontrolledCarousel
          items={convertImages(props.carouselModalImages)}
        />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({ showImageCarouselModal, carouselModalImages }) => ({
  showImageCarouselModal,
  carouselModalImages,
})

export default connect(
  mapStateToProps,
  actions
)(ImageCarouselModal)
