import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import Colors from '../../utils/Colors'
import './styles/colorPaletteStyle.css'

const ColorPalette = props => {
  return (
    <div className="paletteWrapper">
      <div className="colorPaletteRow">
        <button
          className="colorButton"
          name={Colors.ColorPalette1}
          style={{ background: Colors.ColorPalette1 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette2}
          style={{ background: Colors.ColorPalette2 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette3}
          style={{ background: Colors.ColorPalette3 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette4}
          style={{ background: Colors.ColorPalette4 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
      </div>
      <div className="colorPaletteRow">
        <button
          className="colorButton"
          name={Colors.ColorPalette5}
          style={{ background: Colors.ColorPalette5 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette6}
          style={{ background: Colors.ColorPalette6 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette7}
          style={{ background: Colors.ColorPalette7 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette8}
          style={{ background: Colors.ColorPalette8 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
      </div>
      <div className="colorPaletteRow">
        <button
          className="colorButton"
          name={Colors.ColorPalette9}
          style={{ background: Colors.ColorPalette9 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette10}
          style={{ background: Colors.ColorPalette10 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette11}
          style={{ background: Colors.ColorPalette11 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
        <button
          className="colorButton"
          name={Colors.ColorPalette12}
          style={{ background: Colors.ColorPalette12 }}
          onClick={e => {
            props.onColorChange(e, props.tagIndex)
            props.AfterColorChange()
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ tasks }) => ({
  tasks,
})

export default connect(
  mapStateToProps,
  actions
)(ColorPalette)
