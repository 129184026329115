import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Button from '../Buttons/Button'
import {
  addNewItemToOrder,
  generateID,
  getRsid,
  isSolid,
  sortTasks,
  validate,
} from '../../../utils/taskHandler'
import {
  date2timestamp,
  epochTime2date,
  getNextWeek,
  getNow,
  timestamp2date,
} from '../../../utils/dateHandler'
import Colors from '../../../utils/Colors'
import '../styles/formStyle.css'
import Radio from '@material-ui/core/Radio'
import Input from '../FormElements/Input'
import Keys from '../../../utils/Keys'
import Switch from '../FormElements/Switch'
import withSubmissionHandler from './withSubmissionHandler'
import { isNil } from 'lodash'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 70%;
`
class TaskExternalLinkForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tid:
        this.props.editTask === true && this.props.selectedTask.tid !== null
          ? this.props.selectedTask.tid
          : generateID(),

      ref_id: !isNil(this.props.selectedTask[Keys.REFERENCE_ID])
        ? this.props.selectedTask[Keys.REFERENCE_ID]
        : generateID(),

      title:
        this.props.editTask === true && this.props.selectedTask.title !== null
          ? this.props.selectedTask.title
          : '',
      page_title:
        this.props.editTask === true &&
        this.props.selectedTask.page_title !== null
          ? this.props.selectedTask.page_title
          : '',
      is_opened:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.IS_OPENED])
          ? this.props.selectedTask[Keys.IS_OPENED]
          : false,
      link:
        this.props.editTask === true && this.props.selectedTask.link !== null
          ? this.props.selectedTask.link
          : '',
      link_type:
        this.props.editTask === true && this.props.selectedTask.link !== null
          ? this.props.selectedTask.link_type
          : 'iframe',
      order:
        this.props.editTask === true && this.props.selectedTask.order !== null
          ? this.props.selectedTask.order
          : this.props.tasks.length,

      task_order_orig:
        this.props.editTask === true && this.props.selectedTask.order !== null
          ? this.props.selectedTask.order
          : this.props.tasks.length,

      created_at_ts:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.CREATED_AT_TS])
          ? this.props.selectedTask[Keys.CREATED_AT_TS]
          : null,

      update_ts:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.UPDATE_TS])
          ? this.props.selectedTask[Keys.UPDATE_TS]
          : null,

      created_by:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.CREATED_BY])
          ? this.props.selectedTask[Keys.CREATED_BY]
          : null,

      updated_by:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.UPDATED_BY])
          ? this.props.selectedTask[Keys.UPDATED_BY]
          : null,

      from_ts:
        this.props.editTask === true && this.props.selectedTask.from_ts !== null
          ? this.props.selectedTask.from_ts
          : getNow('YYYYMMDDHHmmss'),
      to_ts:
        this.props.editTask === true && this.props.selectedTask.to_ts !== null
          ? this.props.selectedTask.to_ts
          : getNextWeek('YYYYMMDDHHmmss'),
      view:
        this.props.editTask === true &&
        this.props.selectedTask.view !== undefined
          ? this.props.selectedTask.view
          : 1,
      active:
        this.props.editTask === true &&
        this.props.selectedTask.active !== undefined
          ? this.props.selectedTask.active
          : true,
    }
  }

  handleChange = event => {
    let val = event.target.value
    if ([Keys.VIEW, Keys.ORDER].includes(event.target.name)) {
      val = Number(event.target.value)
    }
    this.setState({
      [event.target.name]: val,
    })
  }

  finish = (taskId, tasks) => {
    // this.props.addChangedTask(taskId)
    this.props.addNonExpendableTask(taskId)
    this.props.setAppTasks(tasks)
    this.props.setCreatingTaskOfType(null)
    this.props.setShowTasksModal(false)
  }

  handleEditSubmit = () => {
    const form = this.state
    const { selectedTask, tasks } = this.props
    let oldTasks = []
    if (tasks !== null && tasks !== undefined) {
      oldTasks = [...tasks]
    }

    const editableFields = [
      Keys.TITLE,
      Keys.PAGE_TITLE,
      Keys.LINK,
      Keys.LINK_TYPE,
      Keys.ORDER,
      Keys.FROM_TS,
      Keys.TO_TS,
      Keys.VIEW,
      Keys.ACTIVE,
      Keys.REFERENCE_ID,
      Keys.IS_OPENED,
    ]
    let editedTask = null
    let newTasks = oldTasks.map((task, index, array) => {
      if (task[Keys.TASK_ID] === selectedTask.tid) {
        Object.keys(form).forEach((item, index) => {
          if (editableFields.includes(item)) {
            task[item] = form[item]
            if ([Keys.FROM_TS, Keys.TO_TS].includes(item)) {
              task[item] = date2timestamp(form[item])
            }
            if ([Keys.TITLE, Keys.PAGE_TITLE, Keys.LINK].includes(item)) {
              task[item] = form[item].trim()
            }
            if ([Keys.ORDER].includes(item)) {
              task[item] = Number(form[item])
            }
          }
        })
        editedTask = task
      }
      return task
    })
    this.props.submitTask(editedTask)
    newTasks = sortTasks(newTasks)
    this.props.setEditTask(false)
    this.finish(selectedTask[Keys.TASK_ID], newTasks)
  }

  handleNewSubmit = event => {
    let newTasks = []
    const { selected_rid, selectedGroup, tasks } = this.props
    const {
      tid,
      link,
      link_type,
      title,
      order,
      page_title,
      view,
      active,
      from_ts,
      to_ts,
      ref_id,
      is_opened,
    } = this.state
    let rs_id = getRsid(selected_rid, selectedGroup[Keys.OBJECT_ID])
    if (tasks !== null && tasks !== undefined) {
      newTasks = [...tasks]
    }
    const newTask = {
      rs_id: rs_id,
      ref_id: ref_id,
      tid: tid,
      type: 2,
      link: link.trim(),
      link_type: link_type,
      title: title.trim(),
      order: Number(order),
      page_title: page_title.trim(),
      done: false,
      view: view,
      active: active,
      from_ts: from_ts,
      to_ts: to_ts,
      is_opened: is_opened,
    }
    this.props.submitTask(newTask)
    newTasks = addNewItemToOrder(newTasks, order, newTask)
    newTasks = sortTasks(newTasks)

    this.finish(tid, newTasks)
  }

  isValid = form => {
    const toValidate = [Keys.TITLE, Keys.PAGE_TITLE, Keys.LINK]
    try {
      return validate(form, toValidate)
    } catch (e) {
      this.props.handleAlert({
        text: e.text,
        type: 'error',
      })
      return false
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    if (this.isValid(this.state) === true) {
      if (this.props.editTask === true) {
        this.handleEditSubmit(event)
      } else {
        this.handleNewSubmit(event)
      }
    }
  }

  getLinkTypes = () => {
    const linkTypeNames = ['iframe', 'external']
    let linkType = []
    linkTypeNames.forEach((item, index) => {
      linkType.push(
        <div className="menuOptionsSelectElement" key={index}>
          <Radio
            type="radio"
            id={item}
            name={'link_type'}
            color="default"
            value={item}
            checked={this.state.link_type === item}
            onChange={this.handleChange}
          />
          <label className="menuOptionsSelectLabel">{item}</label>
        </div>
      )
    })

    return linkType
  }

  getViewOptions = () => {
    let menuOptions = []
    Object.keys(this.props.taskTypesView).forEach((item, index) => {
      menuOptions.push(
        <div className="menuOptionsSelectElement" key={index}>
          <Radio
            type="radio"
            id={item}
            name={'view'}
            color="default"
            value={this.props.taskTypesView[item]}
            checked={this.state.view === this.props.taskTypesView[item]}
            onChange={this.handleChange}
          />
          <label className="menuOptionsSelectLabel">{item}</label>
        </div>
      )
    })

    return menuOptions
  }

  switchActive = e => {
    this.setState({ active: e.target.checked })
  }

  render() {
    return (
      <form className="itemForm" onSubmit={this.handleSubmit}>
        <ul className="formList">
          <Row>
            <Input
              value={this.state.tid}
              type="text"
              name={Keys.TASK_ID}
              required={true}
              label={'Task ID'}
              readOnly={true}
            />

            <Switch
              value={'active'}
              label={'Active'}
              onChange={this.switchActive}
              checked={this.state.active}
              required={true}
            />
            <Switch
              value={'is_opened'}
              label={'Opened'}
              onChange={this.switchActive}
              checked={this.state.is_opened}
            />
          </Row>
          <Row>
            <Input
              value={this.state.ref_id}
              type="text"
              name={Keys.REFERENCE_ID}
              label={'Reference ID'}
              readOnly={true}
            />
            <Input
              value={this.state.link}
              onChange={this.handleChange}
              type="text"
              name="link"
              required={true}
              label={'Link'}
            />
          </Row>
          <Row>
            <Input
              value={this.state.title}
              onChange={this.handleChange}
              type="text"
              name={Keys.TITLE}
              required={true}
              label={'Title'}
            />

            <Input
              value={this.state.page_title}
              onChange={this.handleChange}
              type="text"
              name={Keys.PAGE_TITLE}
              required={true}
              label={'Page title'}
            />
          </Row>
          <Row>
            <li className="formListItem">
              <InputLabel shrink>View</InputLabel>
              <div className="formRadioSelectWrapper">
                {this.getViewOptions()}
              </div>
            </li>

            <li className="formListItem">
              <InputLabel shrink>Link type</InputLabel>
              <div className="formRadioSelectWrapper">
                {this.getLinkTypes()}
              </div>
            </li>
          </Row>
          <Input
            value={this.state.order}
            onChange={this.handleChange}
            type="number"
            name={Keys.ORDER}
            required={true}
            label={'Order'}
          />
          <Row>
            <Input
              value={timestamp2date(this.state.from_ts)}
              onChange={this.handleChange}
              type="datetime-local"
              name={'from_ts'}
              required={true}
              label={'Start of campaign'}
              step={'1'}
            />
            <Input
              value={timestamp2date(this.state.to_ts)}
              onChange={this.handleChange}
              type="datetime-local"
              name={'to_ts'}
              required={true}
              label={'End of campaign'}
              step={'1'}
            />
          </Row>
          <Row>
            {this.props.editTask && isSolid(this.state.updated_by) ? (
              <Input
                value={this.state.updated_by}
                name={'updated_by'}
                label={'Updated by'}
                readOnly={true}
              />
            ) : null}

            {this.props.editTask && isSolid(this.state.update_ts) ? (
              <Input
                value={epochTime2date(this.state.update_ts)}
                type="datetime-local"
                name={'update_ts'}
                label={'Last update'}
                step={'1'}
                readOnly={true}
              />
            ) : null}
          </Row>
          <Row>
            {this.props.editTask && isSolid(this.state.created_by) ? (
              <Input
                value={this.state.created_by}
                name={'created_by'}
                label={'Created by'}
                readOnly={true}
              />
            ) : null}

            {this.props.editTask && isSolid(this.state.created_at_ts) ? (
              <Input
                value={epochTime2date(this.state.created_at_ts)}
                type="datetime-local"
                name={'created_at_ts'}
                label={'Created at'}
                step={'1'}
                readOnly={true}
              />
            ) : null}
          </Row>
          <li className="buttonDash">
            <Button
              type={'submit'}
              label={
                this.props.editTask === true ? 'Submit Changes' : 'Add new Task'
              }
            />
          </li>
        </ul>
      </form>
    )
  }
}

const mapStateToProps = ({
  tasks,
  tasksData,
  selected_rid,
  selectedGroup,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
  taskTypesView,
}) => ({
  tasks: tasks,
  tasksData,
  selected_rid,
  selectedGroup,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
  taskTypesView,
})

export default withSubmissionHandler(
  connect(mapStateToProps, actions)(TaskExternalLinkForm)
)
