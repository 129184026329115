import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import './sortableEditableStyle.css'
import EditIcon from '../../../assets/svg-icons/pencil.svg'
import CloseIcon from '../../../assets/svg-icons/error.svg'
import Popover from '@material-ui/core/Popover'
import '../styles/formStyle.css'

const ClosePopover = styled.img`
  margin: 3px;
`

const TextArea = styled.textarea`
  width: 100%;
  margin: 5px;
  border: none;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  padding: 5px;
`

class EditableList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editingTagValue: null,
      editingTagIndex: null,
      anchorEl: null,
    }
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleEditKeyPress = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      const newVal = this.state.editingTagValue + '\n'
      this.setState({
        editingTagValue: newVal,
      })
    }
  }

  render() {
    return (
      <div className="App">
        <main>
          <ul className={'suggestionsList'}>
            {this.props.items.map((item, idx) => (
              <li
                className="suggestionsItem"
                key={item.tag}
                // onDragOver={() => this.props.onDragOver(idx)}
              >
                <div
                  className="drag"
                  // draggable
                  // onDragStart={e => this.props.onDragStart(e, idx)}
                  // onDragEnd={this.props.onDragEnd}
                >
                  <span className="content">{item.tag}</span>

                  <img
                    alt={'EditIcon'}
                    className={'tagIconImage'}
                    src={EditIcon}
                    onClick={e =>
                      this.setState({
                        anchorEl: e.currentTarget,
                        editingTagValue: item.tag,
                        editingTagIndex: idx,
                      })
                    }
                    aria-haspopup="true"
                    variant="contained"
                    aria-owns={
                      Boolean(this.state.anchorEl) ? item.tag : undefined
                    }
                  />
                  <img
                    alt={'CloseIcon'}
                    className={'tagIconImage'}
                    src={CloseIcon}
                    onClick={e => this.props.onClose(e, idx)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </main>

        <Popover
          id={this.state.editingTagIndex}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onBackdropClick={this.handleClose}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Wrapper>
            <TextArea
              autoFocus={true}
              value={this.state.editingTagValue}
              onChange={e => {
                this.props.onTagEdite(e, this.state.editingTagIndex)
                this.setState({ editingTagValue: e.target.value })
              }}
              onKeyPress={this.handleEditKeyPress}
            />
            <ClosePopover
              alt={'CloseIcon'}
              className={'tagIconImage'}
              src={CloseIcon}
              onClick={() =>
                this.setState({
                  anchorEl: null,
                  editingTagIndex: null,
                  editingTagValue: null,
                })
              }
            />
          </Wrapper>
        </Popover>

        <input
          className={'formInput'}
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.placeholder}
          value={this.props.inputValue}
          type="text"
        />
      </div>
    )
  }
}

EditableList.defaultProps = {
  bg: '#49486b',
  placeholder: 'Type a tag and press Enter ...',
}

EditableList.propTypes = {
  bg: PropTypes.string,
  items: PropTypes.any,
  onDragOver: PropTypes.any,
  onDragStart: PropTypes.any,
  onDragEnd: PropTypes.any,
  onChange: PropTypes.any,
  onKeyPress: PropTypes.any,
  onFormSubmit: PropTypes.any,
  onClose: PropTypes.any,
  placeholder: PropTypes.any,
  inputValue: PropTypes.any,
}

export default EditableList
