import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import ItemModalFormType1 from './Form/ItemModalFormType1'
import SubTaskForm from './Form/SubTaskForm'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import './styles/modalStyle.css'
import Keys from '../../utils/Keys'

class ItemModalType0 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enableImage: false,
      image: this.props.selectedRow[Keys.IMAGE],
    }
  }

  enableImage = bool => {
    this.setState({ enableImage: bool })
  }

  setImage = image => {
    this.setState({ image: image })
  }

  render() {
    return (
      <React.Fragment>
        <SubTaskForm enableImage={this.enableImage} setImage={this.setImage} />
      </React.Fragment>
    )
  }
}

class ItemModalClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  close = () => {
    this.props.setEditItem(false)
    this.props.setTabNum(0)
    this.props.setEditSubTask(false)
    this.props.setShowItemModal(false)
  }

  open = () => {
    this.props.setShowItemModal(true)
  }

  getItemModal = () => {
    if ([Keys.TASK_TYPE_GENERAL].includes(this.props.selectedTask.type)) {
      return <ItemModalType0 {...this.props} />
    } else if ([Keys.TASK_TYPE_REPORT].includes(this.props.selectedTask.type)) {
      return <ItemModalFormType1 />
    }
  }

  render() {
    return (
      <Modal
        backdrop={false}
        isOpen={this.props.showItemModal && !this.props.showImageCarouselModal}
        toggle={this.close}
        size={'lg'}
      >
        <ModalHeader toggle={this.close} />
        <ModalBody>{this.getItemModal()}</ModalBody>
      </Modal>
    )
  }
}

const mapStateToProps = ({
  selectedTask,
  selectedRow,
  showItemModal,
  showImageCarouselModal,
  itemModalFormType,
  editSubTask,
  editItem,
  tabNum,
}) => ({
  selectedTask,
  selectedRow,
  showItemModal,
  showImageCarouselModal,
  itemModalFormType,
  editSubTask,
  editItem,
  tabNum,
})

export const ItemModal = connect(
  mapStateToProps,
  actions
)(ItemModalClass)
