import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { buildTree, flattenTree } from './utils'
import TreeNode from './TreeNode'
import { useOpenGroups } from './hooks/useOpenGroups'
import { useCheckedGroups } from './hooks/useCheckedGroups'
import { useHasCheckedChildren } from './hooks/useHasCheckedChildren'

const GroupTree = ({
  groups,
  setSelectedGroup,
  setEditGroup,
  setShowGroupModal,
  onSelectedGroupsChange,
  withCheckboxes = false,
}) => {
  const [treeNodes, setTreeNodes] = useState([])

  const { openGroups, toggleGroup } = useOpenGroups()
  const { checkedGroups, handleCheckboxChange } = useCheckedGroups(
    groups,
    openGroups
  )

  const hasCheckedChildren = useHasCheckedChildren(groups, checkedGroups)

  const treeData = useMemo(() => buildTree(groups), [groups])

  useMemo(() => {
    const flattened = flattenTree(treeData, openGroups)
    setTreeNodes(flattened)
  }, [treeData, openGroups])

  const handleSelectGroup = useCallback(
    group => {
      if (!withCheckboxes) {
        setSelectedGroup({ ...groups[group.oid] })
        setEditGroup(true)
        setShowGroupModal(true)
      }
    },
    [withCheckboxes, groups, setSelectedGroup, setEditGroup, setShowGroupModal]
  )

  useEffect(() => {
    if (withCheckboxes && onSelectedGroupsChange) {
      const selectedGroupIds = Object.keys(checkedGroups)
      onSelectedGroupsChange(selectedGroupIds)
    }
  }, [checkedGroups, withCheckboxes, onSelectedGroupsChange])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {treeNodes.map(group => {
        const hasCheckedChild = hasCheckedChildren(group)
        return (
          <TreeNode
            key={group.oid}
            group={group}
            isOpen={!!openGroups[group.oid]}
            onToggle={toggleGroup}
            onSelect={handleSelectGroup}
            withCheckboxes={withCheckboxes}
            onCheckboxChange={handleCheckboxChange}
            checked={!!checkedGroups[group.oid]}
            hasChildrenChecked={hasCheckedChild}
          />
        )
      })}
    </div>
  )
}

export default GroupTree
