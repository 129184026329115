import React, { Component } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from 'react-bootstrap-table-next'
import withTasksTable from './withTasksTable'
import '../style.css'
import Keys from '../../../utils/Keys'
import TaskTableExpandComponent from '../../Elements/TaskTableExpandComponent'
import ExpendedIcon from '../../../assets/svg-icons/chevron-up.svg'
import UnExpendedIcon from '../../../assets/svg-icons/chevron-down.svg'
const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
}
class ReportTasksTable extends React.Component {
  constructor(props) {
    super(props)
  }
  getData = () => {
    if (this.props.reportTasks === null) {
      return []
    } else {
      return this.props.reportTasks
    }
  }

  render() {
    const expandRow = {
      renderer: row => <TaskTableExpandComponent row={row} />,
      onExpand: (row, isExpand, rowIndex, e) => {
        this.props.handelRowClick(row)
        this.props.setExpendedCategory([])
      },

      expandColumnRenderer: ({ expanded, rowKey, expandable }) =>
        this.props._getExpandColumn(expanded, rowKey, expandable),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => <div />,
      showExpandColumn: true,
      onlyOneExpanding: true,
      expandByColumnOnly: true,
      width: 70,
    }

    const rowClasses = (row, rowIndex) => {
      return 'reportTableRow'
    }

    const columns = [
      {
        dataField: Keys.TASK_ID,
        text: 'Task Id',
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.PAGE_TITLE,
        text: 'Page title',
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.ORDER,
        text: 'Order',
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.FROM_TS,
        text: 'Start of Campaign',
        formatter: this.props._timestamp,
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.TO_TS,
        text: 'End of Campaign',
        formatter: this.props._timestamp,
        sort: true,
        headerStyle: headerStyle,
      },
      {
        dataField: Keys.ACTIVE,
        text: 'Activation',
        formatter: this.props._active,
        headerStyle: headerStyle,
      },
      {
        dataField: 'button',
        text: 'Action',
        formatter: this.props.tableActions,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            this.props.setShowTasksModal(false)
          },
        },
        headerStyle: headerStyle,
      },
    ]

    return (
      <div className={'tableWrapper'}>
        {this.props.reportTasks === null ||
        this.props.reportTasks.length === 0 ? (
          <div> No Tasks to Show </div>
        ) : (
          <BootstrapTable
            keyField="tid"
            data={this.getData()}
            expandRow={expandRow}
            selectRow={this.props.selectRow}
            columns={columns}
            bordered={false}
            rowClasses={rowClasses}
          />
        )}
      </div>
    )
  }
}
export default withTasksTable(ReportTasksTable)
