import React from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import Scrapper from '../Scrapper'
import Colors from '../../../utils/Colors'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Wrapper = styled.div`
  margin: 10px 0 30px 0;
`

const ImageTextArea = props => {
  return (
    <Wrapper>
      <InputLabel shrink>
        {props.label}
        {props.required && <span style={{ color: Colors.DANGER }}> *</span>}
      </InputLabel>

      <div className="formRow">
        <textarea
          className="formTextArea"
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          required={props.required}
        />
        <ButtonWrapper>
          {props.showScrapper ? (
            <Scrapper
              name={props.name}
              itemId={props.itemId}
              _callback={props.setImage}
            />
          ) : null}
          {props.children}
        </ButtonWrapper>
      </div>
    </Wrapper>
  )
}

ImageTextArea.defaultProps = {
  required: false,
  readOnly: false,
  value: '',
  label: 'Image URL',
  name: 'image',
  showScrapper: true,
}

ImageTextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  itemId: PropTypes.string,
  onChange: PropTypes.func,
}

export default ImageTextArea
