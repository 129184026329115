import React, { useState } from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import Button from './Button'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Copy from '../../assets/svg-icons/copy.svg'
import { Select } from 'rebass'
import isNil from 'lodash/isNil'

import { copyTask } from '../../services/api'
import Keys from '../../utils/Keys'

import Colors from '../../utils/Colors'
import './styles/modalStyle.css'

const CopyTaskModal = props => {
  const [sids, setSids] = useState([])
  const close = () => {
    props.setEditTask(false)
    props.setShowTasksModal(false)
    props.setShowCopyTaskModal(false)
  }

  const printStoresOptions = () => {
    const storeMap = props.groups

    if (!isNil(storeMap)) {
      return Object.keys(storeMap).map((item, i) => {
        return (
          <option key={i} value={item}>
            {storeMap[item][Keys.NAME]}
          </option>
        )
      })
    }
  }

  const handleResponse = response => {
    let text = 'Task was copied'
    let type = Keys.ALERT_SUCCESS
    if (response.status !== 200) {
      text = 'something went wrong with the copy, call the Dr.Admin'
      type = Keys.ALERT_ERROR
    }
    props.handleAlert({
      text: text,
      type: type,
    })
  }

  const finishSubmitProcess = () => {
    props.hideFullScreenLoader()
    close()
  }

  const handleStoresSelect = event => {
    let storeIds = []
    let storesOptions = event.target.options
    Array.prototype.forEach.call(storesOptions, option => {
      if (option.selected) {
        let group_id = option.value
        storeIds.push(group_id)
      }
    })

    setSids(storeIds)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const { selectedTask, selected_rid, selectedGroup } = props

    const tid = selectedTask[Keys.TASK_ID]
    if (!isNil(selectedTask[Keys.CREATED_BY])) {
      if (sids.length === 0) {
        props.handleAlert({
          text: 'Choose target store first',
          type: Keys.ALERT_WARNING,
        })
      } else {
        props.showFullScreenLoader()
        const body = {
          [Keys.TASK_ID]: tid,
          [Keys.GROUP]: selectedGroup[Keys.OBJECT_ID],
          [Keys.RID]: selected_rid,
          [Keys.GROUPS]: sids,
        }
        copyTask(handleResponse, body, finishSubmitProcess)
      }
    } else {
      alert(
        'היי! אני מצטער אבל כרגע אי אפשר להעתיק את המשימה :( \n' +
          ' אבל אל דאגה יש מספר צעדים פשוטים בשביל לגרום לזה לקרות: \n' +
          '1) תלחצו OK בחלון הזה \n' +
          '2) כנסו למשימה ותשמרו אותה (אין צורך בלעשות שינויים)\n' +
          ' 3) לחצו על כפתור FETCH TASKS \n' +
          '4) נסו להעתיק שוב'
      )
      finishSubmitProcess()
    }
  }

  return (
    <Modal isOpen={props.showCopyTaskModal} toggle={close}>
      <ModalHeader toggle={close}>
        <img className="modalHeaderImage" src={Copy} /> Copy Task
      </ModalHeader>
      <ModalBody>
        <p>
          keep in mind: you are copying the task titled:{' '}
          {props.selectedTask.title} to the chosen store, it will be shown in
          current week task list.
        </p>
        <Select
          label="Stores"
          name="stores"
          value={sids}
          onChange={handleStoresSelect}
          multiple={true}
          required={true}
        >
          {printStoresOptions()}
        </Select>
        <div className="modalButtonDash">
          <Button onClick={handleSubmit} bg={Colors.ACTION}>
            Copy
          </Button>
          <Button onClick={close} bg={Colors.DANGER}>
            {' '}
            Cancel{' '}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({
  selected_rid,
  showCopyTaskModal,
  selectedTask,
  selectedGroup,
  groups,
}) => ({
  selected_rid,
  showCopyTaskModal,
  selectedTask,
  selectedGroup,
  groups,
})

export default connect(
  mapStateToProps,
  actions
)(CopyTaskModal)
