import React from 'react'
import Button from '../Buttons/Button'
import {
  generateID,
  addNewItemToOrder,
  validate,
  sortTasks,
  getRsid,
  isSolid,
  setTaskToLocalStorage,
} from '../../../utils/taskHandler'
import {
  date2timestamp,
  timestamp2date,
  epochTime2date,
  getNow,
  timestamp2dateWithoutSec,
  getDiffInHours,
} from '../../../utils/dateHandler'
import Keys from '../../../utils/Keys'
import '../styles/formStyle.css'
import Input from '../FormElements/Input'
import Switch from '../FormElements/Switch'
import withSubmissionHandler from './withSubmissionHandler'
import Select from '../FormElements/Select'
import styled from 'styled-components'
import { isNil } from 'lodash'
import moment from 'moment'
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 70%;
`
class TaskGeneralForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tid:
        this.props.editTask && this.props.selectedTask[Keys.TASK_ID] !== null
          ? this.props.selectedTask[Keys.TASK_ID]
          : generateID(),
      ref_id: !isNil(this.props.selectedTask[Keys.REFERENCE_ID])
        ? this.props.selectedTask[Keys.REFERENCE_ID]
        : generateID(),
      title:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.TITLE])
          ? this.props.selectedTask[Keys.TITLE]
          : '',
      order:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.ORDER])
          ? this.props.selectedTask[Keys.ORDER]
          : this.props.tasks.length,

      task_order_orig:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.ORDER])
          ? this.props.selectedTask[Keys.ORDER]
          : this.props.tasks.length,

      created_at_ts:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.CREATED_AT_TS])
          ? this.props.selectedTask[Keys.CREATED_AT_TS]
          : null,

      update_ts:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.UPDATE_TS])
          ? this.props.selectedTask[Keys.UPDATE_TS]
          : null,

      created_by:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.CREATED_BY])
          ? this.props.selectedTask[Keys.CREATED_BY]
          : null,

      updated_by:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.UPDATED_BY])
          ? this.props.selectedTask[Keys.UPDATED_BY]
          : null,

      from_ts:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.FROM_TS])
          ? this.props.selectedTask[Keys.FROM_TS]
          : getNow('YYYYMMDDHHmmss'),
      to_ts:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.TO_TS])
          ? this.props.selectedTask[Keys.TO_TS]
          : '',
      view:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.VIEW])
          ? this.props.selectedTask[Keys.VIEW]
          : 0,
      active:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.ACTIVE])
          ? this.props.selectedTask[Keys.ACTIVE]
          : true,
      roll:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.ROLL])
          ? this.props.selectedTask[Keys.ROLL]
          : false,
      is_opened:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.IS_OPENED])
          ? this.props.selectedTask[Keys.IS_OPENED]
          : false,
      is_st_product_camera_enabled:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.IS_SUB_TASK_PRODUCT_CAMERA_ENABLED])
          ? this.props.selectedTask[Keys.IS_SUB_TASK_PRODUCT_CAMERA_ENABLED]
          : true,
      send_schedule_push:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.SEND_SCHEDULE_PUSH])
          ? this.props.selectedTask[Keys.SEND_SCHEDULE_PUSH]
          : true,
      send_campaign_start_push:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.SEND_IMMEDIATE_PUSH])
          ? this.props.selectedTask[Keys.SEND_IMMEDIATE_PUSH]
          : true,
      deadline:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.DEADLINE])
          ? this.props.selectedTask[Keys.DEADLINE]
          : '',
      is_st_product_numbered:
        this.props.editTask &&
        !isNil(this.props.selectedTask[Keys.SUB_TASK_PRODUCT_NUMBERED])
          ? this.props.selectedTask[Keys.SUB_TASK_PRODUCT_NUMBERED]
          : false,
      tag:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.TAG])
          ? this.props.selectedTask[Keys.TAG]
          : '',
      isSendSubTasks:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.TO_TS])
          ? this.props.selectedTask[Keys.TO_TS] <= getNow('YYYYMMDDHHmmss')
          : false,
      taskTypeView:
        this.props.editTask && !isNil(this.props.selectedTask[Keys.VIEW])
          ? this.props.selectedTask[Keys.VIEW]
          : '',
    }
  }
  foo = (to_ts, deadline, val) => {
    return deadline &&
      to_ts &&
      getDiffInHours(deadline, to_ts) !== undefined &&
      getDiffInHours(deadline, to_ts) < 168 === false
      ? to_ts
      : moment(val)
          .add(7, 'days')
          .format('YYYY-MM-DDTHH:mm')
  }

  handleChange = event => {
    const { to_ts, deadline } = this.state
    var val = event.target.value
    if (['view'].includes(event.target.name)) {
      val = Number(event.target.value)
    }
    if (event.target.name === 'deadline') {
      this.setState({
        deadline: val,

        to_ts: this.foo(to_ts, deadline, val),
      })
    }

    this.setState({
      [event.target.name]: val,
    })
  }
  handleSelectView = event => {
    let selectedView = ''
    let options = event.target.options

    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedView = options[i].value
        console.log(selectedView)
      }
    }
    this.setState({ taskTypeView: selectedView })
  }

  handleSelect = event => {
    let selectedTag = ''
    let options = event.target.options

    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedTag = options[i].value
      }
    }
    this.setState({ tag: selectedTag })
  }
  handleSelectView = event => {
    let selectedTag = ''
    let options = event.target.options

    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedTag = options[i].value
      }
    }
    this.setState({ taskTypeView: selectedTag })
  }

  handleEditSubmit = () => {
    const form = this.state
    const { isSendSubTasks } = this.state
    const { tasks, selectedTask } = this.props
    let oldTasks = []
    if (tasks !== null && tasks !== undefined) {
      oldTasks = [...tasks]
    }

    const editableFields = [
      Keys.TITLE,
      Keys.ORDER,
      Keys.FROM_TS,
      Keys.TO_TS,
      Keys.DEADLINE,
      Keys.VIEW,
      Keys.ACTIVE,
      Keys.TAG,
      Keys.ROLL,
      Keys.REFERENCE_ID,
      Keys.IS_SUB_TASK_PRODUCT_CAMERA_ENABLED,
      Keys.SEND_IMMEDIATE_PUSH,
      Keys.SEND_SCHEDULE_PUSH,
      Keys.IS_SUB_TASK_PRODUCT_NUMBERED,
      Keys.IS_OPENED,
    ]

    let editedTask = null

    let newTasks = oldTasks.map(task => {
      if (task[Keys.TASK_ID] === selectedTask[Keys.TASK_ID]) {
        Object.keys(form).forEach(item => {
          if (editableFields.includes(item)) {
            task[item] = form[item]
            if ([Keys.TITLE].includes(item)) {
              task[item] = form[item].trim()
            }
            if ([Keys.FROM_TS, Keys.TO_TS, Keys.DEADLINE].includes(item)) {
              task[item] = date2timestamp(form[item])
            }
            if ([Keys.ORDER].includes(item)) {
              task[item] = Number(form[item])
            }
            if ([Keys.VIEW].includes(item)) {
              task[item] = Number(this.state.taskTypeView)
            }
            if ([Keys.TAG].includes(item) && form[item] === '') {
              delete task[item]
            }
          }
        })
        editedTask = task
      }
      if (
        this.props.editTask &&
        !this.props.selectedTask.isNewTask &&
        !this.props.selectedTask.cloned
      ) {
        task.is_edit_mode = true
        task.deadline =
          task.deadline === '' || task.deadline === 'Invalid date'
            ? date2timestamp(this.state.to_ts)
            : task.deadline
      }
      return task
    })
    newTasks = sortTasks(newTasks)
    const { selected_rid, selectedGroup } = this.props
    const prefix = editedTask.cloned ? Keys.CLONED_TASK_LS_PREFIX : null
    setTaskToLocalStorage(
      selected_rid,
      selectedGroup.name,
      { task: editedTask },
      prefix
    )
  }

  handleNewSubmit = () => {
    let newTasks = []
    let tasksData = {}
    const { tasks, selected_rid, selectedGroup } = this.props
    const {
      tid,
      ref_id,
      from_ts,
      to_ts,
      title,
      order,
      taskTypeView,
      active,
      tag,
      roll,
      is_st_product_camera_enabled,
      is_st_product_numbered,
      is_opened,
      send_campaign_start_push,
      send_schedule_push,
      deadline,
    } = this.state
    if (tasks !== null && tasks !== undefined) {
      newTasks = [...tasks]
    }

    if (this.props.tasksData !== null && this.props.tasksData !== undefined) {
      tasksData = JSON.parse(JSON.stringify(this.props.tasksData))
    }
    const rs_id = getRsid(selected_rid, selectedGroup[Keys.OBJECT_ID])
    const taskId = tid
    let tasksOid = generateID()

    tasksData[tasksOid] = {
      oid: tasksOid,
      data: {},
      rs_id: rs_id,
    }

    let newTask = {
      rs_id: rs_id,
      tid: taskId,
      data_oid: tasksOid,
      from_ts: date2timestamp(from_ts),
      to_ts: date2timestamp(to_ts),
      title: title.trim(),
      type: 0,
      order: Number(order),
      categories_order: [],
      categories: {},
      done: false,
      view: Number(taskTypeView),
      active: active,
      ref_id: ref_id,
      roll: roll,
      is_opened: is_opened,
      is_st_product_numbered: is_st_product_numbered,
      is_st_product_camera_enabled: is_st_product_camera_enabled,
      send_campaign_start_push: send_campaign_start_push,
      send_schedule_push: send_schedule_push,
      deadline: deadline !== '' ? date2timestamp(deadline) : '',
      utc_offset: Number(moment().utcOffset() / 60),
      isNewTask: true,
    }

    if (tag !== '') {
      newTask[Keys.TAG] = tag
    }
    if (newTask.deadline === '') {
      delete newTask.deadline
    }
    newTasks = addNewItemToOrder(newTasks, this.state.order, newTask)
    newTasks = sortTasks(newTasks, this.props.addChangedTask)
    this.props.setTasksAndTasksData([newTask, ...this.props.tasks], tasksData)
    this.props.setShowTasksModal(false)
  }
  printViewOptions = () => {
    let viewOptions = [
      <option key={-1} value={''}>
        No View
      </option>,
    ]
    const taskViewsObject = this.props.taskTypesView
    for (let key in taskViewsObject) {
      let item = taskViewsObject[key]
      viewOptions.push(
        <option key={key} value={item}>
          {key}
        </option>
      )
    }
    return viewOptions
  }

  printTagOptions = () => {
    let tagOptions = [
      <option key={-1} value={''}>
        No Tag
      </option>,
    ]
    this.props.taskTags.forEach((tagObj, index) => {
      tagOptions.push(
        <option key={index} value={tagObj.key}>
          {tagObj.title}
        </option>
      )
    })

    return tagOptions
  }

  isValid = form => {
    const toValidate = [Keys.TITLE]
    try {
      return validate(form, toValidate)
    } catch (e) {
      this.props.handleAlert({
        text: e.text,
        type: Keys.ALERT_ERROR,
      })
      return false
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    if (this.isValid(this.state) === true) {
      if (this.props.editTask === true) {
        this.handleEditSubmit()
      } else {
        this.handleNewSubmit()
      }
    }
    this.props.setShowTasksModal(false)
  }

  switchActive = e => {
    this.setState({ [e.target.value]: e.target.checked })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="itemForm">
        <ul className="formList">
          <Row>
            <Input
              value={this.state.tid}
              type="text"
              name={Keys.TASK_ID}
              required={true}
              label={'Task ID'}
              readOnly={true}
            />
            <Input
              value={this.state.ref_id}
              type="text"
              name={Keys.REFERENCE_ID}
              label={'Reference ID'}
              readOnly={true}
            />
          </Row>
          <Row>
            <Switch
              value={'is_st_product_camera_enabled'}
              label={'Is Product Camera Enabled'}
              onChange={this.switchActive}
              checked={this.state.is_st_product_camera_enabled}
              required={true}
            />
            <Switch
              value={'is_st_product_numbered'}
              label={'Is Product Numbered'}
              onChange={this.switchActive}
              checked={this.state.is_st_product_numbered}
              required={true}
            />
            <Switch
              value={'active'}
              label={'Active    '}
              onChange={this.switchActive}
              checked={this.state.active}
              required={true}
            />
            <Switch
              value={'roll'}
              label={'Roll'}
              onChange={this.switchActive}
              checked={this.state.roll}
            />
            <Switch
              value={'is_opened'}
              label={'Opened'}
              onChange={this.switchActive}
              checked={this.state.is_opened}
            />
          </Row>
          <Row>
            <Select
              label={'View'}
              name={Keys.VIEW}
              required={true}
              value={this.state.taskTypeView}
              onChange={this.handleSelectView}
            >
              {this.printViewOptions()}
            </Select>
            <Select
              label={'Tag'}
              name={Keys.TAG}
              value={this.state.tag}
              onChange={this.handleSelect}
            >
              {this.printTagOptions()}
            </Select>
          </Row>
          <Row>
            <Input
              value={this.state.title}
              onChange={this.handleChange}
              type="text"
              name={Keys.TITLE}
              required={true}
              label={'Title'}
              autoFocus={true}
            />
            <Input
              value={this.state.order}
              onChange={this.handleChange}
              type="number"
              name={Keys.ORDER}
              required={true}
              label={'Order'}
            />
          </Row>
          <Row>
            <Input
              value={timestamp2dateWithoutSec(this.state.from_ts)}
              onChange={this.handleChange}
              type="datetime-local"
              name={'from_ts'}
              required={true}
              label={'Start of campaign'}
              step={'1'}
            />
            <Input
              value={timestamp2date(this.state.deadline)}
              onChange={this.handleChange}
              type={'datetime-local'}
              name={'deadline'}
              required={true}
              min={timestamp2dateWithoutSec(this.state.from_ts)}
              label={'End of campaign'}
              step={'1'}
              readOnly={
                this.props.editTask &&
                !this.props.selectedTask.isNewTask &&
                !this.props.selectedTask.cloned
                  ? true
                  : false
              }
            />
          </Row>
          <Row>
            <Input
              value={timestamp2date(this.state.to_ts)}
              onChange={this.handleChange}
              type="datetime-local"
              name={'to_ts'}
              min={timestamp2date(this.state.deadline)}
              required={true}
              label={'Removal date of campaign'}
              step={'1'}
            />
          </Row>
          <Row>
            <>
              {this.props.editTask &&
              !this.props.selectedTask.isNewTask &&
              !this.props.selectedTask.cloned ? (
                <>
                  <Switch
                    value={'send_schedule_push'}
                    label={'Is send schedule push'}
                    checked={this.state.send_schedule_push}
                    required={true}
                  />
                  <Switch
                    value={'send_campaign_start_push'}
                    label={'Is send immediate push'}
                    checked={this.state.send_campaign_start_push}
                    required={true}
                  />
                </>
              ) : (
                <>
                  <Switch
                    value={'send_schedule_push'}
                    label={'Is send schedule push'}
                    onChange={this.switchActive}
                    checked={this.state.send_schedule_push}
                    required={true}
                  />
                  <Switch
                    value={'send_campaign_start_push'}
                    label={'Is send immediate push'}
                    onChange={this.switchActive}
                    checked={this.state.send_campaign_start_push}
                    required={true}
                  />
                </>
              )}
            </>
          </Row>
          <Row>
            {this.props.editTask && isSolid(this.state.updated_by) ? (
              <Input
                value={this.state.updated_by}
                name={'updated_by'}
                label={'Updated by'}
                readOnly={true}
              />
            ) : null}

            {this.props.editTask && isSolid(this.state.update_ts) ? (
              <Input
                value={epochTime2date(this.state.update_ts)}
                type="datetime-local"
                name={'update_ts'}
                label={'Last update'}
                step={'1'}
                readOnly={true}
              />
            ) : null}
          </Row>
          <Row>
            {this.props.editTask && isSolid(this.state.created_by) ? (
              <Input
                value={this.state.created_by}
                name={'created_by'}
                label={'Created by'}
                readOnly={true}
              />
            ) : null}

            {this.props.editTask && isSolid(this.state.created_at_ts) ? (
              <Input
                value={epochTime2date(this.state.created_at_ts)}
                type="datetime-local"
                name={'created_at_ts'}
                label={'Created at'}
                step={'1'}
                readOnly={true}
              />
            ) : null}
          </Row>

          <li className="formButtonDash">
            <Button
              type={'submit'}
              label={this.props.editTask ? 'Submit Changes' : 'Add new Task'}
            />
          </li>
        </ul>
      </form>
    )
  }
}

export default withSubmissionHandler(TaskGeneralForm)
