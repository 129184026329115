import { Divider } from '@material-ui/core'
import React from 'react'

export const GroupCheckbox = ({
  value,
  onChange,
  isChecked,
  title,
  children,
  marginBottom = '10px',
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: marginBottom,
      }}
    >
      <input
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={isChecked}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {title && <div className="groupName">{title}</div>}
        {children}
      </div>
    </div>
  )
}
