import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import './styles/modalStyle.css'
import ProductForm from './Form/ProductForm'

const ProductModal = props => {
  const close = () => {
    props.setShowProductModal(false)
    props.setSelectedProduct({})
  }

  return (
    <Modal backdrop={false} isOpen={props.showProductModal} toggle={close}>
      <ModalHeader toggle={close} />
      <ModalBody>
        <ProductForm />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({ selectedTask, selectedRow, showProductModal }) => ({
  selectedTask,
  selectedRow,
  showProductModal,
})

export default connect(
  mapStateToProps,
  actions
)(ProductModal)
