import React from 'react'
import '../styles/formStyle.css'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import {
  convertUnderscoreNameToLabel,
  emptyStrToNull,
} from '../../../utils/taskHandler'
import Input from '../FormElements/Input'
import Keys from '../../../utils/Keys'
import ImageTextArea from '../FormElements/ImageTextArea'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import Button from '../Buttons/Button'
import Colors from '../../../utils/Colors'
import { setImage } from '../../../services/api'
import UploadFile from '../UploadFile'
import FileService from '../../../services/fileServices'
import withSubmissionHandler from './withSubmissionHandler'
import InputLabel from '@material-ui/core/InputLabel'

class ProductForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pid: this.props.selectedProduct[Keys.PRODUCT_ID],
      image: !isNil(this.props.selectedProduct[Keys.IMAGE])
        ? this.props.selectedProduct[Keys.IMAGE]
        : '',
      cover_img: this.props.selectedProduct[Keys.COVER_IMAGE],
      title: this.props.selectedProduct[Keys.TITLE],
      index: this.props.selectedProduct['index'],
      image_orig: this.props.selectedProduct[Keys.IMAGE],
    }
  }

  _image = img => {
    const _img = this.state[img]
    if (!isNil(_img) && _img !== '') {
      return (
        <li className="formListItem">
          <InputLabel shrink id="label">
            {convertUnderscoreNameToLabel(img)}
          </InputLabel>

          <img
            src={_img}
            alt={img}
            style={{ width: '200px', display: 'flex', margin: 'auto' }}
          />
        </li>
      )
    }
  }

  setImage = image => {
    this.setState({
      image: image,
    })
  }

  handleImageUpload = async (e, imageName) => {
    this.props.showFullScreenLoader()
    const { selected_rid } = this.props
    const file = e.target.files[0]
    const res = await FileService.uploadFile(file, selected_rid)
    this.setState({ [imageName]: res })
    this.props.hideFullScreenLoader()
  }

  setProductImageInDB = () => {
    const { image, image_orig, pid } = this.state

    if (
      Keys.NO_IMAGE_FLAG.includes(image_orig) &&
      Keys.NO_IMAGE_FLAG.includes(image) === false &&
      !isNil(image) &&
      image !== ''
    ) {
      const _image = { image: image }
      const retail_name = this.props.selected_rid
      setImage({
        image: _image,
        platform: 'web',
        retail_name: retail_name,
        model_id: pid.trim(),
      })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    const { pid, index, cover_img, title, image } = this.state
    const _cover_img = emptyStrToNull(cover_img)
    let tasksData = { ...this.props.tasksData }
    let subTasks = { ...this.props.subTasks }
    let { selectedTask, selectedSubTask, editProduct, tasks } = this.props
    const tid = selectedTask[Keys.TASK_ID]
    const taskDataId = selectedTask[Keys.DATA_OID]
    const stId = selectedSubTask[Keys.ST_ID]
    const tasksList = tasks.map(task => {
      if (task[Keys.TASK_ID] === tid) {
        return { ...task, isEdit: true }
      }
      return task
    })

    if (!isNil(get(tasksData, `${taskDataId}.${[Keys.DATA]}`, null))) {
      tasksData[taskDataId][Keys.DATA][pid.trim()] = {
        [Keys.TITLE]: title.trim(),
        [Keys.IMAGE]: emptyStrToNull(image),
      }
    } else {
      alert('task data object missing')
    }

    if (!isNil(get(subTasks, stId, null))) {
      if (editProduct) {
        subTasks[stId][Keys.PRODUCT_ID][index] = pid.trim()
        subTasks[stId][Keys.COVER_IMAGE][index] = _cover_img
      } else {
        subTasks[stId][Keys.PRODUCT_ID].push(pid.trim())
        if (subTasks[stId][Keys.PRODUCT_ID].length > 1) {
          subTasks[stId][Keys.COVER_IMAGE].push(_cover_img)
        }
      }
    } else {
      alert('subTask missing')
    }

    if (this.props.editProduct) {
      this.setProductImageInDB()
    }
    this.props.finishProductSubmit(subTasks, tasksData)
    this.props.setAppTasks(tasksList)
  }

  handleDelete = event => {
    event.preventDefault()
    const { pid, index } = this.state
    let subTasks = { ...this.props.subTasks }
    let tasksData = { ...this.props.tasksData }
    let { selectedTask, selectedSubTask, tasks } = this.props
    const tid = selectedTask[Keys.TASK_ID]
    const stId = selectedSubTask[Keys.ST_ID]

    let currentTask = null
    tasks.forEach(task => {
      if (task[Keys.TASK_ID] === tid) {
        currentTask = task
      }
    })

    if (
      !isNil(get(subTasks, stId, null)) &&
      !isNil(get(subTasks, `${stId}.${Keys.PRODUCT_ID}`, null)) &&
      subTasks[stId][Keys.PRODUCT_ID].length > 1
    ) {
      subTasks[stId][Keys.PRODUCT_ID].splice(index, 1)
      subTasks[stId][Keys.COVER_IMAGE].splice(index, 1)
      // this.props.addChangedTask(tid)
      this.props.submitSubTask(currentTask, null, subTasks[stId])
    } else {
      this.props.handleAlert({
        text:
          'This is the last product in the product type sub task,\n it can not be deleted... sorry :(',
        type: 'warning',
      })
    }
    this.props.finishProductSubmit(subTasks, tasksData)
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render() {
    return (
      <form className="itemForm" onSubmit={this.handleSubmit}>
        <ul className="formList">
          {this._image(Keys.IMAGE)}

          <Input
            value={this.state.pid}
            onChange={this.handleChange}
            type="text"
            name={Keys.PID}
            required={true}
            label={'Product ID'}
          />
          <Input
            value={this.state.title}
            onChange={this.handleChange}
            type="text"
            name={Keys.TITLE}
            required={true}
            label={'Product title'}
          />

          <ImageTextArea
            name={Keys.IMAGE}
            value={this.state.image}
            itemId={this.state.pid}
            setImage={this.setImage}
            onChange={this.handleChange}
          >
            <UploadFile
              setShowValidationPassword={this.props.setShowValidationPassword}
              onChange={e => this.handleImageUpload(e, Keys.IMAGE)}
            />
          </ImageTextArea>

          <Input
            value={this.state.cover_img}
            onChange={this.handleChange}
            type="text"
            name={Keys.COVER_IMAGE}
            label={'Cover Image'}
          >
            <UploadFile
              onChange={e => this.handleImageUpload(e, Keys.COVER_IMAGE)}
            />
          </Input>
          {this._image(Keys.COVER_IMAGE)}

          {this.props.editProduct === true ? (
            <li className="formButtonDash">
              <Button type={'submit'} label={'Save Item Changes'} />
              <Button
                onClick={this.handleDelete}
                color={'secondary'}
                label={'Delete Item'}
              />
            </li>
          ) : (
            <li className="formButtonDash">
              <Button type={'submit'} label={'Add Item'} />
            </li>
          )}
        </ul>
      </form>
    )
  }
}

export default withSubmissionHandler(ProductForm)
