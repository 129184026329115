import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from '../Button'
import '../styles/reactTagsStyle.css'
import Colors from '../../../utils/Colors'
import Keys from '../../../utils/Keys'
import '../styles/formStyle.css'
import Input from '../FormElements/Input'
import isNil from 'lodash/isNil'
import withSubmissionHandler from './withSubmissionHandler'
class ItemModalFormType1 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      row: this.getRow(),
      rowIndex: this.props.selectedRow.index,
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state.row !== nextState.row
  }

  getRow = () => {
    if (this.props.editItem === true) {
      return this.props.selectedRow.data
    } else {
      if (!isNil(this.props.selectedTask[Keys.TITLES])) {
        const newEditableRow = new Array(
          this.props.selectedTask[Keys.TITLES].length
        ).fill(null)
        return newEditableRow
      }
    }
  }

  handleChange = event => {
    let newRow = [...this.state.row]
    const ind = this.getColumns().indexOf(event.target.name)
    let val = event.target.value
    if (val.trim() === '') {
      val = null
    }
    newRow[ind] = val
    this.setState({ row: newRow })
  }

  finish = () => {
    // this.props.addChangedTask(this.props.selectedTask[Keys.TASK_ID])
    this.props.setEditItem(false)
    this.props.setShowItemModal(false)
    this.props.setCreatingSubTaskOfType(null)
  }

  handleEditSubmit = event => {
    let newTasks = [...this.props.reportTasks]
    const row = [...this.state.row]
    let editedTask = null
    newTasks.forEach(task => {
      if (task[Keys.TASK_ID] === this.props.selectedTask.tid) {
        task[Keys.DATA][this.state.rowIndex] = row
        editedTask = task
      }
    })
    this.props.submitTask(editedTask)
    this.props.setReportTasks(newTasks)
  }

  handleDelete = event => {
    event.preventDefault()
    let newTasks = [...this.props.reportTasks]
    let editedTask = null
    newTasks.forEach(task => {
      if (task[Keys.TASK_ID] === this.props.selectedTask[Keys.TASK_ID]) {
        let taskData = task[Keys.DATA]
        taskData.splice(this.state.rowIndex, 1)
        task.data = taskData
        editedTask = task
      }
    })
    this.props.submitTask(editedTask)
    this.props.setReportTasks(newTasks)
    this.finish()
  }

  handleNewSubmit = event => {
    let row = this.state.row
    let newTasks = [...this.props.reportTasks]
    let selectedTask = { ...this.props.selectedTask }
    let editedTask = null

    newTasks.forEach(task => {
      if (task[Keys.TASK_ID] === selectedTask.tid) {
        task[Keys.DATA].push(row)
        editedTask = task
      }
    })
    this.props.submitTask(editedTask)
    this.props.setReportTasks(newTasks)
  }

  isValid = form => {
    return true
  }

  handleSubmit = event => {
    event.preventDefault()
    if (this.isValid(this.state) === true) {
      if (this.props.editItem === true) {
        this.handleEditSubmit(event)
      } else {
        this.handleNewSubmit(event)
      }
      this.finish()
    }
  }

  getColumns = () => {
    const task = this.props.selectedTask
    if (!isNil(task) && !isNil(task[Keys.TITLES])) {
      return task[Keys.TITLES]
    }
  }

  render() {
    return (
      <form className="itemForm" onSubmit={this.handleSubmit}>
        <ul className="formList">
          {this.getColumns().map((col, index) => {
            return (
              <Input
                value={
                  this.state.row[index] === null ? '' : this.state.row[index]
                }
                onChange={this.handleChange}
                key={index}
                type="text"
                name={col}
                label={col}
              />
            )
          })}

          {this.props.editItem === true ? (
            <li className="formButtonDash">
              <Button type="submit" bg={Colors.PRIME}>
                Save Item Changes
              </Button>
              <Button onClick={this.handleDelete} bg={Colors.DANGER}>
                Delete Item
              </Button>
            </li>
          ) : (
            <li className="formButtonDash">
              <Button type={'submit'} bg={Colors.PRIME}>
                Add new report line
              </Button>
            </li>
          )}
        </ul>
      </form>
    )
  }
}

const mapStateToProps = ({
  reportTasks,
  tasksData,
  selectedRow,
  selectedTask,
  showItemModal,
  editItem,
}) => ({
  reportTasks,
  tasksData,
  selectedRow,
  selectedTask,
  showItemModal,
  editItem,
})

export default withSubmissionHandler(
  connect(
    mapStateToProps,
    actions
  )(ItemModalFormType1)
)
