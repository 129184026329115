import React, { useState } from 'react'
import { connect } from 'redux-zero/react'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

import Input from '../FormElements/Input'
import Colors from '../../../utils/Colors.json'
import Button from '../Button'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys.json'
import { updateUserTasks } from '../../../services/api'

const DeleteByRefModal = props => {
  const [refId, setRefId] = useState('')
  const close = () => {
    setRefId('')
    props.close()
  }

  const handleResponse = response => {
    let msg =
      'delete by ref operations started! chill for couple of minutes, then fetch tasks again '
    let alertType = Keys.ALERT_SUCCESS
    if (response === undefined || response.status !== 200) {
      msg = 'failed to delete Task :( , please check with R&D team'
      alertType = Keys.ALERT_ERROR
    }
    close()
    props.handleAlert({
      text: msg,
      type: alertType,
    })
  }

  const handleDelete = e => {
    e.preventDefault()
    props.showFullScreenLoader()
    const body = {
      rid: props.selected_rid,
      ref_id: refId,
    }
    updateUserTasks(handleResponse, body, 'DELETE', props.hideFullScreenLoader)
  }

  return (
    <Modal backdrop={false} isOpen={props.isOpen} toggle={close}>
      <ModalHeader toggle={close}>Delete task by ref id</ModalHeader>
      <ModalBody>
        <form className="itemForm">
          <ul className="formList">
            <Input
              onChange={e => setRefId(e.target.value)}
              label={'Reference Id'}
              name={'ref_id'}
              value={refId}
            />
            <Button bg={Colors.DANGER} onClick={handleDelete}>
              Delete Tasks
            </Button>
          </ul>
        </form>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({
  showTasksModal,
  editTask,
  selectedTask,
  showDeleteTaskWarningModal,
  showSubmitTaskWarningModal,
  showCopyTaskModal,
  creatingTaskOfType,
  taskTypeMap,
  selected_rid,
}) => ({
  showTasksModal,
  editTask,
  selectedTask,
  showDeleteTaskWarningModal,
  showSubmitTaskWarningModal,
  showCopyTaskModal,
  creatingTaskOfType,
  taskTypeMap,
  selected_rid,
})
export default connect(
  mapStateToProps,
  actions
)(DeleteByRefModal)
