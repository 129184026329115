import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import CategoryModalForm from './Form/CategoryModalForm'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const CategoryModal = props => {
  const close = () => {
    props.closeCategoryModal()
  }

  return (
    <Modal
      backdrop={false}
      isOpen={props.showCategoryModal && !props.showImageCarouselModal}
      toggle={close}
    >
      <ModalHeader toggle={close} />
      <ModalBody>
        <CategoryModalForm />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({ showCategoryModal, showImageCarouselModal }) => ({
  showCategoryModal,
  showImageCarouselModal,
})

export default connect(
  mapStateToProps,
  actions
)(CategoryModal)
