import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import './sortableEditableStyle.css'
import EditIcon from '../../../assets/svg-icons/pencil.svg'
import CloseIcon from '../../../assets/svg-icons/error.svg'
import Popover from '@material-ui/core/Popover'
import '../styles/formStyle.css'
import ColorPalette from '../ColorPalette'
const StyledImage = styled.img`
  width: 15px;
  height: 15px;
  margin: 0px 5px 0px 0px;
  cursor: pointer;
`

const ButtonStyled = styled.button`
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
`

class SortableEditableListWithColor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editingTagValue: null,
      editingTagIndex: null,
      anchorElText: null,
      anchorElColor: null,
    }
  }

  handleClose = () => {
    this.setState({
      anchorElText: null,
      anchorElColor: null,
    })
  }

  handleEditKeyPress = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      this.setState({
        anchorElText: null,
        editingTagIndex: null,
        editingTagValue: null,
      })
    }
  }

  render() {
    return (
      <div className="App">
        <main>
          <ul className={'suggestionsList'}>
            {this.props.items.map((item, idx) => (
              <li
                className="suggestionsItem"
                key={item.tag}
                onDragOver={() => this.props.onDragOver(idx)}
              >
                <div
                  className="drag"
                  draggable
                  onDragStart={e => this.props.onDragStart(e, idx)}
                  onDragEnd={this.props.onDragEnd}
                >
                  <span className="content">{item.tag}</span>

                  <ButtonStyled
                    type="button"
                    id={idx}
                    aria-owns={
                      Boolean(this.state.anchorElColor) ? item.tag : undefined
                    }
                    style={{ background: item.bg }}
                    aria-haspopup="true"
                    variant="contained"
                    onClick={e =>
                      this.setState({
                        anchorElColor: e.currentTarget,
                        editingTagValue: item.tag,
                        editingTagIndex: idx,
                      })
                    }
                  />

                  <StyledImage
                    src={EditIcon}
                    onClick={e =>
                      this.setState({
                        anchorElText: e.currentTarget,
                        editingTagValue: item.tag,
                        editingTagIndex: idx,
                      })
                    }
                    aria-haspopup="true"
                    variant="contained"
                    aria-owns={
                      Boolean(this.state.anchorElText) ? item.tag : undefined
                    }
                  />
                  <StyledImage
                    src={CloseIcon}
                    onClick={e => this.props.onClose(e, idx)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </main>

        <Popover
          id={this.state.editingTagIndex}
          open={Boolean(this.state.anchorElText)}
          anchorEl={this.state.anchorElText}
          onBackdropClick={this.handleClose}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <textarea
            autoFocus={true}
            className={'content'}
            value={this.state.editingTagValue}
            onChange={e => {
              this.props.onTagEdite(e, this.state.editingTagIndex)
              this.setState({ editingTagValue: e.target.value })
            }}
            onKeyPress={e => this.handleEditKeyPress(e)}
          />
        </Popover>

        <Popover
          id={this.state.editingTagIndex}
          open={Boolean(this.state.anchorElColor)}
          anchorEl={this.state.anchorElColor}
          onBackdropClick={this.handleClose}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ColorPalette
            tagIndex={this.state.editingTagIndex}
            onColorChange={this.props.onColorChange}
            AfterColorChange={this.handleClose}
          />
        </Popover>

        <input
          className={'formInput'}
          onChange={this.props.onChange}
          onSubmit={this.props.onSubmit}
          onKeyPress={this.props.onKeyPress}
          placeholder={this.props.placeholder}
          value={this.props.inputValue}
          type="text"
        />
      </div>
    )
  }
}

SortableEditableListWithColor.defaultProps = {
  bg: '#49486b',
  placeholder: 'Type a tag and press Enter ...',
}

SortableEditableListWithColor.propTypes = {
  bg: PropTypes.string,
  items: PropTypes.any,
  onDragOver: PropTypes.any,
  onDragStart: PropTypes.any,
  onDragEnd: PropTypes.any,
  onChange: PropTypes.any,
  onKeyPress: PropTypes.any,
  onFormSubmit: PropTypes.any,
  onClose: PropTypes.any,
  placeholder: PropTypes.any,
  inputValue: PropTypes.any,
}

export default SortableEditableListWithColor
