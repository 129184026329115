import React from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys'
import { updateUserTasks } from '../../../services/api'
import {
  isSolid,
  taskBodyFormatter,
  isValidTaskBody,
} from '../../../utils/taskHandler'
import { isEmpty } from 'lodash'

const withSubmissionHandler = WrappedComponent => {
  class WithSubmissionHandler extends React.Component {
    constructor(props) {
      super(props)
    }

    handleResponseError = status => {
      if (status === '401') {
        this.props.logout()
      }
    }

    handleSubmissionResponse = response => {
      let msg =
        'ERROR: changes were not saved, something is wrong with the data'
      let alertType = Keys.ALERT_ERROR

      if (response !== undefined && response['success'] === true) {
        msg = 'Changes Were Saved :) !'
        alertType = Keys.ALERT_SUCCESS
      }

      this.props.handleAlert({
        text: msg,
        type: alertType,
      })
      this.handleResponseError(response.status.toString())
    }

    submitTask = (
      task,
      taskData = null,
      attachedSubTasks = null,
      subTask2Delete = null
    ) => {
      this.setState({ waitingForRes: true })
      const rId = this.props.selected_rid
      const groups = [this.props.selectedGroup[Keys.OBJECT_ID]]
      const users = !isEmpty(this.props.taskSelectedUser)
        ? [this.props.taskSelectedUser.value]
        : []
      const { body, taskType } = taskBodyFormatter(
        task,
        groups,
        users,
        rId,
        subTask2Delete,
        attachedSubTasks,
        taskData
      )

      if (isValidTaskBody(body, taskType)) {
        this.props.showFullScreenLoader()

        updateUserTasks(
          this.handleSubmissionResponse,
          body,
          'PUT',
          this.props.hideFullScreenLoader
        )
      } else {
        this.props.handleAlert({
          text:
            'task is not valid, check if : you field all the necessary data, and products',
          type: Keys.ALERT_ERROR,
        })
      }
    }

    submitCategory = (task, subTasks) => {
      if (isSolid(subTasks)) {
        this.submitTask(task, null, subTasks)
      } else {
        this.submitTask(task, null, null)
      }
    }

    submitSubTask = (task, taskData, subTask) => {
      this.submitTask(task, taskData, [subTask])
    }

    render() {
      return (
        <WrappedComponent
          submitTask={this.submitTask}
          submitCategory={this.submitCategory}
          submitSubTask={this.submitSubTask}
          {...this.props}
        />
      )
    }
  }

  const mapStateToProps = ({
    tasks,
    tasksData,
    subTasks,
    selected_rid,
    selectedGroup,
    selectedTask,
    selectedCategory,
    selectedRow,
    selectedSubTask,
    selectedProduct,
    showItemModal,
    editTask,
    editCategory,
    editSubTask,
    editProduct,
    creatingTaskOfType,
    creatingSubTaskOfType,
    taskTypesView,
    subTasksToDelete,
    statusMenus,
    taskTags,
    retailsConfig,
    setDynamicTaskTypesView,
    setSubTasks,
  }) => ({
    tasks: tasks,
    tasksData,
    subTasks,
    selected_rid,
    selectedGroup,
    selectedTask,
    selectedCategory,
    selectedRow,
    selectedSubTask,
    selectedProduct,
    showItemModal,
    editTask,
    editCategory,
    editSubTask,
    editProduct,
    creatingTaskOfType,
    creatingSubTaskOfType,
    taskTypesView,
    subTasksToDelete,
    statusMenus,
    taskTags,
    retailsConfig,
    setDynamicTaskTypesView,
    setSubTasks,
  })

  return connect(mapStateToProps, actions)(WithSubmissionHandler)
}

export default withSubmissionHandler
