import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import '../styles/formStyle.css'
import RuleBoxColor from '../FormElements/RuleBoxColor'
import Plus from '../../../assets/svg-icons/plus-white.svg'
import styled from 'styled-components'
import Button from '../Button'
import Colors from '../../../utils/Colors'
import Divider from '@material-ui/core/Divider'
import Keys from '../../../utils/Keys'
import RuleBoxSuffix from '../FormElements/RuleBoxSuffix'
const ButtonIcon = styled.img`
  width: 30px;
  height: 30px;
`
const ButtonStyled = styled(Button)`
  display: flex;
  padding: 0px 20px;
  margin: auto;
`

const ButtonDush = styled.div`
  margin: 10px 0 10px 0;
  display: flex;
  flex-diration: row;
`

const COLUMN1 = 0
const COLUMN2 = 2
const COMPERATOR = 1

class TaskType1Rules extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getRules = type => {
    let rulesColor = []
    const { rules } = this.props
    rules.forEach((rule, index) => {
      if (rule[Keys.TYPE] === type) {
        rulesColor.push({ rule_data: rule, index: index })
      }
    })
    return rulesColor
  }

  printRulesSuffix = () => {
    let rules = []
    const { columnNumber } = this.props
    this.getRules('pos/neg').forEach(rule => {
      const rule_data = rule['rule_data']
      const index = rule['index']
      rules.push(
        <RuleBoxSuffix
          False={rule_data['False']}
          True={rule_data['True']}
          target_column={rule_data['target_column']}
          rule={rule_data['rule']}
          type={rule_data['type']}
          index={index}
          key={index}
          deleteTableRule={this.deleteTableRule}
          handleRuleChange={this.handleRuleChange}
          columnNumber={columnNumber}
        />
      )
    })
    return rules
  }

  printRulesColors = () => {
    let rules = []
    const { columnNumber } = this.props
    this.getRules(Keys.COLOR).forEach(rule => {
      const rule_data = rule['rule_data']
      const index = rule['index']
      rules.push(
        <RuleBoxColor
          False={rule_data['False']}
          True={rule_data['True']}
          target_column={rule_data['target_column']}
          rule={rule_data['rule']}
          type={rule_data['type']}
          index={index}
          key={index}
          deleteTableRule={this.deleteTableRule}
          handleRuleChange={this.handleRuleChange}
          columnNumber={columnNumber}
          handleColorTrueRuleChange={this.handleColorTrueRuleChange}
          handleColorFalseRuleChange={this.handleColorFalseRuleChange}
        />
      )
    })

    return rules
  }

  handleColorTrueRuleChange = (event, ruleIndex) => {
    let rules = [...this.props.rules]
    if (rules.length > ruleIndex) {
      let changedRule = rules[ruleIndex]
      changedRule['True'] = event.target.name
      rules[ruleIndex] = changedRule
      this.props.handleRulesChange(rules)
    }
  }

  handleColorFalseRuleChange = (event, ruleIndex) => {
    let rules = [...this.props.rules]
    if (rules.length > ruleIndex) {
      let changedRule = rules[ruleIndex]
      changedRule['False'] = event.target.name
      rules[ruleIndex] = changedRule
      this.props.handleRulesChange(rules)
    }
  }

  updateColorRule = (event, changedRule) => {
    if (event.target.name === 'column1') {
      changedRule['rule'][COLUMN1] = event.target.value
    } else if (event.target.name === 'column2') {
      changedRule['rule'][COLUMN2] = event.target.value
    } else if (event.target.name === 'rule_comparator') {
      changedRule['rule'][COMPERATOR] = event.target.value
    } else if (event.target.name === Keys.TARGET_COLUMN) {
      changedRule[event.target.name] = Number(event.target.value)
    } else {
      changedRule[event.target.name] = event.target.value
    }
    return changedRule
  }

  handleRuleChange = (event, ruleIndex) => {
    let rules = [...this.props.rules]
    if (rules.length > ruleIndex) {
      let changedRule = rules[ruleIndex]
      if (changedRule[Keys.TYPE] === Keys.COLOR) {
        changedRule = this.updateColorRule(event, changedRule)
      } else {
        changedRule[event.target.name] = Number(event.target.value)
      }

      rules[ruleIndex] = changedRule
      this.props.handleRulesChange(rules)
    }
  }

  deleteTableRule = index => {
    let oldRules = [...this.props.rules]
    oldRules.splice(index, 1)
    this.props.handleRulesChange(oldRules)
  }

  addColorRule = () => {
    let rules = [...this.props.rules]

    const defaultRule = {
      True: Colors.ACTION,
      False: Colors.DANGER,
      rule: ['0', '=', '0'],
      target_column: 0,
      type: 'color',
    }

    rules.push(defaultRule)
    this.props.handleRulesChange(rules)
  }

  addSuffixRule = () => {
    let rules = [...this.props.rules]

    if (this.getRules('pos/neg').length < this.props.columnNumber) {
      const defaultRule = {
        True: Colors.ACTION,
        False: Colors.DANGER,
        rule: '+,-',
        target_column: 0,
        type: 'pos/neg',
      }

      rules.push(defaultRule)
      this.props.handleRulesChange(rules)
    }
  }

  render() {
    return (
      <div>
        <ButtonDush>
          <ButtonStyled
            type="button"
            onClick={this.addSuffixRule}
            className="button"
            bg={Colors.ACTION}
          >
            Add new neg/pos rule <ButtonIcon src={Plus} />
          </ButtonStyled>
        </ButtonDush>

        {this.printRulesSuffix()}
        <Divider />
        <ButtonDush>
          <ButtonStyled
            type="button"
            onClick={this.addColorRule}
            className="button"
            bg={Colors.ACTION}
          >
            Add new color rule <ButtonIcon src={Plus} />
          </ButtonStyled>
        </ButtonDush>
        {this.printRulesColors()}
      </div>
    )
  }
}

const mapStateToProps = ({
  reportTasks,
  selected_rid,
  selected_sid,
  selectedTask,
  editTask,
}) => ({
  reportTasks,
  selected_rid,
  selected_sid,
  selectedTask,
  editTask,
})

export default connect(
  mapStateToProps,
  actions
)(TaskType1Rules)
