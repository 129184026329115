import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import Keys from '../../utils/Keys'
import ProductTable from '../Table/ProductTable'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
const SubTaskTableExpandComponent = props => {
  return (
    <div>
      {!isNil(get(props.subTask, Keys.TYPE, null)) &&
      props.subTask.type === Keys.SUB_TASK_PRODUCT ? (
        <ProductTable products={props.subTask} />
      ) : null}
    </div>
  )
}

const mapStateToProps = ({
  tasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  enableSaveTasksChanges,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  thisWeek,
}) => ({
  tasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  enableSaveTasksChanges,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  thisWeek,
})

export default connect(
  mapStateToProps,
  actions
)(SubTaskTableExpandComponent)
