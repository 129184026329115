import React from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import Colors from '../../../utils/Colors'
import styled from 'styled-components'
import ColorPalette from '../ColorPalette'
import Popover from '@material-ui/core/Popover'

const ButtonStyled = styled.button`
  width: 18px;
  height: 18px;
  padding: 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  display: flex;
`

class ColorSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      color: this.props.color,
      anchorElColor: null,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.color !== this.props.color) {
      this.setState({ color: this.props.color })
    }
  }

  handleClose = () => {
    this.setState({
      anchorElColor: null,
    })
  }

  render() {
    return (
      <li className="formListItem">
        <label className="formLabel">{this.props.label}</label>
        <ButtonStyled
          type="button"
          aria-owns={
            Boolean(this.state.anchorElColor)
              ? this.state.anchorElColor
              : undefined
          }
          style={{ background: this.state.color }}
          aria-haspopup="true"
          variant="contained"
          onClick={e =>
            this.setState({
              anchorElColor: e.currentTarget,
            })
          }
        />

        <Popover
          id={this.props.id}
          open={Boolean(this.state.anchorElColor)}
          anchorEl={this.state.anchorElColor}
          onBackdropClick={this.handleClose}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ColorPalette
            tagIndex={this.props.index}
            onColorChange={this.props.onColorChange}
            AfterColorChange={this.handleClose}
          />
        </Popover>
      </li>
    )
  }
}

ColorSelect.defaultProps = {
  color: Colors.ACTION,
  label: 'Color Select',
  id: 1,
}

ColorSelect.propTypes = {
  onColorChange: PropTypes.func,
}

export default ColorSelect
