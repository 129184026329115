import React from 'react'
import styled from 'styled-components'
import { connect } from 'redux-zero/react/index'
import actions from '../../../store/actions'
import Keys from '../../../utils/Keys'
import Button from '../Buttons/Button'
import { get, isNil } from 'lodash'
import { getUserTasks } from '../../../services/api'
import Select from '../SingleGroupSelector'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { date2timestamp, getNow, getNextWeek } from '../../../utils/dateHandler'
import { getTaskFromLocalStorage, sortTasks } from '../../../utils/taskHandler'
import AlertMassages from '../../../utils/AlertMassages'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { getLocalStorageKey } from '../../../utils'
import TextField from '@material-ui/core/TextField'
import { LocalStorage } from '../../../services/localStorage'
const ToggleButtonStyled = styled(ToggleButton)`
  height: 43px !important;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
`
const InputWrapper = styled.div`
  align-items: baseline;
  width: fit-content;
`

const DateSelector = styled.li`
  opacity: ${props => (props.show ? '1' : '0')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  transform: translateY(${props => (props.show ? '0px' : '40px')});
  transition: all 500ms ease-in-out;
`

class TaskFetcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...this._setState() }
  }
  _setState = () => {
    return {
      min: getNow(),
      max: getNextWeek(),
      enableSubmit: true,
      fromDate: getNow(),
      toDate: getNextWeek(),
      byDates: false,
      options: this.props.users
        ? this.props.users.map(user => {
            return {
              title: `${user.first_name} ${user?.last_name ?? ''}`,
              value: user.uid,
              sec_uid: user?.sec_uid ?? '',
              desc: user?.desc ?? '',
            }
          })
        : [],
      selectedUserID: '',
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.selected_rid !== this.props.selected_rid ||
      prevProps.groups !== this.props.groups ||
      prevProps.users !== this.props.users
    ) {
      this.setState({
        ...this._setState(),
      })
    }
  }

  handleFromDatePicker = e => {
    this.setState({
      min: e.target.value,
      fromDate: e.target.value,
    })
  }

  handleToDatePicker = e => {
    this.setState({
      max: e.target.value,
      toDate: e.target.value,
    })
  }

  handleResponseError = status => {
    if (status === 401) {
      this.props.logout()
    }
  }

  handleResponse = response => {
    if (response !== undefined && response.status === 200) {
      console.log('got the tasks data')
      let groupsTasks = response.groups_tasks
      let storeTasksData = response.tasks_data
      let subTasks = response.sub_tasks
      if (isNil(groupsTasks)) {
        groupsTasks = []
        this.props.handleAlert({
          text: 'Did not got any tasks!',
          type: 'info',
        })
      }
      if (isNil(storeTasksData)) {
        storeTasksData = {}
      }
      let tasks = groupsTasks
      const { selected_rid, selectedGroup } = this.props
      tasks = sortTasks(tasks, () => {}, false)
      const cachedTask = LocalStorage.getData(
        getLocalStorageKey(selected_rid, selectedGroup.name)
      )
      const { task: cachedClonedTask } = getTaskFromLocalStorage(
        selected_rid,
        selectedGroup.name,
        Keys.CLONED_TASK_LS_PREFIX
      )
      if (cachedTask) {
        tasks = [cachedTask.task, ...tasks]
        storeTasksData = { ...cachedTask.taskData, ...storeTasksData }
        subTasks = { ...cachedTask.subtasks, ...subTasks }
      }
      if (cachedClonedTask) {
        tasks = [cachedClonedTask.task, ...tasks]
        storeTasksData = { ...cachedClonedTask.taskData, ...storeTasksData }
        subTasks = { ...cachedClonedTask.subTasks, ...subTasks }
      }

      this.props.setAppTasks(tasks)
      this.props.setTasksData(storeTasksData)
      this.props.setSubTasks(subTasks)
    } else {
      this.props.handleAlert({
        text: AlertMassages[response.status.toString()],
        type: 'error',
      })
      this.handleResponseError(response.status)
    }
  }

  getUserTasks = () => {
    const { fromDate, toDate, byDates } = this.state
    const { selected_rid, selectedGroup } = this.props
    const fromTs = date2timestamp(fromDate)
    const ToTs = date2timestamp(toDate)
    if (ToTs > fromTs) {
      this.props.showFullScreenLoader()
      const params = {
        rid: selected_rid,
        groups: selectedGroup?.[Keys.OBJECT_ID] ?? '',
        users: this.state.selectedUserID?.value ?? '',
        fromDate: fromTs,
        toDate: ToTs,
        byDates: byDates,
      }
      getUserTasks(this.handleResponse, params, this.props.hideFullScreenLoader)
    } else {
      this.props.handleAlert({
        text:
          'HI! you are breaking space and time!, the first date should be earlier then second date',
        type: 'error',
      })
    }
  }

  handleStoresSelect = (event, val) => {
    let groupId = null
    if (!isNil(val)) {
      groupId = val.value
    }
    this.props.setSelectedGroup(this.props.groups[groupId])
    this.props.handleTaskFetcherStoreSelect(groupId)
  }

  _alert = () => {
    this.props.handleAlert({
      text: 'Fill dates first, notice: that your from date < to date',
      type: 'error',
    })
  }

  handleAlignment = (e, alignment) => {
    this.setState({ byDates: alignment })
  }

  handleUserSelect = (event, val) => {
    this.setState({ selectedUserID: val })
    this.props.setTaskSelectedUser(val)
    this.props.setSelectedGroup({})
  }
  hadleClearUserID = () => {
    this.setState({ selectedUserID: '' })
    this.props.setTaskSelectedUser({})
  }

  render() {
    const { selectedGroup } = this.props

    return (
      <Wrapper>
        <InputWrapper>
          <Select
            label="Groups"
            name="stores"
            hadleClearUserID={this.hadleClearUserID}
            value={
              get(selectedGroup, Keys.OBJECT_ID, false)
                ? selectedGroup[Keys.OBJECT_ID]
                : null
            }
          />
          <Autocomplete
            id="combo-box-demo"
            options={this.state.options}
            style={{ width: 300, marginTop: '20px' }}
            getOptionLabel={option => option.title || ''}
            onChange={this.handleUserSelect}
            value={this.state.selectedUserID}
            renderInput={params => (
              <TextField {...params} label={'Users'} fullWidth />
            )}
            filterOptions={(options, state) => {
              const inputValue = state.inputValue.toLowerCase()
              return options.filter(
                option =>
                  option?.title?.toLowerCase()?.includes(inputValue) ||
                  '' ||
                  option?.desc?.toLowerCase()?.includes(inputValue) ||
                  '' ||
                  option?.sec_uid?.toLowerCase()?.includes(inputValue) ||
                  '' ||
                  option?.value?.toLowerCase()?.includes(inputValue) ||
                  ''
              )
            }}
            renderOption={option => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{option.title}</div>
                {option?.value && (
                  <div style={{ fontSize: '12px', color: 'gray' }}>
                    uid: {option.value}
                  </div>
                )}
                {option?.desc && (
                  <div style={{ fontSize: '12px', color: 'gray' }}>
                    desc: {option.desc}
                  </div>
                )}
                {option?.sec_uid && (
                  <div style={{ fontSize: '12px', color: 'gray' }}>
                    sec uid: {option.sec_uid}
                  </div>
                )}
              </div>
            )}
          />
        </InputWrapper>
        <InputWrapper style={{ marginLeft: '50px' }}>
          <ToggleButtonGroup
            value={this.state.byDates}
            exclusive
            onChange={this.handleAlignment}
          >
            <ToggleButtonStyled value={false}>
              Current running tasks
            </ToggleButtonStyled>
            <ToggleButtonStyled value={true}>Between ranges</ToggleButtonStyled>
          </ToggleButtonGroup>
        </InputWrapper>
        <DateSelector
          show={this.state.byDates}
          className="taskFetcherListItem taskFetcherMargin"
          style={{ width: 'fit-content' }}
        >
          <label className="taskFetcherLabel">End of tasks range</label>
          <InputWrapper>
            <input
              style={{ width: 'fit-content' }}
              value={this.state.fromDate !== null ? this.state.fromDate : ''}
              type="datetime-local"
              name={Keys.TIMESTAMP}
              required={false}
              max={this.state.max}
              onChange={this.handleFromDatePicker}
              step={'1'}
            />

            <input
              className="formInput"
              style={{ width: 'fit-content' }}
              value={this.state.toDate !== null ? this.state.toDate : ''}
              onChange={this.handleToDatePicker}
              type="datetime-local"
              name={Keys.TIMESTAMP}
              required={false}
              min={this.state.min}
              step={'1'}
            />
          </InputWrapper>
        </DateSelector>
        <Button
          style={{ marginBottom: '0' }}
          onClick={this.state.enableSubmit ? this.getUserTasks : this._alert}
          label={'Fetch Tasks'}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = ({
  selected_rid,
  enableDateSubmit,
  groups,
  users,
  selectedGroup,
}) => ({
  selected_rid,
  enableDateSubmit,
  groups,
  users,
  selectedGroup,
})
export default connect(mapStateToProps, actions)(TaskFetcher)
