import React from 'react'
import Italic from '../../assets/svg-icons/message.svg'
import Clock from '../../assets/svg-icons/clock.svg'
import Button from './Button'
import styled from 'styled-components'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'

const ImageStyled = styled.img`
  width: 18px;
  height: 18px;
  padding: 0px 0px 1px 0px;
`
const IButton = styled(Button)`
  border-radius: 50%;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  width: 25px;
  height: 25px;
  padding: 0;
`


class TimeStampCellClass extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    printTimestamps = () => {
        let timestamps = this.props.txt.map((timestamp, index) => {
            let date = new Date(timestamp * 1000).toString()
            return <li key={index}>{date.substring(0, date.indexOf('GMT'))}</li>
        })
        return timestamps
    }

    render() {
        return (
            <React.Fragment>
                <style type="text/css">{`
                .dropdown {
                  position: relative;
                  display: inline-block;
                  color: #424858;
                }
                .dropbtn {
                  position:absolute;
                }

                .dropdown-content {
                  display: none;
                  left:28px;
                  position: absolute;
                  background-color: #f9f9f9;
                  min-width: 180px;
                  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                }

                .dropdown:hover .dropdown-content {
                  color: #424858;
                  display: block;
                }
                `}</style>
                <IButton className="dropdown">
                    <ImageStyled src={Clock} />
                    <div className="dropdown-content">
                        <ul>
                            {this.props.txt !== undefined && this.props.txt.length > 0
                                ? this.printTimestamps()
                                : null}
                        </ul>
                    </div>
                </IButton>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({
                             tasks,
                             userRidsDetails,
                             selected_rid,
                             selected_sid,
                             locales,
                         }) => ({
    tasks,
    userRidsDetails,
    selected_rid,
    selected_sid,
    locales,
})

export const MassageCell = connect(
    mapStateToProps,
    actions
)()

export const TimeStampCell = connect(
    mapStateToProps,
    actions
)(TimeStampCellClass)
