import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import React, { useEffect, useMemo, useState } from 'react'
import AppTasksTable from '../components/Table/TasksTable/AppTasksTable'
import { ItemModal } from '../components/Elements/ItemModal'
import ImageCarouselModal from '../components/Elements/ImageCarouselModal'
import TaskModal from '../components/Elements/TaskModal'
import TasksFetcher from '../components/Elements/TasksFetcher/TasksFetcher'
import Layout from '../components/layout'
import { connect } from 'redux-zero/react'
import actions from '../store/actions'
import {
  DeleteCategoryWarning,
  SubmitTaskModal,
} from '../components/Elements/AlertModal'
import withRetailUsers from '../components/Elements/withRetailUsers'
import CategoryModal from '../components/Elements/CategoryModal'
import CopyTaskModal from '../components/Elements/CopyTaskModal'
import FullScreenLoader from '../components/FullScreenLoader'
import styled from 'styled-components'
import ReportTasksTable from '../components/Table/TasksTable/ReportTasksTable'
import ProductModal from '../components/Elements/ProductModal'
import ValidationPassword from '../components/Elements/ValidationPassword'
import IconButtonPopOver from '../components/Elements/Buttons/IconButtonPopOver'
import Add from '@material-ui/icons/Add'
import TaskTypeSelector from '../components/Elements/TaskTypeSelector'
import IconButtonWrapper from '../components/Elements/FormElements/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteSweep'
import DeleteByRefModal from '../components/Elements/Modals/DeleteByRefModal'
import withGroups from '../components/Elements/withGroups'
import DeleteTaskModal from '../components/Elements/Modals/DeleteTaskModal'
import CloneTaskModal from '../components/Elements/Modals/CloneTaskModal'
import AssigneeModal from '../components/Elements/Modals/AssigneeModal'
import _ from 'lodash'

const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  margin: 10px;
`
const TasksPage = props => {
  const defaultTasks = {
    'Data driven': 0,
    Operational: 1,
    Training: 2,
    Vm: 3,
  }
  const [openDeleteByRef, setOpenDeleteByRef] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const handleMergeObjects = useMemo(() => {
    const dynamicSubTasks =
      props.retailsConfig[props.selected_rid] &&
      props.retailsConfig[props.selected_rid].dynamic_task_views !== undefined
        ? props.retailsConfig[props.selected_rid].dynamic_task_views
        : []
    let dynamicObj = {}

    if (
      dynamicSubTasks !== undefined &&
      dynamicSubTasks !== null &&
      dynamicSubTasks.length > 0
    ) {
      dynamicObj = _.reduce(
        dynamicSubTasks,
        (acc, { task_view_name, task_view_id }) => ({
          ...acc,
          [task_view_name]: task_view_id,
        }),
        {}
      )
      return Object.assign(defaultTasks, dynamicObj)
    }
    return defaultTasks
  }, [props.selected_rid, props.retailsConfig, props.groups])

  useEffect(() => {
    props.setDynamicTaskTypesView(handleMergeObjects)
  }, [props.groups])

  return (
    <Layout>
      <HeaderStyled>
        <h1>Tasks</h1>
        <IconButtonWrapper
          onClick={e => {
            e.preventDefault()
            setOpenDeleteByRef(true)
          }}
          icon={<DeleteIcon />}
          color={'secondary'}
        />
        <IconButtonPopOver
          popoverChild={
            <TaskTypeSelector
              AfterSelect={() => {
                props.setSelectedTask({
                  data_oid: null,
                  task_id: null,
                  data_order: null,
                })
              }}
            />
          }
          color={'green'}
          icon={<Add style={{ fontSize: 25 }} />}
        />
      </HeaderStyled>
      <br />
      <br />
      <ValidationPassword />
      <DeleteByRefModal
        isOpen={openDeleteByRef}
        close={() => setOpenDeleteByRef(false)}
      />
      <CategoryModal />
      <ItemModal />
      <ProductModal />
      <TaskModal />
      {props.groups !== {} ? (
        <TasksFetcher {...props} setSelectedUser={setSelectedUser} />
      ) : null}
      <span style={{ fontSize: '25px', marginLeft: '10px' }}>
        Application tasks
      </span>
      <AppTasksTable />
      <span style={{ fontSize: '25px', marginLeft: '10px' }}>Report tasks</span>
      <ReportTasksTable />
      <ImageCarouselModal />
      {/*<DeleteTaskWarning />*/}
      <DeleteTaskModal {...props} />
      <CopyTaskModal />
      <CloneTaskModal {...props} />
      <AssigneeModal
        {...props}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <DeleteCategoryWarning />
      <FullScreenLoader loading={props.loading} />
    </Layout>
  )
}

const mapStateToProps = ({
  selectedTask,
  selectedGroup,
  showDeleteTaskWarningModal,
  taskSelectedUser,
  tasks,
  loading,
  tasksData,
}) => ({
  selectedTask,
  selectedGroup,
  taskSelectedUser,
  showDeleteTaskWarningModal,
  tasks,
  loading,
  tasksData,
})

export default connect(
  mapStateToProps,
  actions
)(withRetailUsers(withGroups(TasksPage)))
