import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/formStyle.css'
import PropTypes from 'prop-types'
import SortableEditableList from './SortableEditableList'
import EditableList from './EditableList'

const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.enter]

class EditableListWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: this.props.items,
      new_item: '',
    }
  }

  onTagEdite = (e, index) => {
    let items = this.state.items
    items[index].tag = e.target.value
    this.setState({ items: items })
    this.props.setItems(items, index, 'EDIT')
  }

  onCloseTag = (e, index) => {
    let items = this.state.items
    items.splice(index, 1)
    this.setState({ items: items })
    this.props.setItems(items, index, 'DELETE')
  }

  onInputChangeTag = e => {
    this.setState({ new_item: e.target.value })
  }

  onKeyPressTag = (e, index) => {
    if (e.key === 'Enter') {
      let items = this.state.items
      const new_item = this.state.new_item.trim()
      let isUniq = true
      items.forEach(tag => {
        if (tag.tag === new_item) {
          isUniq = false
        }
      })
      if (new_item !== '' && isUniq === true) {
        items.push({ tag: new_item })
      }
      this.setState({ items: items, new_item: '' })
      this.props.setItems(items, index, 'ADD')
    }
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  render() {
    return (
      <ul className="formList">
        <li className="formListItem">
          <label className="formLabel">{this.props.label}</label>
          <EditableList
            items={this.state.items}
            onChange={this.onInputChangeTag}
            onKeyPress={this.onKeyPressTag}
            onClose={this.onCloseTag}
            onTagEdite={this.onTagEdite}
            placeholder={this.props.placeholder}
            inputValue={this.state.new_item}
          />
        </li>
      </ul>
    )
  }
}

EditableListWrapper.defaultProps = {
  bg: '#49486b',
  placeholder: 'Type a tag and press Enter ...',
}

EditableListWrapper.propTypes = {
  items: PropTypes.any,
  setItems: PropTypes.func,
  label: PropTypes.string,
}

const mapStateToProps = ({}) => ({})

export default connect(
  mapStateToProps,
  actions
)(EditableListWrapper)
