import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import '../styles/formStyle.css'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import { TextareaAutosize } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 220,
  },
}))

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const ButtonWrapper = styled.div`
  margin: 5px;
`

const TextArea = props => {
  const classes = useStyles()
  return (
    <li style={{ marginBottom: '30px' }}>
      <InputLabel shrink id="label">
        {props.label}
      </InputLabel>
      <Wrapper>
        <TextareaAutosize
          key={props.key}
          name={props.name}
          rowsmin={props.rowsMin}
          onChange={props.onChange}
          value={props.value}
        />
        {props.children !== undefined ? (
          <ButtonWrapper>{props.children}</ButtonWrapper>
        ) : null}
      </Wrapper>
    </li>
  )
}

TextArea.defaultProps = {
  rowsMin: 10,
  key: null,
}

TextArea.propTypes = {
  name: PropTypes.string,
  rowsMin: PropTypes.number,
  label: PropTypes.string,
}

export default TextArea
