import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const AStyled = styled.a`
  background-color: #424858;
  color: white !important;
  border-radius: 0%;
  margin: 3px 3px 0 3px;
  padding: 3px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 3px;
  }
  &:hover {
    background-color: #61687a;
  }
`
const TaskTypeSelector = props => {
  const handleTypeSelect = event => {
    let task_type = event.target.id
    props.setShowTasksModal(true)
    props.setCreatingTaskOfType(Number(task_type))
    props.AfterSelect()
  }

  return (
    <React.Fragment>
      <StyledDiv>
        <AStyled id="0" onClick={handleTypeSelect}>
          General
        </AStyled>
        <AStyled id="1" onClick={handleTypeSelect}>
          Report
        </AStyled>
        <AStyled id="2" onClick={handleTypeSelect}>
          External Link Task
        </AStyled>
      </StyledDiv>
    </React.Fragment>
  )
}

const mapStateToProps = ({ tasks }) => ({
  tasks,
})

export default connect(
  mapStateToProps,
  actions
)(TaskTypeSelector)
