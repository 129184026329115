import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import TaskModalFormType0 from './Form/TaskGeneralForm'
import TaskModalFormType1 from './Form/TaskReportFormMain'
import TaskModalFormType2 from './Form/TaskExternalLinkForm'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Keys from '../../utils/Keys'
const TaskModal = props => {
  const getModalType = () => {
    const { editTask, selectedTask, creatingTaskOfType } = props
    if (editTask === true) {
      return Number(selectedTask.type)
    } else {
      return creatingTaskOfType
    }
  }

  const close = () => {
    props.setCreatingTaskOfType(null)
    props.setShowTasksModal(false)
    props.setEditTask(false)
  }

  const isFormType = type_num => {
    return (
      props.creatingTaskOfType === type_num ||
      (props.selectedTask.type === type_num && props.editTask === true)
    )
  }

  const open = () => {
    props.setShowTasksModal(true)
  }

  return (
    <Modal
      size={'lg'}
      backdrop={false}
      isOpen={
        props.showTasksModal === true &&
        props.showDeleteTaskWarningModal === false &&
        props.showSubmitTaskWarningModal === false &&
        props.showCopyTaskModal === false &&
        props.showCloneTaskModal === false
      }
      toggle={() => this.close()}
    >
      <ModalHeader toggle={() => close()}>
        {props.editTask === true ? 'Edit ' : 'Create '}
        {`${Object.keys(props.taskTypeMap).find(
          key => props.taskTypeMap[key] === getModalType()
        )} task`}
      </ModalHeader>
      <ModalBody>
        {isFormType(Keys.TASK_TYPE_REPORT) ? (
          <TaskModalFormType1 />
        ) : isFormType(Keys.TASK_TYPE_LINK) ? (
          <TaskModalFormType2 />
        ) : (
          <TaskModalFormType0 />
        )}
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = ({
  showTasksModal,
  editTask,
  selectedTask,
  showDeleteTaskWarningModal,
  showSubmitTaskWarningModal,
  showCopyTaskModal,
  creatingTaskOfType,
  taskTypeMap,
  showCloneTaskModal,
}) => ({
  showTasksModal,
  editTask,
  selectedTask,
  showDeleteTaskWarningModal,
  showSubmitTaskWarningModal,
  showCopyTaskModal,
  creatingTaskOfType,
  taskTypeMap,
  showCloneTaskModal,
})
export default connect(mapStateToProps, actions)(TaskModal)
