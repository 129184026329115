import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Button from '../Buttons/Button'
import Colors from '../../../utils/Colors'
import CodeIcon from '@material-ui/icons/CodeRounded'

import {
  setNewOrder,
  validate,
  addNewItemToOrder,
  generateTagList,
  tagsToList,
  inReachOptional,
  isSolid,
} from '../../../utils/taskHandler'
import Keys from '../../../utils/Keys'
import '../styles/formStyle.css'
import Input from '../FormElements/Input'
import TextArea from '../FormElements/TextArea'
import SortableEditableListWrapper from '../Lists/SortableEditableListWrapper'
import FileService from '../../../services/fileServices'
import UploadFile from '../UploadFile'
import withSubmissionHandler from './withSubmissionHandler'
import IconButtonWrapper from '../Buttons/IconButton'

class CategoryModalForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category_name:
        this.props.editCategory === true &&
        isSolid(this.props.selectedCategory.category_name)
          ? this.props.selectedCategory.category_name
          : '',
      order:
        this.props.editCategory === true &&
        isSolid(this.props.selectedCategory.order)
          ? Number(this.props.selectedCategory.order)
          : isSolid(this.props.selectedTask.categories_order)
          ? this.props.selectedTask.categories_order.length
          : 0,

      order_orig:
        this.props.editCategory === true &&
        isSolid(this.props.selectedCategory.order)
          ? Number(this.props.selectedCategory.order)
          : isSolid(this.props.selectedTask.categories_order)
          ? this.props.selectedTask.categories_order.length
          : 0,

      images:
        this.props.editCategory === true &&
        isSolid(this.props.selectedCategory[Keys.IMAGES])
          ? generateTagList(this.props.selectedCategory[Keys.IMAGES])
          : [],

      dialog_box:
        this.props.editCategory === true &&
        isSolid(this.props.selectedCategory[Keys.DIALOG_BOX])
          ? this.props.selectedCategory[Keys.DIALOG_BOX]
          : '',
    }
  }

  handleListChange = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  isValidate = form => {
    const toValidate = ['category_name']
    try {
      return validate(form, toValidate)
    } catch (e) {
      this.props.handleAlert({
        text: e.text,
        type: 'error',
      })
      return false
    }
  }

  preventSubmit = e => {
    const key = e.charCode || e.keyCode || 0
    if (key === 13) {
      e.preventDefault()
    }
  }

  handleImageUpload = async (e, imageName) => {
    this.props.showFullScreenLoader()
    const file = e.target.files[0]
    let files = this.state[imageName]

    const res = await FileService.uploadFile(file, this.props.selected_rid)
    files.push({ tag: res })
    this.setState({ [imageName]: [...files] })
    this.props.hideFullScreenLoader()
  }

  handleSubmit = event => {
    event.preventDefault()
    let editedTask = null
    const {
      tasks,
      selectedTask,
      editCategory,
      selectedCategory,
      subTasks,
    } = this.props
    const { order, order_orig, dialog_box, images } = this.state
    if (this.isValidate(this.state) === true) {
      const category_name = this.state.category_name.trim()
      let newTasks = [...tasks]
      let subTasksToSend = []
      newTasks = newTasks.map(item => {
        if (item[Keys.TASK_ID] === selectedTask.tid) {
          item.isEdit = true
          if (editCategory === false) {
            // new category
            item['categories_order'] = addNewItemToOrder(
              [...item['categories_order']],
              order,
              category_name
            )

            item['categories'][category_name] = {
              [Keys.ST_ORDER]: [],
              done: [],
            }
          } else {
            // edit category
            item['categories'][category_name] = {
              [Keys.ST_ORDER]:
                item['categories'][selectedCategory.category_name][
                  Keys.ST_ORDER
                ],
              done: item['categories'][selectedCategory.category_name].done,
            }

            if (selectedCategory.category_name !== category_name) {
              let i = item['categories_order'].indexOf(
                selectedCategory.category_name
              )
              item['categories_order'][i] = category_name
              delete item['categories'][selectedCategory.category_name]
            }

            // set new order
            if (order !== order_orig) {
              item['categories_order'] = setNewOrder(
                item['categories_order'],
                order_orig,
                order
              )
            }

            //get all category subtasks
            const subTaskOrder =
              item['categories'][category_name][Keys.ST_ORDER]
            subTaskOrder.forEach(st_id => {
              let curr_st = subTasks[st_id]
              curr_st['category'] = category_name
              subTasksToSend.push(curr_st)
            })
          }

          // adding optional fields
          item['categories'][category_name] = inReachOptional(
            item['categories'][category_name],
            {
              dialog_box: dialog_box,
              images: tagsToList(images),
            }
          )
          editedTask = item
        }

        return item
      })

      this.props.closeCategoryModal()
      this.props.setAppTasks(newTasks)
    }
  }

  handleDelete = event => {
    event.preventDefault()
    this.props.setShowDeleteCategoryWarningModal(true)
  }

  render() {
    return (
      <form
        className="itemForm"
        onSubmit={this.handleSubmit}
        onKeyPress={this.preventSubmit}
      >
        <ul className="formList">
          <Input
            value={this.state.category_name}
            onChange={this.handleChange}
            type="text"
            name={'category_name'}
            required={true}
            label={'Category name'}
            autoFocus={true}
          />

          <TextArea
            onChange={this.handleChange}
            name={'dialog_box'}
            label={'Dialog Box'}
            value={this.state.dialog_box}
          >
            <IconButtonWrapper
              color={'orange'}
              icon={<CodeIcon fontSize={'small'} />}
              onClick={e => {
                e.preventDefault()
                window.open('https://html5-editor.net/')
              }}
            />
          </TextArea>
          <SortableEditableListWrapper
            items={this.state.images}
            setItems={this.handleListChange}
            label={'Images'}
            name={Keys.IMAGES}
          >
            <UploadFile
              setShowValidationPassword={this.props.setShowValidationPassword}
              onChange={e => this.handleImageUpload(e, Keys.IMAGES)}
            />
          </SortableEditableListWrapper>

          <Input
            value={this.state.order}
            onChange={this.handleChange}
            type="number"
            name={Keys.ORDER}
            required={true}
            label={'Order'}
          />

          <li className="formButtonDash">
            <Button
              type={'submit'}
              label={
                this.props.editCategory ? 'Submit changes' : 'Add New Category'
              }
            />
            {this.props.editCategory ? (
              <Button
                type="button"
                onClick={this.handleDelete}
                color={'secondary'}
                label={'Delete Category'}
              />
            ) : null}
          </li>
        </ul>
      </form>
    )
  }
}

export default withSubmissionHandler(CategoryModalForm)
