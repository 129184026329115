import React from 'react'
import Keys from '../../../utils/Keys.json'
import { updateUserTasks } from '../../../services/api'
import { getTaskFromLocalStorage, sortTasks } from '../../../utils/taskHandler'
import Button from '../Button'
import Colors from '../../../utils/Colors.json'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import WarningIcon from '@material-ui/icons/Warning'
import { LocalStorage } from '../../../services/localStorage'
import { isEmpty } from 'lodash'

const DeleteTaskModal = props => {
  const close = () => {
    props.setEditTask(false)
    props.setShowTasksModal(false)
    props.setShowDeleteTaskWarningModal(false)
    props.setSelectedTask({
      data_oid: null,
      task_id: null,
      data_order: null,
    })
  }
  const handleResponse = response => {
    let msg = 'Task deleted! less tasks => more time to chill'
    let alertType = Keys.ALERT_SUCCESS
    if (response.status === 200) {
      const { tasks, selectedTask } = props

      const taskId = selectedTask[Keys.TASK_ID]
      let reducedTasks = tasks.filter(task => {
        if (task[Keys.TASK_ID] !== taskId) {
          return task
        }
      })
      reducedTasks = sortTasks(reducedTasks, props.addChangedTask)
      props.setAppTasks(reducedTasks)
    } else {
      msg = 'failed to delete Task :( , please check with R&D team'
      alertType = Keys.ALERT_ERROR
    }
    props.handleAlert({
      text: msg,
      type: alertType,
    })
    close()
  }

  const handleDelete = () => {
    props.showFullScreenLoader()
    const {
      selected_rid,
      selectedGroup,
      taskSelectedUser,
      selectedTask,
    } = props
    const body = {
      rid: selected_rid,
      group: !isEmpty(selectedGroup[Keys.OBJECT_ID])
        ? selectedGroup[Keys.OBJECT_ID]
        : taskSelectedUser?.value,
      tid: selectedTask[Keys.TASK_ID],
    }

    const prefix = props.selectedTask.cloned ? Keys.CLONED_TASK_LS_PREFIX : null
    const { task: taskFromLS, LSKey } = getTaskFromLocalStorage(
      selected_rid,
      selectedGroup.name,
      prefix
    )
    if (taskFromLS && body.tid === taskFromLS.task.tid) {
      LocalStorage.removeData(LSKey)
      props.setSelectedTask({ data_oid: null, task_id: null, data_order: null })
      const tasks = props.tasks.filter(task => task.tid !== body.tid)
      props.setTasksAndTasksData(tasks, props.tasksData)
      props.setShowDeleteTaskWarningModal(false)
      props.hideFullScreenLoader()
      props.setEditTask(false)
      props.setShowTasksModal(false)
    } else {
      updateUserTasks(
        handleResponse,
        body,
        'DELETE',
        props.hideFullScreenLoader
      )
    }
  }

  return (
    <Modal isOpen={props.showDeleteTaskWarningModal} toggle={close}>
      <ModalHeader toggle={close}>
        <WarningIcon /> Delete task
      </ModalHeader>
      <ModalBody>
        <h4 className="modalHeaderText">
          Are you sure you want to delete this task2 : {'  '}
          {props.selectedTask[Keys.TASK_ID]}
        </h4>
        <div className="modalButtonDash">
          <Button onClick={handleDelete} type={'submit'} bg={Colors.ACTION}>
            {' '}
            Yes, delete please{' '}
          </Button>
          <Button onClick={close} bg={Colors.DANGER}>
            {' '}
            cancel{' '}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteTaskModal
