import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../../store/actions'
import Button from '../Buttons/Button'
import {
  generateID,
  getRsid,
  validate,
  tagsToList,
  removeKeyFromObject,
} from '../../../utils/taskHandler'
import Colors from '../../../utils/Colors'
import '../styles/formStyle.css'
import Keys from '../../../utils/Keys'
import {
  date2timestamp,
  epochTime2date,
  getNextWeek,
  getNow,
  timestamp2date,
} from '../../../utils/dateHandler'
import Input from '../FormElements/Input'
import Switch from '../FormElements/Switch'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import TaskType1Details from './TaskType1Details'
import TaskType1Structure from './TaskType1Structure'
import TaskType1Rules from './TaskType1Rules'
import withSubmissionHandler from './withSubmissionHandler'
import { generateTagList } from '../../../utils/taskHandler'
import { isNil } from 'lodash'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: '5px',
  },
  instructions: {
    marginTop: '5px',
    marginBottom: '5px',
  },
})

class TaskReportFormMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeStep: 0,
      steps: this.getSteps(),

      tid:
        this.props.editTask === true &&
        !isNil(this.props.selectedTask[Keys.TASK_ID])
          ? this.props.selectedTask[Keys.TASK_ID]
          : generateID(),
      ref_id: !isNil(this.props.selectedTask[Keys.REFERENCE_ID])
        ? this.props.selectedTask[Keys.REFERENCE_ID]
        : generateID(),
      page_title:
        this.props.editTask === true &&
        !isNil(this.props.selectedTask.page_title)
          ? this.props.selectedTask.page_title
          : '',
      titles:
        this.props.editTask === true && !isNil(this.props.selectedTask.titles)
          ? generateTagList(this.props.selectedTask.titles)
          : [],

      deleted_titles: [],
      added_titles: [],

      rules:
        this.props.editTask === true && !isNil(this.props.selectedTask.rules)
          ? this.props.selectedTask.rules
          : [],
      created_at_ts:
        this.props.editTask === true &&
        !isNil(this.props.selectedTask.created_at_ts)
          ? this.props.selectedTask.created_at_ts
          : null,
      update_ts:
        this.props.editTask === true &&
        !isNil(this.props.selectedTask.update_ts)
          ? this.props.selectedTask.update_ts
          : null,

      from_ts:
        this.props.editTask === true && !isNil(this.props.selectedTask.from_ts)
          ? this.props.selectedTask.from_ts
          : getNow('YYYYMMDDHHmmss'),
      to_ts:
        this.props.editTask === true && !isNil(this.props.selectedTask.to_ts)
          ? this.props.selectedTask.to_ts
          : getNextWeek('YYYYMMDDHHmmss'),
      active:
        this.props.editTask === true && !isNil(this.props.selectedTask.active)
          ? this.props.selectedTask.active
          : true,
      order:
        this.props.editTask === true && !isNil(this.props.selectedTask.order)
          ? this.props.selectedTask.order
          : this.props.reportTasks.length,
    }
  }

  handleNext = () => {
    const prevActiveStep = this.state.activeStep
    this.setActiveStep((prevActiveStep + 1) % this.state.steps.length)
  }

  handlePrev = () => {
    const prevActiveStep = this.state.activeStep
    this.setActiveStep((prevActiveStep - 1) % this.state.steps.length)
  }

  handleDetailsChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  finish = (task, taskId, tasks) => {
    // this.props.addChangedTask(taskId)
    this.props.submitTask(task)
    this.props.setReportTasks(tasks)
    this.props.setCreatingTaskOfType(null)
    this.props.setEditTask(false)
    this.props.setShowTasksModal(false)
  }

  handleEditSubmit = () => {
    this.state.deleted_titles.forEach(colIndex => {
      this.deleteColumnFromData(colIndex)
    })
    this.state.added_titles.forEach(colIndex => {
      this.addColumnFromData(colIndex)
    })
    const form = this.state
    let oldTasks = this.props.reportTasks
    const taskId = this.state.tid
    const editableFields = [
      Keys.PAGE_TITLE,
      Keys.FROM_TS,
      Keys.TO_TS,
      Keys.ACTIVE,
      Keys.TITLES,
      Keys.RULES,
      Keys.ORDER,
      Keys.REFERENCE_ID,
    ]

    let editedTask = null

    let newTasks = oldTasks.map((task, index, array) => {
      if (task[Keys.TASK_ID] === taskId) {
        Object.keys(form).forEach((item, index) => {
          if (editableFields.includes(item)) {
            task[item] = form[item]
            if ([Keys.ORDER].includes(item)) {
              task[item] = Number(form[item])
            }
            if ([Keys.FROM_TS, Keys.TO_TS].includes(item)) {
              task[item] = date2timestamp(form[item])
            }
            if ([Keys.PAGE_TITLE].includes(item)) {
              task[item] = form[item].trim()
            }
            if ([Keys.TITLES].includes(item)) {
              task[item] = tagsToList(form[item])
            }
          }
        })
        editedTask = task
      }
      return task
    })
    this.finish(editedTask, taskId, newTasks)
  }

  handleNewSubmit = event => {
    const { selected_rid, selected_sid, reportTasks } = this.props
    let tasks = []
    if (reportTasks !== undefined && reportTasks !== null) {
      tasks = reportTasks.slice()
    }
    const {
      tid,
      rules,
      titles,
      page_title,
      from_ts,
      to_ts,
      active,
      order,
      ref_id,
    } = this.state
    let rs_id = getRsid(selected_rid, selected_sid)
    if (tasks === null) {
      tasks = []
    }
    const newReportTask = {
      rs_id: rs_id,
      ref_id: ref_id,
      tid: tid,
      data: [],
      titles: tagsToList(titles),
      rules: rules,
      type: Keys.TASK_TYPE_REPORT,
      done: false,
      page_title: page_title.trim(),
      from_ts: date2timestamp(from_ts),
      to_ts: date2timestamp(to_ts),
      active: active,
      order: Number(order),
    }
    tasks.push(newReportTask)

    this.finish(newReportTask, tid, tasks)
  }

  validateRules = () => {
    const { rules } = this.state
    rules.forEach(rule => {
      if (rule[Keys.TYPE] === Keys.COLOR) {
        if (!isNil(rule[Keys.RULE])) {
          if (rule[Keys.RULE].includes(null)) {
            throw { text: 'Some parameters in color rule was not chosen' }
          }
        }
      }

      if (rule[Keys.TARGET_COLUMN] === null) {
        throw { text: 'target column was not chosen' }
      }
    })
  }

  isValid = form => {
    const toValidate = [Keys.PAGE_TITLE]
    const { titles } = this.state
    try {
      if (isNil(titles) || titles.length < 1) {
        throw { text: 'No columns were set for this table' }
      }
      this.validateRules()
      return validate(form, toValidate)
    } catch (e) {
      this.props.handleAlert({
        text: e.text,
        type: 'error',
      })
      return false
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    if (this.isValid(this.state) === true) {
      if (this.props.editTask === true) {
        this.handleEditSubmit(event)
      } else {
        this.handleNewSubmit(event)
      }
    }
  }

  deleteColumnFromData(index) {
    let task = this.props.selectedTask
    const tid = task[Keys.TASK_ID]
    let reportTasks = this.props.reportTasks
    reportTasks.forEach(task => {
      if (tid === task[Keys.TASK_ID]) {
        let data = task[Keys.DATA]
        data.forEach(row => {
          row.splice(index, 1)
        })
        task[Keys.DATA] = data
      }
    })

    this.props.setReportTasks([...reportTasks])
  }

  addColumnFromData(index) {
    let task = this.props.selectedTask
    const tid = task[Keys.TASK_ID]
    let reportTasks = this.props.reportTasks
    reportTasks.forEach(task => {
      if (tid === task[Keys.TASK_ID]) {
        let data = task[Keys.DATA]
        data.forEach(row => {
          row.push('')
        })
        task[Keys.DATA] = data
      }
    })

    this.props.setReportTasks([...reportTasks])
  }

  upDateRules = index => {
    let newRules = [...this.state.rules]
    newRules.forEach(rule => {
      if (rule[Keys.TYPE] === Keys.COLOR) {
        const colIndexesInRule = [0, 2]
        colIndexesInRule.forEach(colIndex => {
          if (Number(rule[Keys.RULE][colIndex]) > index) {
            rule[Keys.RULE][colIndex] = `${Number(rule[Keys.RULE][colIndex]) -
              1}`
          } else if (Number(rule[Keys.RULE][colIndex]) === index) {
            rule[Keys.RULE][colIndex] = null
          }
        })
      }
      if (Number(rule[Keys.TARGET_COLUMN]) === index) {
        rule[Keys.TARGET_COLUMN] = null
      } else if (Number(rule[Keys.TARGET_COLUMN]) > index) {
        rule[Keys.TARGET_COLUMN] = `${Number(rule[Keys.TARGET_COLUMN]) - 1}`
      }
    })
    this.handleRulesChange(newRules)
  }

  handleTitlesChange = (value, index, action_name) => {
    this.setState({
      titles: value,
    })

    if (action_name === 'DELETE') {
      let toDelete = this.state.deleted_titles
      toDelete.push(index)
      this.setState({ deleted_titles: toDelete })
      this.upDateRules(index)
    }
    if (action_name === 'ADD') {
      let toAdd = this.state.added_titles
      toAdd.push(index)
      this.setState({ added_titles: toAdd })
    }
  }

  handleRulesChange = value => {
    this.setState({
      rules: [...value],
    })
  }

  switchActive = e => {
    this.setState({ active: e.target.checked })
  }

  setActiveStep = step => {
    this.setState({ activeStep: step })
  }
  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <TaskType1Details
            {...this.state}
            handleDetailsChange={this.handleDetailsChange}
            switchActive={this.switchActive}
          />
        )
      case 1:
        return (
          <TaskType1Structure
            titles={this.state.titles}
            handleTitlesChange={this.handleTitlesChange}
          />
        )
      case 2:
        return (
          <TaskType1Rules
            handleRulesChange={this.handleRulesChange}
            rules={this.state.rules}
            columnNumber={this.state.titles.length}
          />
        )
      default:
        return 'Unknown step'
    }
  }

  getSteps = () => {
    return ['Report details', 'Report structure', 'Report rules']
  }

  render() {
    return (
      <div>
        <Stepper activeStep={this.state.activeStep}>
          {this.state.steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>

        {this.getStepContent(this.state.activeStep)}
        <li className="formButtonDash">
          {this.state.activeStep !== 0 ? (
            <Button label={'Prev'} onClick={this.handlePrev} />
          ) : null}
          {this.state.activeStep !== 2 ? (
            <Button label={'Next'} onClick={this.handleNext} />
          ) : (
            <Button label={'Submit'} onClick={this.handleSubmit} />
          )}
        </li>
      </div>
    )
  }
}

const mapStateToProps = ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
}) => ({
  reportTasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  editTask,
})

export default withSubmissionHandler(
  connect(
    mapStateToProps,
    actions
  )(withStyles(styles)(TaskReportFormMain))
)
