import React from 'react'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import ReportTable from '../Table/ReportTable'
import CategoryTable from '../Table/CategoryTable'
import Keys from '../../utils/Keys'
import isNil from 'lodash/isNil'

class TaskTableExpandComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      task_type: this.props.selectedTask.type,
    }
  }

  render() {
    const { row } = this.props
    return (
      <div>
        {!isNil(row) && row.type === Keys.TASK_TYPE_REPORT ? (
          <ReportTable selectedTask={row} />
        ) : !isNil(row) && row.type === Keys.TASK_TYPE_GENERAL ? (
          <CategoryTable selectedTask={row} />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = ({
  tasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  enableSaveTasksChanges,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  thisWeek,
}) => ({
  tasks,
  tasksData,
  selected_rid,
  selected_sid,
  selectedTask,
  enableSaveTasksChanges,
  newCategories,
  taskTypeMap,
  creatingTaskOfType,
  thisWeek,
})

export default connect(
  mapStateToProps,
  actions
)(TaskTableExpandComponent)
