import React, { Component } from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Keys from '../../utils/Keys'
import BootstrapTable from 'react-bootstrap-table-next'
import { connect } from 'redux-zero/react'
import actions from '../../store/actions'
import UnExpendedIcon from '../../assets/svg-icons/chevron-down.svg'
import ExpendedIcon from '../../assets/svg-icons/chevron-up.svg'
import styled from 'styled-components'
import './style.css'
import { TimeStampCell } from '../Elements/HoverOnCells'
import Colors from '../../utils/Colors'
import SubTaskTableType0 from './SubTaskTableType0'
import ImageIcon from '../../assets/svg-icons/camera.svg'
import TextAddButton from '../Elements/Buttons/TextAddButton'

const headerStyle = {
  textAlign: 'center',
  fontWeight: 'bold',
}
const Icon = styled.img`
  width: 30px;
  height: 30px;
`

const ImageStyled = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
`

const ImageStyledArrow = styled(ImageStyled)`
  transform: translate(-5%, -10%);
`

const TableWrapper = styled.div``

class CategoryTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getData = () => {
    let categories = []
    const { selectedTask, tasks } = this.props
    tasks.forEach(task => {
      if (task.tid === selectedTask.tid && selectedTask.type === 0) {
        task[Keys.CATEGORIES_ORDER].forEach((category_name, index) => {
          categories.push({
            category_name: category_name,
            order: index,
            ...task[Keys.CATEGORIES][category_name],
          })
        })
      }
    })
    return categories
  }

  _done = cell => {
    if (cell !== undefined && cell !== null && cell.length > 0) {
      return <TimeStampCell txt={cell} />
    }
  }

  _images = images => {
    if (images !== undefined && images !== null && images.length > 0) {
      return (
        <button
          className="categoryImagesButton"
          type={'button'}
          onClick={() => {
            this.props.showImageCarousel(images)
          }}
        >
          <Icon src={ImageIcon} />
        </button>
      )
    }
  }

  handelRowClick = (row, isExpand, rowIndex, e) => {
    this.props.setSelectedCategory({ ...row })
  }

  render() {
    const tableColor = {}

    const columns = [
      {
        dataField: 'category_name',
        text: 'Category',
        headerStyle: {
          ...headerStyle,
        },
      },
      {
        dataField: 'order',
        text: 'Order',
        headerStyle: {
          ...headerStyle,
        },
      },
      {
        dataField: 'images',
        text: 'Images',
        formatter: this._images,
        headerStyle: {
          ...headerStyle,
        },
      },
    ]

    const expandRow = {
      renderer: row => (
        <SubTaskTableType0
          selectedTask={this.props.selectedTask}
          selectedCategory={row}
        />
      ),
      onExpand: (row, isExpand, rowIndex, e) => {
        this.handelRowClick(row, isExpand, rowIndex, e)
        if (isExpand) {
          this.props.setExpendedCategory([row.category_name])
        } else {
          this.props.setExpendedCategory([])
        }
      },

      expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
        <div>
          {expanded ? (
            <ImageStyledArrow src={ExpendedIcon} />
          ) : (
            <ImageStyledArrow src={UnExpendedIcon} />
          )}
        </div>
      ),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => (
        <div
          style={{
            height: '50px',
          }}
        />
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expanded: this.props.expandedCategory,
      width: 70,
    }

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
      hideSelectAll: true,

      onSelect: (row, isSelect, rowIndex, e) => {
        this.handelRowClick(row)
        this.props.setEditCategory(true)
        this.props.setShowCategoryModal(true)
      },
      selectionHeaderRenderer: ({ mode, checked, indeterminate }) => <div />,
    }

    return (
      <TableWrapper className="itemTableWrapper" tableColor={tableColor}>
        {this.props.selectedTask.categories_order !== undefined &&
        this.props.selectedTask.categories_order.length === 0 ? (
          'No Categories to Show'
        ) : (
          <BootstrapTable
            keyField="category_name"
            data={this.getData()}
            expandRow={expandRow}
            selectRow={selectRow}
            columns={columns}
            bordered={false}
            rowStyle={{
              backgroundColor: tableColor.color,
            }}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <TextAddButton
            onClick={() => this.props.setShowCategoryModal(true)}
            color={'purple'}
            label={'Add Category'}
          />
        </div>
      </TableWrapper>
    )
  }
}

const mapStateToProps = ({
  tasks,
  selected_rid,
  selected_sid,
  taskTypeMap,
  expandedCategory,
}) => ({
  tasks: tasks,
  selected_rid,
  selected_sid,
  taskTypeMap,
  expandedCategory,
})
export default connect(
  mapStateToProps,
  actions
)(CategoryTable)
